import React from "react";
import moment from "moment";

const LeadDetail = ({ leadListId }) => {
  return (
    <div className="project_edit_main_content">
      <div className="about_page_section plan_page_section">
        <div className="about_detaile_text_content">
          <h2>Name </h2>
          <p>
            {leadListId.first_name} {leadListId.last_name}
          </p>
        </div>
        <div className="about_detaile_text_content">
          <h2>Mobile Number </h2>
          <p>{leadListId.phone_number}</p>
        </div>
        <div className="about_detaile_text_content">
          <h2>Project Name </h2>
          <p>{leadListId.project_name}</p>
        </div>
        <div className="about_detaile_text_content">
          <h2>Remarks </h2>
          <p>{leadListId.remarks}</p>
        </div>
        <div className="about_detaile_text_content">
          <h2>Status </h2>
          <p>{leadListId.status}</p>
        </div>
        <div className="about_detaile_text_content">
          <h2>Referred By </h2>
          <p>
            {leadListId.referred_first_name} {leadListId.referred_last_name}
          </p>
        </div>
        <div className="about_detaile_text_content">
          <h2>Created At </h2>
          <p>
            {moment(leadListId.created_at).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeadDetail;
