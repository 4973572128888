import { logout } from "../store/slice/userSlice";
import Axios, { authHeaders } from "./axios";

export const checkAuth = async (dispatch) => {
  try {
    await Axios.get("/users/jwt-decode", authHeaders());
  } catch (error) {
    if (error?.response?.status === 403) {
      dispatch(logout());
    }
  }
};
