import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import Loader from "../../component/Common/Loader";
import { getPlanById } from "../../store/slice/planSlice";
import CreatePlanListPage from "./CreatePlanListPage";

const EditPlanListPage = () => {
  const [isPlanDetail, setIsPlanDetail] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const { values } = location?.state;

  const loader = useSelector((state) => state.plan.loader);
  const planListDetail = useSelector((state) => state.plan.planDetailId);

  useEffect(() => {
    if (!planListDetail && id) {
      dispatch(getPlanById(id));
    } else {
      setIsPlanDetail(false);
    }
  }, [dispatch, id, planListDetail]);

  if (isPlanDetail) {
    return <div />;
  }

  return (
    <>
      {loader && <Loader />}
      <CreatePlanListPage planListDetail={planListDetail} locValues={values} />
    </>
  );
};

export default EditPlanListPage;
