import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import TextArea from "../../component/Common/TextArea";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import {
  clearComplaint,
  getComplaintDetailByComplaintId,
  updateComplaint,
} from "../../store/slice/complaintSlice";

const EditComplaintPage = () => {
  const complaint = useSelector((state) => state.complaint.complaintDetailId);

  const location = useLocation();
  const { value } = location?.state;

  const initialFValues = {
    pending: false,
    status: complaint?.status ? complaint?.status : "pending",
    remarks: complaint?.remarks ? complaint?.remarks : "",
    project_name: complaint?.project_name ? complaint?.project_name : "",
    phone_number: complaint?.phone_number ? complaint?.phone_number : "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  // useEffect(() => {
  //   setValues({
  //     ...values,
  //     project_name: complaint?.project_name,
  //     phone_number: complaint?.phone_number,
  //   });
  // }, [complaint]);

  const loader = useSelector((state) => state.complaint.loader);

  const params = useParams();

  const { id, page_number } = params;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getComplaintDetailByComplaintId(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (complaint) {
      // dispatch(clearComplaint());
      setValues({
        ...values,
        status: complaint.status,
        remarks: complaint.remarks,
        project_name: complaint?.project_name,
        phone_number: complaint?.phone_number,
      });
    }
  }, [complaint, dispatch]);

  const updateHandler = (e) => {
    e.preventDefault();
    setValues({ ...values, pending: true });
    let data;
    data = {
      status: values.status,
      remarks: values.remarks,
    };
    if (values.status === "resolved") {
      data.project_name = values.project_name;
      data.phone_number = values.phone_number;
    }
    setValues({ ...values, pending: false });
    dispatch(updateComplaint({ data, id, navigate, page_number, value }));
  };

  return (
    <>
      {(loader || values.pending) && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.complaints}/page/${page_number}`}
          state={{ value }}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <h2 className="col_span_2">Complaint</h2>
          <div className="create_from_input_content col_span_2">
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Reopen"
              id="reopen"
              name="status"
              value="reopen"
              onChange={handleInputChange}
              checked={values.status === "reopen"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Pending"
              id="pending"
              name="status"
              value="pending"
              onChange={handleInputChange}
              checked={values.status === "pending"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Resolved"
              id="resolved"
              name="status"
              value="resolved"
              onChange={handleInputChange}
              checked={values.status === "resolved"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Closed"
              id="closed"
              name="status"
              value="closed"
              onChange={handleInputChange}
              checked={values.status === "closed"}
            />
          </div>
          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Remarks"
            label="Remarks"
            id="remarks"
            name="remarks"
            value={values.remarks}
            onChange={handleInputChange}
          />
          <Button
            className="creat_edit_project_btn_row  col_span_2"
            buttonClassName="comman_btn"
            onClick={updateHandler}
            text="Update"
          />
        </div>
      </div>
    </>
  );
};

export default EditComplaintPage;
