import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../css/ProjectForm.css";

import { getprojectId } from "../../store/slice/projectSlice";
import { useParams } from "react-router";
import Loader from "../../component/Common/Loader";
import { Link } from "react-router-dom";
import { routes } from "../../constants";
import ProjectNavbar from "../../component/Project/Layout/ProjectNavbar";
import ProjectForms from "../../component/Project/ProjectForms";

export const ProjectEditForm = () => {
  const [isProjectDetail, setIsProjectDetail] = useState(true);

  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;

  const loader = useSelector((state) => state.project.loader);

  const projectDetails = useSelector((state) => state.project.project);

  const updateProjectDetail = useSelector(
    (state) => state.project.updateProject
  );

  useEffect(() => {
    if (!projectDetails && id) {
      dispatch(getprojectId(id));
    } else {
      setIsProjectDetail(false);
    }
  }, [projectDetails, dispatch, id]);

  useEffect(() => {
    dispatch(getprojectId(id));
  }, [id, updateProjectDetail, dispatch]);

  if (isProjectDetail) {
    return <div />;
  }

  return (
    <>
      {loader && <Loader />}
      <EditProjectPage projectDetails={projectDetails} />
    </>
  );
};

const EditProjectPage = ({ projectDetails }) => {
  const [menu, setMenu] = useState("basicInfo");

  return (
    <>
      <div className="project_edit_content">
        <ProjectNavbar menu={menu} setMenu={setMenu} />
        <div
          className={`comman_btn_container project_edit_page_body ${
            menu === "legalDocuments" || menu === "medias"
              ? ""
              : "center_back_btn"
          }`}
        >
          <Link to={routes.projects} className="comman_btn back_btn">
            Back
          </Link>
          <span className="project_detail-name">{projectDetails?.name}</span>
        </div>
        <ProjectForms menu={menu} projectDetails={projectDetails} />
      </div>
    </>
  );
};

export default EditProjectPage;
