import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../constants";
import { deleteIcon, editIcon, viewIcon } from "../../icons";
import moment from "moment";

const ServiceList = ({ list, handleServiceDelete, handleServiceView }) => {
  return (
    <>
      {list?.map((item, i) => {
        return (
          <div className="plan_list_column" key={item?.id}>
            <div className="plan_list_img">
              {item?.image && (
                <img
                  src={process.env.REACT_APP_FILE_BASE_URL + "/" + item.image}
                  alt="service"
                  className="plan_list_image"
                />
              )}
              <div className="plan_list_add_update_time">
                {item?.created_at && (
                  <p className="plan_list_added_time">
                    Created Date
                    <span>
                      {moment(item.created_at).format("DD/MM/YY, hh:mm A")}
                    </span>
                  </p>
                )}
                {item?.updated_at && (
                  <p className="plan_list_update_time">
                    Updated Date
                    <span>
                      {moment(item.updated_at).format("DD/MM/YY, hh:mm A")}
                    </span>
                  </p>
                )}
              </div>
            </div>
            <div className="plan_list_body">
              <div className="plan_list_id">
                {item?.service_name && (
                  <h3 className="plan_list_name">{item.service_name}</h3>
                )}
              </div>
              <div className="plan_list_bottom_btn">
                <Link
                  to={`${routes.service}/edit/${item?.id}`}
                  className="plan_list_bottom"
                  state={{ item: item }}
                >
                  {editIcon}
                  Service Edit
                </Link>
                <button
                  className="project_list_card_view_edit_btn link_red only-btn-css"
                  onClick={() => handleServiceView(item)}
                >
                  {viewIcon}
                  View
                </button>
                <div className="custom_data_table_view_edit_btn_item_row flex-end">
                  <button
                    className="custom_data_table_view_edit_item_btn"
                    onClick={() => handleServiceDelete(item.id)}
                  >
                    {deleteIcon}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ServiceList;
