import { Navigate } from "react-router-dom";
import AuthPage from "./component/Layout/AuthPage";
import Layout from "./component/Layout/MainLayout";
import CreateLeadPage from "./pages/Lead/CreateLeadPage";
import CreatePlanListPage from "./pages/Plan/CreatePlanListPage";
import CreateProjectPage from "./pages/Project/CreateProjectPage";
import CustomerPropertiesPage from "./pages/Property/CreateCustomerPropertiesPage";
import UsersListPage from "./pages/User/UsersListPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import LeadPage from "./pages/Lead/LeadPage";
import LoginPage from "./pages/Login/LoginPage";
import Page404 from "./pages/Page404/Page404";
import PlanListPage from "./pages/Plan/PlanListPage";
import ProjectListPage from "./pages/Project/ProjectListPage";
import { ProjectEditForm } from "./pages/Project/EditProjectPage";
import InquiryPage from "./pages/Inquiry/InquiryPage";
import EditInquiryPage from "./pages/Inquiry/EditInquiryPage";
import ComplaintPage from "./pages/Complaint/ComplaintPage";
import EditComplaintPage from "./pages/Complaint/EditComplaintPage";
import CreateCompanyPage from "./pages/Company/CreateCompanyPage";
import { CompanyEditForm } from "./pages/Company/CreateCompanyPage";
import CompanyDetailPage from "./pages/Company/CompanyDetailPage";
import VerifyOtpPage from "./pages/Login/VerifyOtpPage";
import ViewLeadPage from "./pages/Lead/ViewLeadPage";
import EditLeadPage from "./pages/Lead/EditLeadPage";
import CustomerPropertiesListPage from "./pages/Property/CustomerPropertiesListPage";
import CustomerPropertyEditPage from "./pages/Property/CustomerPropertyEditPage";
import ViewPlanListPage from "./pages/Plan/ViewPlanListPage";
import EditPlanListPage from "./pages/Plan/EditPlanListPage";
import NoticePage from "./pages/Notification/NoticePage";
import CreateNoticePage from "./pages/Notification/CreateNoticePage";
import EditNoticePage from "./pages/Notification/EditNoticePage";
import UserDetailPage from "./pages/User/UserDetailPage";
import PrivilegeListPage from "./pages/Privilege/PrivilegeListPage";
import CreatePrivilegePage from "./pages/Privilege/CreatePrivilegePage";
import EditPrivilegePage from "./pages/Privilege/EditPrivilegePage";
import FeedBackPage from "./pages/Feedback/FeedBackPage";
import InquiryDetailsPage from "./pages/Inquiry/InquiryDetailsPage";

import ContactListPage from "./pages/Contact/ContactListPage";
import CreateContactPage from "./pages/Contact/CreateContactPage";
import EditContactPage from "./pages/Contact/EditContactPage";
import { routes } from "../src/constants";
import ProjectRewardpoints from "./pages/Dashboard/ProjectRewardpoints";
import FeaturedProject from "./pages/FeaturedProject/FeaturedProjectList";
import MobileConfiguration from "./pages/MobileConfiguration/MobileConfiguration";
import RewardHistoryPage from "./pages/RewardHistory/RewardHistoryPage";
import EditFeedbackPage from "./pages/Feedback/EditFeedbackPage";
import CreateBulkProperties from "./pages/Property/CreateBulkProperties";
import Service from "./pages/Service/Service";
import CreateService from "./pages/Service/CreateService";
import ImportContactEditProperty from "./pages/Property/ImportContactEditProperty";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: !user ? <AuthPage /> : <Navigate to={routes.dashboard} />,
    children: [
      { path: routes.login, element: <LoginPage /> },
      { path: routes.verifyOtp, element: <VerifyOtpPage /> },
      { path: routes.homepage, element: <Navigate to={routes.login} /> },
    ],
  },
  {
    path: routes.homepage,
    element: user ? <Layout /> : <Navigate to={routes.login} />,
    children: [
      { path: routes.dashboard, element: <DashboardPage /> },
      { path: routes.dashboardRewardPoints, element: <ProjectRewardpoints /> },
      { path: routes.projects, element: <ProjectListPage /> },
      { path: routes.featuredProjects, element: <FeaturedProject /> },
      { path: routes.createProject, element: <CreateProjectPage /> },
      { path: routes.editProject, element: <ProjectEditForm /> },
      { path: routes.rewardHistoryByPage, element: <RewardHistoryPage /> },
      { path: routes.leadsByPage, element: <LeadPage /> },
      { path: routes.createLead, element: <CreateLeadPage /> },
      { path: routes.viewLead, element: <ViewLeadPage /> },
      { path: routes.editLead, element: <EditLeadPage /> },
      { path: routes.plansByPage, element: <PlanListPage /> },
      { path: routes.viewPlan, element: <ViewPlanListPage /> },
      { path: routes.editPlan, element: <EditPlanListPage /> },
      { path: routes.createPlan, element: <CreatePlanListPage /> },
      {
        path: routes.createCustomerProperty,
        element: <CustomerPropertiesPage />,
      },
      {
        path: routes.createBulkProperties,
        element: <CreateBulkProperties />,
      },
      {
        path: routes.customerPropertyByPage,
        element: <CustomerPropertiesListPage />,
      },
      {
        path: routes.editCustomerProperty,
        element: <CustomerPropertyEditPage />,
      },

      {
        path: routes.importContactsCustomerProperty,
        element: <ImportContactEditProperty />,
      },

      { path: routes.usersByPage, element: <UsersListPage /> },
      { path: routes.userDetail, element: <UserDetailPage /> },

      { path: routes.inquiriesByPage, element: <InquiryPage /> },
      { path: routes.editInquiry, element: <EditInquiryPage /> },
      { path: routes.viewInquiry, element: <InquiryDetailsPage /> },
      { path: routes.complaintsByPage, element: <ComplaintPage /> },
      { path: routes.editComplaint, element: <EditComplaintPage /> },
      { path: routes.company, element: <CompanyDetailPage /> },
      { path: routes.createCompany, element: <CreateCompanyPage /> },
      { path: routes.editCompany, element: <CompanyEditForm /> },
      { path: routes.notificationsByPage, element: <NoticePage /> },
      { path: routes.createNotice, element: <CreateNoticePage /> },
      { path: routes.editNotice, element: <EditNoticePage /> },
      { path: routes.privilegesByPage, element: <PrivilegeListPage /> },
      { path: routes.createPrivilege, element: <CreatePrivilegePage /> },
      { path: routes.editPrivilege, element: <EditPrivilegePage /> },
      { path: routes.feedBackBypage, element: <FeedBackPage /> },
      { path: routes.editFeedBack, element: <EditFeedbackPage /> },
      { path: routes.homepage, element: <Navigate to={routes.dashboard} /> },

      { path: routes.contactsByPage, element: <ContactListPage /> },
      { path: routes.createContact, element: <CreateContactPage /> },
      { path: routes.editContact, element: <EditContactPage /> },

      { path: routes.serviceByPage, element: <Service /> },
      { path: routes.createService, element: <CreateService /> },
      { path: routes.editService, element: <CreateService /> },

      { path: routes.mobileConfiguration, element: <MobileConfiguration /> },
    ],
  },
  {
    path: "",
    element: !user ? <Navigate to={routes.login} /> : <Page404 />,
    children: [
      { path: routes.login, element: <LoginPage /> },
      { path: "*", element: <LoginPage /> },
    ],
  },
];

export default getRoutes;
