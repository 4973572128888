import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Select from "react-select";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import { benefitsTypeHandler } from "../../helpers/commonFunction";
import { fileUpload } from "../../helpers/fileUpload";
import { useForm } from "../../hooks/useForm";
import { addIcon, closeIcon, editIcon, removeIcon } from "../../icons";
import { imageProps } from "../../props";
import { createPlan, updatePlan } from "../../store/slice/planSlice";
import {
  getProjectList,
  getTowersByProject,
} from "../../store/slice/projectSlice";
import {
  documentsColumn,
  drawingsColumn,
  legalDocumentsColumn,
} from "../../component/Project/ProjectForms/ValidationForm";

const CreatePlanListPage = ({ planListDetail, locValues }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id, page_number } = params;
  const loader = useSelector((state) => state.plan.loader);

  const [legalDocErr, setLegalDocErr] = useState("");
  const [drawingsErr, setDrawingsErr] = useState("");
  const [documentsErr, setDocumentsErr] = useState("");

  const towerList = useSelector((state) => state.project.towerList);

  // console.log("planListDetail", planListDetail);

  const towerOptions = towerList?.map((item) => {
    return { value: item.name, label: item.name };
  });

  const towerOptionsAllOptions = towerOptions && [
    { value: "", label: "All" },
    ...towerOptions,
  ];

  let InitialPlanImagePreview;
  let InitialLegalDocsPreview;
  let InitialMyDocsPreview;
  let InitialDrawingsPreview;

  if (planListDetail?.main_plan_image) {
    InitialPlanImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${planListDetail.main_plan_image}`;
  }

  if (planListDetail?.legal_documents) {
    InitialLegalDocsPreview = JSON.parse(planListDetail?.legal_documents).map(
      (item) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`;
      }
    );
  }

  if (planListDetail?.documents) {
    InitialMyDocsPreview = JSON.parse(planListDetail?.documents).map((item) => {
      return `${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`;
    });
  }

  if (planListDetail?.drawings) {
    InitialDrawingsPreview = JSON.parse(planListDetail?.drawings).map(
      (item) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`;
      }
    );
  }
  useEffect(() => {
    dispatch(getProjectList({ admin: "true" }));
  }, [dispatch]);

  const projectList = useSelector((state) => state.project.projects);

  const projectIdOptions = projectList.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const initialFValues = {
    project_id: planListDetail?.project_id
      ? {
          value: planListDetail?.project_name,
          label: planListDetail?.project_name,
          id: planListDetail?.project_id,
        }
      : null,
    tower_name: null,
    properties:
      planListDetail && planListDetail.properties?.length !== 0
        ? planListDetail.properties.map((item) => {
            return { value: item.id, label: item.property_number };
          })
        : [],

    main_plan_image: planListDetail?.main_plan_image
      ? planListDetail?.main_plan_image
      : "",

    main_plan_image_preview: planListDetail?.main_plan_image
      ? InitialPlanImagePreview
      : [],

    main_plan_image_error: "",

    display_name: planListDetail?.display_name
      ? planListDetail?.display_name
      : "",

    // title: planListDetail?.title ? planListDetail?.title : "",

    drawings:
      planListDetail?.drawings &&
      JSON.parse(planListDetail?.drawings).length > 0
        ? JSON.parse(planListDetail?.drawings)
        : [
            {
              title: "",
              doc: "",
            },
          ],

    drawings_preview: planListDetail?.drawings ? InitialDrawingsPreview : [],
    drawings_error: [],

    legal_documents:
      planListDetail?.legal_documents &&
      JSON.parse(planListDetail?.legal_documents).length > 0
        ? JSON.parse(planListDetail?.legal_documents)
        : [
            {
              title: "",
              doc: "",
            },
          ],

    legal_documents_preview: planListDetail?.legal_documents
      ? InitialLegalDocsPreview
      : [],
    legal_documents_error: [],

    documents:
      planListDetail?.documents &&
      JSON.parse(planListDetail?.documents).length > 0
        ? JSON.parse(planListDetail?.documents)
        : [
            {
              title: "",
              doc: "",
            },
          ],

    documents_preview: planListDetail?.documents ? InitialMyDocsPreview : [],
    documents_error: [],
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("display_name" in fieldValues) {
      temp.display_name = fieldValues.display_name ? "" : "Name is required !";
    }
    // if ("title" in fieldValues) {
    //   temp.title = fieldValues.title ? "" : "Title is required !";
    // }
    if ("project_id" in fieldValues) {
      temp.project_id = fieldValues.project_id ? "" : "Project is not selected";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialFValues,
    true,
    validate
  );

  // useEffect(() => {
  //   setValues({ ...values, properties: [], tower_name: null });
  // }, [values.project_id]);

  const getPropertiesOptions = (tower_name) => {
    let optionArr = [];
    for (let i = 0; i < towerList?.length; i++) {
      if (!tower_name) {
        towerList[i].properties?.map((item) => {
          optionArr.push({ value: item.id, label: item.property_number });
        });
      } else if (
        towerList[i]?.name === tower_name &&
        towerList[i].properties?.length !== 0
      ) {
        towerList[i].properties?.map((item) => {
          optionArr.push({ value: item.id, label: item.property_number });
        });
      }
    }
    return optionArr;
  };

  useEffect(() => {
    if (values.project_id) {
      dispatch(getTowersByProject({ id: values.project_id.id }));
    }
  }, [values.project_id]);

  const onProjectChange = (e) => {
    setValues({
      ...values,
      project_id: e,
      tower_name: null,
      properties: [],
    });
    setErrors({
      ...errors,
      project_id: "",
    });
  };

  //legalDocuments
  const legalDocsHandler = (e, index) => {
    setLegalDocErr("");
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.legal_documents,
      previewState: values.legal_documents_preview,
      error: values.legal_documents_error,
      prop: "legal_documents",
      previewProp: "legal_documents_preview",
      errorProp: "legal_documents_error",
      type: "benefitsTypeHandler",
      allowPdf: true,
    };
    benefitsTypeHandler(tempData);
  };

  const legalDocsHandleRemoveClick = (index) => {
    setLegalDocErr("");
    const tempData = {
      index,
      values,
      setValues,
      state: values.legal_documents,
      previewState: values.legal_documents_preview,
      prop: "legal_documents",
      previewProp: "legal_documents_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };
  const legalDocsHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.legal_documents,
      prop: "legal_documents",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        doc: "",
      },
    };
    benefitsTypeHandler(tempData);
  };
  const docImageRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.legal_documents,
      previewState: values.legal_documents_preview,
      type: "benefitsTypeImageRemoveHandler doc",
      prop: "legal_documents",
      previewProp: "legal_documents_preview",
    };
    benefitsTypeHandler(tempData);
  };

  //drawings
  const drawingsHandler = (e, index) => {
    setDrawingsErr("");
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.drawings,
      previewState: values.drawings_preview,
      error: values.drawings_error,
      prop: "drawings",
      previewProp: "drawings_preview",
      errorProp: "drawings_error",
      type: "benefitsTypeHandler",
      allowPdf: true,
    };
    benefitsTypeHandler(tempData);
  };
  const drawingsHandleRemoveClick = (index) => {
    setDrawingsErr("");
    const tempData = {
      index,
      values,
      setValues,
      state: values.drawings,
      previewState: values.drawings_preview,
      prop: "drawings",
      previewProp: "drawings_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };
  const drawingsHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.drawings,
      prop: "drawings",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        doc: "",
      },
    };
    benefitsTypeHandler(tempData);
  };
  const drawingsImageRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.drawings,
      previewState: values.drawings_preview,
      type: "benefitsTypeImageRemoveHandler doc",
      prop: "drawings",
      previewProp: "drawings_preview",
    };
    benefitsTypeHandler(tempData);
  };
  //documents
  const myDocsHandler = (e, index) => {
    setDocumentsErr("");
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.documents,
      previewState: values.documents_preview,
      error: values.documents_error,
      prop: "documents",
      previewProp: "documents_preview",
      errorProp: "documents_error",
      type: "benefitsTypeHandler",
      allowPdf: true,
    };
    benefitsTypeHandler(tempData);
  };
  const myDocsHandleRemoveClick = (index) => {
    setDocumentsErr("");
    const tempData = {
      index,
      values,
      setValues,
      state: values.documents,
      previewState: values.documents_preview,
      prop: "documents",
      previewProp: "documents_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };
  const myDocsHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.documents,
      prop: "documents",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        doc: "",
        isGlobal: true,
      },
    };
    benefitsTypeHandler(tempData);
  };
  const myDocImageRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.documents,
      previewState: values.documents_preview,
      type: "benefitsTypeImageRemoveHandler doc",
      prop: "documents",
      previewProp: "documents_preview",
    };
    benefitsTypeHandler(tempData);
  };

  const imageRemoveHandler = () => {
    setValues({
      ...values,
      main_plan_image_preview: [],
      main_plan_image: "",
    });
  };

  const getPropertiesIds = (properties) => {
    let idArr = [];
    for (let i = 0; i < properties.length; i++) {
      idArr.push(properties[i].value);
    }
    return idArr;
  };

  const createPlanHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      let planImageUrl;
      const formData = new FormData();
      if (typeof values.main_plan_image !== "string") {
        formData.append("file", values.main_plan_image);
        planImageUrl = await fileUpload(formData);
        formData.delete("file");
      } else {
        planImageUrl = values.main_plan_image;
      }

      const temp_legal_data = {
        type: "fileUpload doc",
        state: values.legal_documents,
      };
      const tempLegalDoc = await benefitsTypeHandler(temp_legal_data);
      const temp_documents_data = {
        type: "fileUpload doc",
        state: values.documents,
      };
      const tempDocuments = await benefitsTypeHandler(temp_documents_data);

      const temp_drawings_data = {
        type: "fileUpload doc",
        state: values.drawings,
      };
      const tempDrawing = await benefitsTypeHandler(temp_drawings_data);

      // legal validation function
      let hasEmptyTitleLegal = legalDocumentsColumn(
        tempLegalDoc,
        setLegalDocErr
      );
      let hasEmptyTitleDrawings = drawingsColumn(tempDrawing, setDrawingsErr);
      let hasEmptyTitleDocuments = documentsColumn(
        tempDocuments,
        setDocumentsErr
      );

      const data = {
        project_id: values.project_id.id,
        display_name: values.display_name.trim(),
        // title: values.title.trim(),
        main_plan_image: planImageUrl,
        legal_documents: tempLegalDoc,
        documents: tempDocuments,
        drawings: tempDrawing,
        properties: getPropertiesIds(values.properties),
      };

      // console.log("planData", data);
      if (
        hasEmptyTitleLegal &&
        hasEmptyTitleDrawings &&
        hasEmptyTitleDocuments
      ) {
        if (id) {
          dispatch(updatePlan({ data, id, navigate, page_number, locValues }));
        } else {
          dispatch(createPlan({ data, navigate }));
        }
      }
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.plans}/page/${page_number || 1}`}
          state={{ locValues }}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <div className="create_from_input_content">
            <label className="create_from_label">Project</label>
            <div
              style={{
                zIndex: "9999",
                position: "relative",
              }}
            >
              <Select
                value={values.project_id}
                className="basic-multi-select"
                classNamePrefix="select"
                name="project_id"
                id="project_id"
                options={projectIdOptions}
                // onChange={(e) => handleInputChange(e, "project_id")}
                onChange={(e) => onProjectChange(e)}
                required
              />
            </div>
            {errors?.project_id && (
              <span className="err_text">{errors?.project_id}</span>
            )}
          </div>
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="For eg. A-101, A-301, OR A-101 to A-109, B-102 to B-902"
            label="Name"
            id="display_name"
            name="display_name"
            value={values.display_name}
            onChange={handleInputChange}
            error={errors?.display_name || ""}
          />
          <div className="create_from_input_content">
            <label className="create_from_label">
              Select Tower for assign properties
            </label>
            <div
              style={{
                zIndex: "999",
                position: "relative",
              }}
            >
              <Select
                value={values.tower_name}
                className="basic-multi-select"
                classNamePrefix="select"
                name="tower_name"
                id="tower_name"
                placeholder="Select Tower..."
                options={towerOptionsAllOptions}
                onChange={(e) => handleInputChange(e, "tower_name")}
                isDisabled={!values.project_id ? true : false}
              />
            </div>
          </div>
          <div className="create_from_input_content">
            <label className="create_from_label">Properties</label>
            <Select
              value={values.properties}
              className="basic-multi-select"
              classNamePrefix="select"
              name="properties"
              id="properties"
              placeholder="Select Properties for this Plan..."
              options={getPropertiesOptions(values.tower_name?.value)}
              isMulti
              onChange={(e) => handleInputChange(e, "properties")}
              isDisabled={!values.project_id ? true : false}
            />
          </div>
          <div className="create_from_input_content null"></div>

          {/* <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Title"
            label="Title"
            id="title"
            name="title"
            value={values.title}
            onChange={handleInputChange}
            error={errors?.title || ""}
          /> */}
          <Input
            className="create_from_input_content"
            labelClassName="create_from_label"
            errorClassName="err_text"
            type="file"
            id="main_plan_image"
            name="main_plan_image"
            labelHeading={<h2>Main Plan Image </h2>}
            accept="image/png, image/jpeg"
            onChange={handleInputChange}
            error={errors?.main_plan_image || ""}
            onClick={(e) => (e.target.value = null)}
            imageProps={imageProps(
              values.main_plan_image,
              values.main_plan_image_preview,
              imageRemoveHandler,
              "main_plan_image"
            )}
          />
          <div className="project_edit_detail_column_content">
            <h2>Legal Documents</h2>
            {values.legal_documents &&
              values.legal_documents.map((x, i) => {
                return (
                  <div key={i} className="project_edit_detail_divider">
                    <div className="project_edit_feature_input_file">
                      <Input
                        className="create_from_input_content"
                        labelClassName="create_from_label"
                        errorClassName="err_text"
                        type="file"
                        id={`plan_document${i}`}
                        labelOtherProps={
                          <span>
                            {!values.legal_documents[i].doc
                              ? addIcon
                              : editIcon}
                          </span>
                        }
                        name="doc"
                        accept="image/png, image/jpeg, application/pdf"
                        onChange={(e) => legalDocsHandler(e, i)}
                        onClick={(e) => (e.target.value = null)}
                        imageProps={
                          x.doc && (
                            <>
                              <iframe
                                title="ampp"
                                src={values.legal_documents_preview[i]}
                                className="profile_edit_iframe"
                              />
                              <Button
                                buttonClassName="image_remove_btn"
                                onClick={() => docImageRemoveHandler(i)}
                                other={closeIcon}
                              />
                            </>
                          )
                        }
                      />
                    </div>
                    {values.legal_documents_error[i] && (
                      <span className="err_text">
                        {values.legal_documents_error[i]}
                      </span>
                    )}
                    <Input
                      className="create_from_input_content null"
                      labelClassName="create_from_label"
                      inputClassName="create_from_input"
                      errorClassName="err_text"
                      type="text"
                      placeholder="Legal Document Title"
                      label="Title"
                      id="title"
                      name="title"
                      value={x.title}
                      onChange={(e) => legalDocsHandler(e, i)}
                    />
                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.legal_documents.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => legalDocsHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.legal_documents.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => legalDocsHandleAddClick(i)}
                          text="Add"
                          other={addIcon}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            {legalDocErr && <span className="err_text">{legalDocErr}</span>}
          </div>
          <div className="project_edit_detail_column_content">
            <h2>My Documents</h2>
            {values.documents &&
              values.documents.map((x, i) => {
                return (
                  <div key={i} className="project_edit_detail_divider">
                    <div className="project_edit_feature_input_file">
                      <Input
                        className="create_from_input_content"
                        labelClassName="create_from_label"
                        errorClassName="err_text"
                        type="file"
                        id={`documents${i}`}
                        labelOtherProps={
                          <span>
                            {!values.documents[i].doc ? addIcon : editIcon}
                          </span>
                        }
                        name="doc"
                        accept="image/png, image/jpeg, application/pdf"
                        onChange={(e) => myDocsHandler(e, i)}
                        onClick={(e) => (e.target.value = null)}
                        imageProps={
                          x.doc && (
                            <>
                              <iframe
                                title="ampp"
                                src={values.documents_preview[i]}
                                className="profile_edit_iframe"
                              />
                              <Button
                                buttonClassName="image_remove_btn"
                                onClick={() => myDocImageRemoveHandler(i)}
                                other={closeIcon}
                              />
                            </>
                          )
                        }
                      />
                    </div>

                    {values.documents_error[i] && (
                      <span className="err_text">
                        {values.documents_error[i]}
                      </span>
                    )}
                    <Input
                      className="project_edit_feature_input"
                      type="text"
                      placeholder="My Document Title"
                      label="Title"
                      id="title"
                      name="title"
                      value={x.title}
                      onChange={(e) => myDocsHandler(e, i)}
                    />
                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.documents.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => myDocsHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.documents.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => myDocsHandleAddClick(i)}
                          text="Add"
                          other={addIcon}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            {documentsErr && <span className="err_text">{documentsErr}</span>}
          </div>
          <div className="project_edit_detail_column_content">
            <h2>My Drawing</h2>
            {values.drawings &&
              values.drawings.map((x, i) => {
                return (
                  <div key={i} className="project_edit_detail_divider">
                    <div className="project_edit_feature_input_file">
                      <Input
                        className="create_from_input_content"
                        labelClassName="create_from_label"
                        errorClassName="err_text"
                        type="file"
                        id={`drawing${i}`}
                        labelOtherProps={
                          <span>
                            {!values.drawings[i].doc ? addIcon : editIcon}
                          </span>
                        }
                        name="doc"
                        accept="image/png,  image/jpeg, application/pdf"
                        onChange={(e) => drawingsHandler(e, i)}
                        onClick={(e) => (e.target.value = null)}
                        imageProps={
                          x.doc && (
                            <>
                              <iframe
                                title="ampp"
                                src={values.drawings_preview[i]}
                                className="profile_edit_iframe"
                              />
                              <Button
                                buttonClassName="image_remove_btn"
                                onClick={() => drawingsImageRemoveHandler(i)}
                                other={closeIcon}
                              />
                            </>
                          )
                        }
                      />
                    </div>
                    {values.drawings_error[i] && (
                      <span className="err_text">
                        {values.drawings_error[i]}
                      </span>
                    )}
                    <Input
                      className="create_from_input_content null"
                      labelClassName="create_from_label"
                      inputClassName="create_from_input"
                      errorClassName="err_text"
                      type="text"
                      placeholder="My Drawings Title"
                      label="Title"
                      id="title"
                      name="title"
                      value={x.title}
                      onChange={(e) => drawingsHandler(e, i)}
                    />
                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.drawings.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => drawingsHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.drawings.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => drawingsHandleAddClick(i)}
                          text="Add"
                          other={addIcon}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            {drawingsErr && <span className="err_text">{drawingsErr}</span>}
          </div>
          <div className="create_from_bottom_btn col_span_2">
            {id ? (
              <Button
                className="create_from_bottom_btn col_span_2"
                buttonClassName="comman_btn"
                onClick={createPlanHandler}
                text=" Edit Plan"
              />
            ) : (
              <Button
                className="create_from_bottom_btn col_span_2"
                buttonClassName="comman_btn"
                onClick={createPlanHandler}
                text="Create Plan"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePlanListPage;
