import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeadById } from "../../store/slice/leadSlice";
import "../../css/LoginPage.css";
import Loader from "../../component/Common/Loader";
import LeadDetail from "../../component/Lead/LeadDetail";
import moment from "moment";
import { getComplaintDetailByComplaintId } from "../../store/slice/complaintSlice";

const FeedBackDetailModal = ({ handleClose, show, feedBackId }) => {
  const showHideClassName = show && "user_detail_modal_show";
  const dispatch = useDispatch();

  const loader = useSelector((state) => state.complaint.loader);
  useEffect(() => {
    if (feedBackId) {
      dispatch(getComplaintDetailByComplaintId(feedBackId));
    }
  }, [feedBackId, dispatch]);

  const complaintDetailById = useSelector(
    (state) => state.complaint.complaintDetailId
  );

  return (
    <>
      {loader && <Loader />}
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_video_img">
              <p className="inquiry_complaint_detail_status">
                {complaintDetailById?.status}
              </p>
              {complaintDetailById?.attachment ? (
                <iframe
                  title="inquiry"
                  src={`${process.env.REACT_APP_FILE_BASE_URL}/${complaintDetailById?.attachment}`}
                  alt="Feedback item"
                  className="plan_slider_box_img"
                />
              ) : (
                <img
                  src="https://www.medstartr.com/main/images/no-image.png"
                  alt="default pic"
                  className="default_image"
                />
              )}
            </div>
            <div className="inquiry_complaint_detail_body">
              <div className="inquiry_complaint_detail_create_updated_date">
                <div className="inquiry_complaint_detail_create_date">
                  <h2>Created At </h2>
                  <p>
                    {moment(complaintDetailById?.created_at).format(
                      "DD/MM/YYYY, h:mm A"
                    )}
                  </p>
                </div>
                {complaintDetailById?.updated_at && (
                  <div className="inquiry_complaint_detail_updated_date">
                    <h2>Updated At </h2>
                    <p>
                      {moment(complaintDetailById?.updated_at).format(
                        "DD/MM/YYYY, h:mm A"
                      )}
                    </p>
                  </div>
                )}
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Description
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {complaintDetailById?.description}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">Subject</h2>
                <p className="inquiry_complaint_detail_name">
                  {complaintDetailById?.subject}
                </p>
              </div>
              {complaintDetailById?.rating && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">Rating</h2>
                  <p className="inquiry_complaint_detail_name">
                    {complaintDetailById?.rating}
                  </p>
                </div>
              )}
              {complaintDetailById?.review && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">Review</h2>
                  <p className="inquiry_complaint_detail_name">
                    {complaintDetailById?.review}
                  </p>
                </div>
              )}
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            Close
          </button>
        </div>
      </section>
    </>
  );
};

export default FeedBackDetailModal;
