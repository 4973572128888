import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { benefitsTypeHandler } from "../../../helpers/commonFunction";
import { useForm } from "../../../hooks/useForm";
import { addIcon, closeIcon, editIcon, removeIcon } from "../../../icons";
import { benefitsTypeImageProps } from "../../../props";
import { updateProjectList } from "../../../store/slice/projectSlice";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import Loader from "../../Common/Loader";
import { useState } from "react";
import {
  documentsColumn,
  drawingsColumn,
  legalDocumentsColumn,
} from "./ValidationForm";

const LegalDocumentsForm = ({ projectDetails }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const loader = useSelector((state) => state.project.loader);

  const [legalDocErr, setLegalDocErr] = useState("");
  const [drawingsErr, setDrawingsErr] = useState("");
  const [documentsErr, setDocumentsErr] = useState("");

  console.log("projectDetails", projectDetails);

  let InitialLegalDocPreview;
  let InitialMyDrawingPreview;
  let InitialDocumentPreview;
  //legalDocument
  if (projectDetails?.legal_documents) {
    InitialLegalDocPreview = JSON.parse(projectDetails?.legal_documents).map(
      (item) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`;
      }
    );
  }
  //myDrawing
  if (projectDetails?.drawings) {
    InitialMyDrawingPreview = JSON.parse(projectDetails?.drawings).map(
      (item) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`;
      }
    );
  }

  //documents
  if (projectDetails?.documents) {
    InitialDocumentPreview = JSON.parse(projectDetails?.documents).map(
      (item) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`;
      }
    );
  }

  const initialFValues = {
    legal_documents:
      projectDetails?.legal_documents &&
      JSON.parse(projectDetails?.legal_documents).length > 0
        ? JSON.parse(projectDetails?.legal_documents)
        : [
            {
              title: "",
              doc: "",
            },
          ],
    legal_documents_preview: projectDetails?.legal_documents
      ? InitialLegalDocPreview
      : [],
    pending: false,
    legalDocImgError: [],

    documents:
      projectDetails?.documents &&
      JSON.parse(projectDetails?.documents).length > 0
        ? JSON.parse(projectDetails?.documents)
        : [
            {
              title: "",
              doc: "",
            },
          ],
    documents_preview: projectDetails?.documents ? InitialDocumentPreview : [],
    myDocImgError: [],

    drawings:
      projectDetails?.drawings &&
      JSON.parse(projectDetails?.drawings).length > 0
        ? JSON.parse(projectDetails?.drawings)
        : [
            {
              title: "",
              doc: "",
            },
          ],
    drawings_preview: projectDetails?.drawings ? InitialMyDrawingPreview : [],
    drawingsImgError: [],
  };

  const { values, setValues } = useForm(initialFValues, true);

  //legalDocuments
  const legalDocumentsHandler = (e, index) => {
    setLegalDocErr("");
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.legal_documents,
      previewState: values.legal_documents_preview,
      error: values.legalDocImgError,
      prop: "legal_documents",
      previewProp: "legal_documents_preview",
      errorProp: "legalDocImgError",
      type: "benefitsTypeHandler",
      allowPdf: true,
    };

    benefitsTypeHandler(tempData);
  };

  const legalDocumentsHandleRemoveClick = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.legal_documents,
      previewState: values.legal_documents_preview,
      prop: "legal_documents",
      previewProp: "legal_documents_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };

  const legalDocumentsHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.legal_documents,
      prop: "legal_documents",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        doc: "",
      },
    };
    benefitsTypeHandler(tempData);
  };

  const legalDocumentsRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.legal_documents,
      previewState: values.legal_documents_preview,
      type: "benefitsTypeImageRemoveHandler doc",
      prop: "legal_documents",
      previewProp: "legal_documents_preview",
    };
    benefitsTypeHandler(tempData);
  };
  //myDrawingHandler
  const legalDrawingHandler = (e, index) => {
    setDrawingsErr("");
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.drawings,
      previewState: values.drawings_preview,
      error: values.drawingsImgError,
      prop: "drawings",
      previewProp: "drawings_preview",
      errorProp: "drawingsImgError",
      type: "benefitsTypeHandler",
      allowPdf: true,
    };
    benefitsTypeHandler(tempData);
  };

  const legalDrawingHandleRemoveClick = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.drawings,
      previewState: values.drawings_preview,
      prop: "drawings",
      previewProp: "drawings_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };

  const legalDrawingHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.drawings,
      prop: "drawings",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        doc: "",
      },
    };
    benefitsTypeHandler(tempData);
  };

  const drawingImageRemove = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.drawings,
      previewState: values.drawings_preview,
      type: "benefitsTypeImageRemoveHandler doc",
      prop: "drawings",
      previewProp: "drawings_preview",
    };
    benefitsTypeHandler(tempData);
  };

  //documentsHandler
  const documentsHandler = (e, index) => {
    setDocumentsErr("");
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.documents,
      previewState: values.documents_preview,
      error: values.myDocImgError,
      prop: "documents",
      previewProp: "documents_preview",
      errorProp: "myDocImgError",
      type: "benefitsTypeHandler",
      allowPdf: true,
    };
    benefitsTypeHandler(tempData);
  };

  const documentsHandleRemoveClick = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.documents,
      previewState: values.documents_preview,
      prop: "documents",
      previewProp: "documents_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };
  const documentsHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.documents,
      prop: "documents",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        doc: "",
        isGlobal: true,
      },
    };
    benefitsTypeHandler(tempData);
  };

  const documentsImageRemove = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.documents,
      previewState: values.documents_preview,
      type: "benefitsTypeImageRemoveHandler doc",
      prop: "documents",
      previewProp: "documents_preview",
    };
    benefitsTypeHandler(tempData);
  };

  const saveDataHandler = async (e) => {
    e.preventDefault();
    setValues({
      ...values,
      pending: true,
    });
    const temp_legal_data = {
      type: "fileUpload doc",
      state: values.legal_documents,
    };
    const tempLegalDoc = await benefitsTypeHandler(temp_legal_data);

    const temp_documents_data = {
      type: "fileUpload doc",
      state: values.documents,
    };
    const tempDocuments = await benefitsTypeHandler(temp_documents_data);

    const temp_drawings_data = {
      type: "fileUpload doc",
      state: values.drawings,
    };
    const tempDrawing = await benefitsTypeHandler(temp_drawings_data);

    setValues({
      ...values,
      pending: false,
    });
    //data

    // legal validation function
    let hasEmptyTitleLegal = legalDocumentsColumn(tempLegalDoc, setLegalDocErr);
    let hasEmptyTitleDrawings = drawingsColumn(tempDrawing, setDrawingsErr);
    let hasEmptyTitleDocuments = documentsColumn(
      tempDocuments,
      setDocumentsErr
    );

    if (hasEmptyTitleLegal && hasEmptyTitleDrawings && hasEmptyTitleDocuments) {
      const data = {
        legal_documents: tempLegalDoc,
        drawings: tempDrawing,
        documents: tempDocuments,
      };

      console.log("data", data);

      dispatch(updateProjectList({ data, id }));
    }
  };

  return (
    <>
      {(values.pending || loader) && <Loader />}
      <div className="project_edit_main_content single_column_body document_body">
        <div className="project_edit_feature_media_row">
          <div className="project_edit_detail_column_content">
            <h2>Legal Documents </h2>
            {values.legal_documents &&
              values.legal_documents.map((x, i) => {
                return (
                  <div key={i} className="project_edit_detail_divider">
                    <div className="project_edit_feature_input_file">
                      <Input
                        className="create_from_input_content"
                        labelClassName="create_from_label"
                        errorClassName="err_text"
                        type="file"
                        id={`legal_documents_image_file_${i}`}
                        labelOtherProps={
                          <span>
                            {!values.legal_documents[i].doc
                              ? addIcon
                              : editIcon}
                          </span>
                        }
                        name="doc"
                        accept="image/png, image/jpeg, application/pdf"
                        onChange={(e) => legalDocumentsHandler(e, i)}
                        onClick={(e) => (e.target.value = null)}
                        imageProps={
                          x.doc && (
                            <>
                              <iframe
                                title="ampp"
                                src={values.legal_documents_preview[i]}
                                className="profile_edit_iframe"
                              />
                              <Button
                                buttonClassName="image_remove_btn"
                                onClick={() => legalDocumentsRemoveHandler(i)}
                                other={closeIcon}
                              />
                            </>
                          )
                        }
                      />
                    </div>
                    {values.legalDocImgError &&
                      values.legalDocImgError.length > 0 && (
                        <span key={i} className="err_text">
                          {values.legalDocImgError[i]}
                        </span>
                      )}
                    <Input
                      className="project_edit_feature_input"
                      type="text"
                      placeholder="Legal Documents Title"
                      label="Title"
                      id="title"
                      name="title"
                      value={x.title}
                      onChange={(e) => legalDocumentsHandler(e, i)}
                    />

                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.legal_documents.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => legalDocumentsHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.legal_documents.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => legalDocumentsHandleAddClick(i)}
                          text="Add"
                          other={addIcon}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            {legalDocErr && <span className="err_text">{legalDocErr}</span>}
          </div>
          <>
            <div className="project_edit_detail_column_content">
              <h2>My Drawing</h2>
              {values.drawings &&
                values.drawings.map((x, i) => {
                  return (
                    <div key={i} className="project_edit_detail_divider">
                      <div className="project_edit_feature_input_file">
                        <Input
                          className="create_from_input_content"
                          labelClassName="create_from_label"
                          errorClassName="err_text"
                          type="file"
                          id={`legal_drawing${i}`}
                          labelOtherProps={
                            <span>
                              {!values.drawings[i].doc ? addIcon : editIcon}
                            </span>
                          }
                          name="doc"
                          accept="image/png,  image/jpeg, application/pdf"
                          onChange={(e) => legalDrawingHandler(e, i)}
                          onClick={(e) => (e.target.value = null)}
                          imageProps={
                            x.doc && (
                              <>
                                <iframe
                                  title="ampp"
                                  src={values.drawings_preview[i]}
                                  className="profile_edit_iframe"
                                />
                                <Button
                                  buttonClassName="image_remove_btn"
                                  onClick={() => drawingImageRemove(i)}
                                  other={closeIcon}
                                />
                              </>
                            )
                          }
                        />
                      </div>
                      {values.drawingsImgError &&
                        values.drawingsImgError.length > 0 && (
                          <span key={i} className="err_text">
                            {values.drawingsImgError[i]}
                          </span>
                        )}
                      <Input
                        className="project_edit_feature_input"
                        type="text"
                        placeholder="Drawing Title"
                        label="Title"
                        id="title"
                        name="title"
                        value={x.title}
                        onChange={(e) => legalDrawingHandler(e, i)}
                      />
                      <div className="project_edit_feature_add_remove_btn_row">
                        {values.drawings.length !== 1 && (
                          <Button
                            buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                            onClick={() => legalDrawingHandleRemoveClick(i)}
                            text="Remove"
                            other={removeIcon}
                          />
                        )}
                        {values.drawings.length - 1 === i && (
                          <Button
                            buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                            onClick={() => legalDrawingHandleAddClick(i)}
                            text="Add"
                            other={addIcon}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              {drawingsErr && <span className="err_text">{drawingsErr}</span>}
            </div>
          </>
          <>
            <div className="project_edit_detail_column_content">
              <h2>My Documents</h2>
              {values.documents &&
                values.documents.map((x, i) => {
                  return (
                    <div key={i} className="project_edit_detail_divider">
                      <div className="project_edit_feature_input_file">
                        <Input
                          className="create_from_input_content"
                          labelClassName="create_from_label"
                          errorClassName="err_text"
                          type="file"
                          id={`documents${i}`}
                          labelOtherProps={
                            <span>
                              {!values.documents[i].doc ? addIcon : editIcon}
                            </span>
                          }
                          name="doc"
                          accept="image/png,  image/jpeg, application/pdf"
                          onChange={(e) => documentsHandler(e, i)}
                          onClick={(e) => (e.target.value = null)}
                          imageProps={
                            x.doc && (
                              <>
                                <iframe
                                  title="ampp"
                                  src={values.documents_preview[i]}
                                  className="profile_edit_iframe"
                                />
                                <Button
                                  buttonClassName="image_remove_btn"
                                  onClick={() => documentsImageRemove(i)}
                                  other={closeIcon}
                                />
                              </>
                            )
                          }
                        />
                      </div>
                      {values.myDocImgError &&
                        values.myDocImgError.length > 0 && (
                          <span key={i} className="err_text">
                            {values.myDocImgError[i]}
                          </span>
                        )}
                      <Input
                        className="project_edit_feature_input"
                        type="text"
                        placeholder="Documents Title"
                        label="Title"
                        id="title"
                        name="title"
                        value={x.title}
                        onChange={(e) => documentsHandler(e, i)}
                      />
                      <div className="project_edit_feature_add_remove_btn_row">
                        {values.documents.length !== 1 && (
                          <Button
                            buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                            onClick={() => documentsHandleRemoveClick(i)}
                            text="Remove"
                            other={removeIcon}
                          />
                        )}
                        {values.documents.length - 1 === i && (
                          <Button
                            buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                            onClick={() => documentsHandleAddClick(i)}
                            text="Add"
                            other={addIcon}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              {documentsErr && <span className="err_text">{documentsErr}</span>}
            </div>
          </>
        </div>
        <Button
          className="legal_doc_button"
          buttonClassName="comman_btn"
          onClick={saveDataHandler}
          text="Save"
        />
      </div>
    </>
  );
};

export default LegalDocumentsForm;
