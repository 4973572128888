import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import Select from "react-select";
import { routes } from "../../constants";
import { createNotice, updateNoticeList } from "../../store/slice/noticeSlice";
import Input from "../../component/Common/Input";
import TextArea from "../../component/Common/TextArea";
import Button from "../../component/Common/Button";
import { useForm } from "../../hooks/useForm";
import { getProjectList } from "../../store/slice/projectSlice";
import { imageProps } from "../../props";
import { fileUpload } from "../../helpers/fileUpload";

const CreateNoticePage = ({ notifications }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialFValues = {
    user_type: notifications?.user_type ? notifications?.user_type : "ALL",
    project_id: notifications?.project_id
      ? {
          value: notifications?.project_name,
          label: notifications?.project_name,
          id: notifications?.project_id,
        }
      : null,
    subject: notifications?.subject ? notifications?.subject : "",
    message: notifications?.message ? notifications?.message : "",
  };

  const [show, setShow] = useState(false);

  const showModal = async () => {
    if (validate()) {
      setShow(true);
    }
  };

  const hideModal = () => {
    setShow(false);
  };

  useEffect(() => {
    dispatch(getProjectList({ complaint: "true" }));
  }, [dispatch]);

  const projectList = useSelector((state) => state.project.projects);

  const projectIdOptions = projectList.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const params = useParams();
  const { id } = params;
  const loader = useSelector((state) => state.notifications.loader);

  const showHideClassName = show && "user_detail_modal_show";

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("subject" in fieldValues) {
      temp.subject = fieldValues.subject ? "" : "Subject is required !";
    }
    if ("message" in fieldValues) {
      temp.message = fieldValues.message ? "" : "Message is required !";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const attachmentRemoveHandler = () => {
    setValues({
      ...values,
      attachment: "",
      attachment_preview: "",
    });
  };

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialFValues,
    true,
    validate
  );

  const createNoticeHandler = async () => {
    setShow(false);
    let attachmentUrl;
    const formData = new FormData();
    if (values.attachment && typeof values.attachment !== "string") {
      formData.append("file", values.attachment);

      if (validate()) {
        attachmentUrl = await fileUpload(formData);
      }
      formData.delete("file");
    } else {
      attachmentUrl = values.attachment;
    }

    const data = {
      notification_for: values.user_type,
      subject: values.subject,
      message: values.message,
    };
    if (attachmentUrl) {
      data.attachment = attachmentUrl;
    }
    if (values?.project_id?.id) {
      data.project_id = values?.project_id?.id;
    }

    dispatch(createNotice({ data, navigate }));
  };

  return (
    <>
      {loader && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.notifications}/page/1`}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <div className="create_from_input_content col_span_2">
            <label className="create_from_label">User </label>
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="All"
              id="ALL"
              name="user_type"
              value="ALL"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values.user_type === "ALL"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="All Brokers"
              id="BROKER"
              name="user_type"
              value="BROKER"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values.user_type === "BROKER"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="All Customers"
              id="GENERAL_CUSTOMER"
              name="user_type"
              value="GENERAL_CUSTOMER"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values.user_type === "GENERAL_CUSTOMER"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Project Wise Customers"
              id="CUSTOMER"
              name="user_type"
              value="CUSTOMER"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values.user_type === "CUSTOMER"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="iOS"
              id="IOS"
              name="user_type"
              value="IOS"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values.user_type === "IOS"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Android"
              id="ANDROID"
              name="user_type"
              value="ANDROID"
              onChange={(e) => {
                handleInputChange(e);
              }}
              checked={values.user_type === "ANDROID"}
            />
          </div>
          {values.user_type === "CUSTOMER" && (
            <div className="create_from_input_content">
              <label className="create_from_label">Project</label>
              <Select
                value={values.project_id}
                className="basic-multi-select"
                classNamePrefix="select"
                name="project_id"
                id="project_id"
                options={projectIdOptions}
                onChange={(e) => handleInputChange(e, "project_id")}
                required
              />
            </div>
          )}
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter subject"
            label="Subject"
            id="subject"
            name="subject"
            value={values.subject}
            onChange={handleInputChange}
            error={errors?.subject || ""}
          />
          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Message"
            label="Message"
            id="message"
            name="message"
            value={values.message}
            onChange={handleInputChange}
            error={errors?.message || ""}
          />
          <div>
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              errorClassName="err_text"
              type="file"
              label="Attachment"
              id="attachment"
              name="attachment"
              accept="image/png, image/jpeg"
              onChange={handleInputChange}
              // error={errors?.logo || ""}
              onClick={(e) => (e.target.value = null)}
              imageProps={imageProps(
                values.attachment,
                values.attachment_preview,
                attachmentRemoveHandler,
                "attachment"
              )}
            />
            <p className="create_project_review">
              300 x 200 (3 : 2),The maximum size for an upload is 1 MB
            </p>
          </div>
          {id ? (
            <Button
              className="create_from_bottom_btn col_span_2"
              buttonClassName="comman_btn"
              onClick={showModal}
              text="Edit Notification"
              other=""
            />
          ) : (
            <Button
              className="create_from_bottom_btn col_span_2"
              buttonClassName="comman_btn"
              onClick={showModal}
              text="Create Notification"
              other=""
            />
          )}
        </div>
      </div>
      <>
        <div
          className={`user_detail_modal_bg  ${showHideClassName}`}
          onClick={hideModal}
        ></div>
        <section className={`user_detail_modal_main ${showHideClassName}`}>
          {/* <div className="comman_btn_container center_back_btn">
        <Link to={routes.leads} className="comman_btn back_btn">
          Back
        </Link>
      </div> */}
          {/* <LeadDetail leadListId={leadListId} /> */}
          <span className="create_notice_heading">
            Are you sure for create this notifications ?
          </span>
          <div className="create_notice_buttons">
            <button
              type="button"
              onClick={createNoticeHandler}
              className="user_detail_modal_close_btn comman_btn"
            >
              Confirm
            </button>
            <button
              type="button"
              onClick={hideModal}
              className="user_detail_modal_close_btn comman_btn"
            >
              Close
            </button>
          </div>
        </section>
      </>
    </>
  );
};

export default CreateNoticePage;
