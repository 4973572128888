import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { benefitsTypeHandler } from "../../../helpers/commonFunction";
import { updateProjectList } from "../../../store/slice/projectSlice";
import Loader from "../../Common/Loader";
import { addIcon, editIcon, removeIcon } from "../../../icons/index";
import { useForm } from "../../../hooks/useForm";
import Input from "../../Common/Input";
import { benefitsTypeImageProps } from "../../../props";
import Button from "../../Common/Button";
import { onSiteActualColumn } from "./ValidationForm";

const OnSiteActualForm = ({ projectDetails }) => {
  let InitialOnsiteImagePreview;
  if (projectDetails?.onsite_actuals) {
    InitialOnsiteImagePreview = JSON.parse(projectDetails?.onsite_actuals).map(
      (item) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}/${item.image}`;
      }
    );
  }

  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;

  const loader = useSelector((state) => state.project.loader);

  const [onSiteErr, setOnSiteErr] = useState("");

  const initialFValues = {
    onsite_actuals:
      projectDetails?.onsite_actuals &&
      JSON.parse(projectDetails?.onsite_actuals).length > 0
        ? JSON.parse(projectDetails?.onsite_actuals)
        : [
            {
              title: "",
              image: "",
            },
          ],
    onsite_actuals_preview: projectDetails?.onsite_actuals
      ? InitialOnsiteImagePreview
      : [],
    pending: false,
    imageError: [],
  };

  const { values, setValues } = useForm(initialFValues, true);

  const onSiteActualsHandler = (e, index) => {
    setOnSiteErr("");
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.onsite_actuals,
      previewState: values.onsite_actuals_preview,
      error: values.imageError,
      prop: "onsite_actuals",
      previewProp: "onsite_actuals_preview",
      errorProp: "imageError",
      type: "benefitsTypeHandler",
    };
    benefitsTypeHandler(tempData);
  };
  // handle click event of the Remove button
  const onsiteActualsHandleRemoveClick = (index) => {
    setOnSiteErr("");
    const tempData = {
      index,
      values,
      setValues,
      state: values.onsite_actuals,
      previewState: values.onsite_actuals_preview,
      prop: "onsite_actuals",
      previewProp: "onsite_actuals_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };
  // handle click event of the Add button
  const onSiteActualsHandleAddClick = () => {
    setOnSiteErr("");
    const tempData = {
      values,
      setValues,
      state: values.onsite_actuals,
      prop: "onsite_actuals",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        image: "",
      },
    };
    benefitsTypeHandler(tempData);
  };
  const imageRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.onsite_actuals,
      previewState: values.onsite_actuals_preview,
      type: "benefitsTypeImageRemoveHandler image",
      prop: "onsite_actuals",
      previewProp: "onsite_actuals_preview",
    };
    benefitsTypeHandler(tempData);
  };

  const saveDataHandler = async (e) => {
    e.preventDefault();

    const tempData = {
      type: "fileUpload image",
      state: values.onsite_actuals,
    };

    const hasEmptyTitle = onSiteActualColumn(tempData.state, setOnSiteErr);

    if (hasEmptyTitle) {
      console.log("");
    } else {
      setOnSiteErr("");
      setValues({
        ...values,
        pending: true,
      });
      const tempOnSiteActuals = await benefitsTypeHandler(tempData);
      setValues({
        ...values,
        pending: false,
      });
      const data = {
        onsite_actuals: tempOnSiteActuals,
      };
      dispatch(updateProjectList({ data, id }));
    }
  };

  return (
    <>
      {(values.pending || loader) && <Loader />}
      <div className="project_edit_main_content single_column_body">
        <div className="create_from_row">
          <div className="project_edit_detail_column_content">
            <h2>Onsite Actuals</h2>
            {values.onsite_actuals &&
              values.onsite_actuals.map((x, i) => {
                return (
                  <div key={i} className="project_edit_detail_divider">
                    <div className="project_edit_feature_input_file">
                      <Input
                        className="create_from_input_content"
                        labelClassName="create_from_label"
                        errorClassName="err_text"
                        type="file"
                        id={`onsite_image_file${i}`}
                        labelOtherProps={
                          <span>
                            {!values.onsite_actuals[i].image
                              ? addIcon
                              : editIcon}
                          </span>
                        }
                        name="image"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => onSiteActualsHandler(e, i)}
                        onClick={(e) => (e.target.value = null)}
                        imageProps={benefitsTypeImageProps(
                          x.image,
                          values.onsite_actuals_preview[i],
                          i,
                          imageRemoveHandler
                        )}
                      />
                    </div>
                    <p className="create_project_review">
                      1280 x 720 (16 : 9),The maximum size for an upload is 2 MB
                    </p>
                    {values.imageError && values.imageError.length > 0 && (
                      <p className="img_error">{values.imageError[i]}</p>
                    )}
                    <Input
                      className="project_edit_feature_input"
                      type="text"
                      placeholder="Enter Title"
                      label="Title"
                      id="title"
                      name="title"
                      value={x.title}
                      onChange={(e) => onSiteActualsHandler(e, i)}
                    />
                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.onsite_actuals.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => onsiteActualsHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.onsite_actuals.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => onSiteActualsHandleAddClick(i)}
                          text="Add"
                          other={addIcon}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            {onSiteErr && <span className="err_text">{onSiteErr}</span>}
          </div>
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={saveDataHandler}
          text="Save"
        />
      </div>
    </>
  );
};

export default OnSiteActualForm;
