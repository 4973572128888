import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CreatePropertyForm from "../../component/Property/CreatePropertyForm";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import { getCustomerPropertyByCustomerId } from "../../store/slice/customerPropertySlice";
import ImportContactEditProperty from "../../pages/Property/ImportContactEditProperty";

const CustomerPropertyEditPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const loader = useSelector((state) => state.customerProperty.loader);
  const params = useParams();
  const navigate = useNavigate();
  const { id, page_number } = params;

  const location = useLocation();
  const value = location?.state?.value;

  const dispatch = useDispatch();

  const propertyDetailByCustomerId = useSelector(
    (state) => state.customerProperty.getCustomerPropertyCustomerId
  );

  useEffect(() => {
    if (!propertyDetailByCustomerId && id) {
      dispatch(getCustomerPropertyByCustomerId(id));
    } else {
      setIsLoading(false);
    }
  }, [dispatch, propertyDetailByCustomerId, id]);

  if (isLoading) {
    return <div />;
  }

  return (
    <>
      {loader && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <>
          {value ? (
            <Link
              to={`${routes.customerProperty}/page/${page_number || 1}`}
              state={{ value }}
              className="comman_btn back_btn"
            >
              Back
            </Link>
          ) : (
            <button
              className="comman_btn back_btn"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          )}
          <Link
            className="comman_btn back_btn lead_status-options"
            to={`${routes.customerProperty}/edit/${id}/assigncontact/${page_number}`}
            state={{ propertyDetailByCustomerId: propertyDetailByCustomerId }}
          >
            Assign contacts
          </Link>
        </>
      </div>

      <div className="project_edit_main_content">
        <div className="create_from_row">
          <CreatePropertyForm
            propertyDetailByCustomerId={propertyDetailByCustomerId}
            value={value}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerPropertyEditPage;
