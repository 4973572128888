import React from "react";

const DeleteModal = ({ values, handleDeleteClose, sendOtpModal }) => {
  return (
    <>
      <div
        className={`user_detail_modal_bg  ${
          values.showDeleteModal && "user_detail_modal_show"
        }`}
        onClick={handleDeleteClose}
      ></div>
      <section
        className={`user_detail_modal_main user_delete_btn ${
          values.showDeleteModal && "user_detail_modal_show"
        }`}
      >
        <h4 className="modal-title">Are you sure?</h4>
        <div className="modal-body">
          <p>Do you really want to delete this user ?</p>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleDeleteClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={sendOtpModal}
          >
            Send OTP
          </button>
        </div>
      </section>
    </>
  );
};

export default DeleteModal;
