import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "../../../hooks/useForm";
import { updateProjectList } from "../../../store/slice/projectSlice";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import Loader from "../../Common/Loader";
import TextArea from "../../Common/TextArea";

const AddressesForm = ({ projectDetails }) => {
  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;

  const loader = useSelector((state) => state.project.loader);

  const initialFValues = {
    // sangini_group: projectDetails?.addresses
    //   ? JSON.parse(projectDetails?.addresses)?.sangini_group
    //   : "",
    project_address: projectDetails?.addresses
      ? JSON.parse(projectDetails?.addresses)?.project_address
      : "",
    project_address_lat: projectDetails?.addresses
      ? JSON.parse(projectDetails?.addresses)?.project_address_lat
      : "",
    project_address_lon: projectDetails?.addresses
      ? JSON.parse(projectDetails?.addresses)?.project_address_lon
      : "",
    booking_address: projectDetails?.addresses
      ? JSON.parse(projectDetails?.addresses)?.booking_address
      : "",
    booking_address_lat: projectDetails?.addresses
      ? JSON.parse(projectDetails?.addresses)?.booking_address_lat
      : "",
    booking_address_lon: projectDetails?.addresses
      ? JSON.parse(projectDetails?.addresses)?.booking_address_lon
      : "",
  };

  const { values, handleInputChange } = useForm(initialFValues, true);

  const saveDataHandler = (e) => {
    e.preventDefault();
    const data = {
      addresses: {
        // sangini_group: values.sangini_group,
        project_address: values.project_address.trim(),
        project_address_lat: values.project_address_lat.trim(),
        project_address_lon: values.project_address_lon.trim(),
        booking_address: values.booking_address.trim(),
        booking_address_lat: values.booking_address_lat.trim(),
        booking_address_lon: values.booking_address_lon.trim(),
      },
    };
    dispatch(updateProjectList({ data: data, id: id }));
  };

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content center_table_body">
        <div className="project_edit_detail_column_content">
          <h2>Addresses</h2>
          {/* <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            type="text"
            placeholder="Sangini Group Address"
            label="Sangini Group"
            id="sangini_group"
            name="sangini_group"
            value={values.sangini_group}
            onChange={handleInputChange}
          /> */}
          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            type="text"
            placeholder="Enter Project Address"
            label="Project Address"
            id="project_address"
            name="project_address"
            value={values.project_address}
            onChange={handleInputChange}
          />
          <Input
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            type="text"
            placeholder="Enter Project Address Latitude Ex. (11.1111)"
            label="Project Address Latitude"
            id="project_address_lat"
            name="project_address_lat"
            value={values.project_address_lat}
            onChange={handleInputChange}
          />
          <Input
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            type="text"
            placeholder="Enter Project Address Longitude Ex. (11.1111)"
            label="Project Address Longitude"
            id="project_address_lon"
            name="project_address_lon"
            value={values.project_address_lon}
            onChange={handleInputChange}
          />
          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            type="text"
            placeholder="Enter Booking Address"
            label="Booking Address"
            id="booking_address"
            name="booking_address"
            value={values.booking_address}
            onChange={handleInputChange}
          />
          <Input
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            type="text"
            placeholder="Enter Booking Address Latitude Ex. (11.1111)"
            label="Booking Address Latitude"
            id="booking_address_lat"
            name="booking_address_lat"
            value={values.booking_address_lat}
            onChange={handleInputChange}
          />
          <Input
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            type="text"
            placeholder="Enter Booking Address Longitude Ex. (11.1111)"
            label="Booking Address Longitude"
            id="booking_address_lon"
            name="booking_address_lon"
            value={values.booking_address_lon}
            onChange={handleInputChange}
          />
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={saveDataHandler}
          text="Save"
        />
      </div>
    </>
  );
};

export default AddressesForm;
