import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

//createContact
export const createContact = createAsyncThunk(
  "createContact",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/projects/add-contact",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Contact Create Successfully !", {
          autoClose: 2000,
        });
        data.navigate(`${routes.contacts}/page/1`);
      }
      return response.data;
    } catch (error) {
      console.log("createContactError", error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//getContact
export const getContact = createAsyncThunk(
  "getContact",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `projects/get/contacts/list?start=${data.start || 0}&limit=${
          data.limit || 10
        }&id=${data.id || ""}&search=${data.search || ""}`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("getContact", error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//getContactById
export const getContactById = createAsyncThunk("getContactById", async (id) => {
  try {
    const response = await Axios.get(
      `/projects/get/contact/${id}`,
      authHeaders()
    );
    return response.data;
  } catch (error) {
    console.log("getContactById", error.response);
  }
});

//updateContact
export const updateContact = createAsyncThunk(
  "updateContact",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/projects/update/contact/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.info("Contact updated successfully !", {
          autoClose: 2000,
        });
        data.navigate(`${routes.contacts}/page/${data.page_number}`);
      }
      if (data.cb) {
        toast.error("Contact deleted successfully !", {
          autoClose: 2000,
        });
        return data.cb(null, response.data);
      }

      return response.data;
    } catch (error) {
      console.log("updateContact", error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//clearContact
export const clearContact = createAsyncThunk(
  "clearContact",
  async (data, thunkAPI) => {
    return;
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    createContact: null,
    contactList: [],
    getContactById: null,
    updateContact: null,
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //createContact
    builder.addCase(createContact.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(createContact.fulfilled, (state, action) => {
      state.createContact = action.payload;
      state.loader = false;
    });
    builder.addCase(createContact.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getContact
    builder.addCase(getContact.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getContact.fulfilled, (state, action) => {
      state.contactList = action.payload;
      state.loader = false;
    });
    builder.addCase(getContact.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getContactById
    builder.addCase(getContactById.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getContactById.fulfilled, (state, action) => {
      state.getContactById = action.payload;
      state.loader = false;
    });
    builder.addCase(getContactById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //updateContact
    builder.addCase(updateContact.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(updateContact.fulfilled, (state, action) => {
      state.updateContact = action.payload;
      state.loader = false;
    });
    builder.addCase(updateContact.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //clearContact
    builder.addCase(clearContact.fulfilled, (state, action) => {
      state.createContact = null;
      state.updateContact = null;
      state.getContactById = null;
      state.loader = false;
    });
  },
});
export default contactSlice.reducer;
