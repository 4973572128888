import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fileUpload } from "../../../helpers/fileUpload";
import { useForm } from "../../../hooks/useForm";
import { updateProjectList } from "../../../store/slice/projectSlice";
import Input from "../../Common/Input";
import Loader from "../../Common/Loader";
import { addIcon, removeIcon } from "../../../icons/index";
import Button from "../../Common/Button";

const AmenitiesForm = ({ projectDetails }) => {
  let InitialAmenitiesPdfPreview;
  if (projectDetails?.amenities_pdf) {
    InitialAmenitiesPdfPreview = `${process.env.REACT_APP_FILE_BASE_URL}/${projectDetails?.amenities_pdf}`;
  }

  const dispatch = useDispatch();
  const params = useParams();

  const { id } = params;
  const loader = useSelector((state) => state.project.loader);

  const initialFValues = {
    amenities_pdf: projectDetails?.amenities_pdf
      ? projectDetails?.amenities_pdf
      : "",
    amenities_pdf_preview: InitialAmenitiesPdfPreview
      ? InitialAmenitiesPdfPreview
      : "",
    amenities: projectDetails?.amenities
      ? JSON.parse(projectDetails?.amenities)
      : [{ amenities: "" }],
    pending: false,
  };

  const amenitiesHandler = (e, index) => {
    const { name, value } = e.target;
    const list = [...values.amenities];
    list[index][name] = value;
    setValues({
      ...values,
      amenities: list,
    });
  };

  // handle click event of the Remove button
  const amenitiesHandleRemoveClick = (index) => {
    const list = [...values.amenities];
    list.splice(index, 1);
    setValues({
      ...values,
      amenities: list,
    });
  };

  // handle click event of the Add button
  const amenitiesHandleAddClick = () => {
    setValues({
      ...values,
      amenities: [...values.amenities, { amenities: "" }],
    });
  };

  const amenitiesPdfRemoveHandler = () => {
    setValues({
      ...values,
      amenities_pdf: "",
      amenities_pdf_preview: "",
    });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("amenities_pdf" in fieldValues) {
      temp.amenities_pdf = "";
      if (
        fieldValues?.amenities_pdf?.name &&
        !fieldValues?.amenities_pdf?.name?.match(/.(pdf)$/i)
      ) {
        temp.amenities_pdf = "Invalid Documents";
        setValues({
          ...values,
          amenities_pdf_preview: "",
          amenities_pdf: "",
        });
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialFValues,
    true,
    validate
  );
  const saveDataHandler = async (e) => {
    e.preventDefault();
    setValues({ ...values, pending: true });

    const formData = new FormData();

    let pdfUrl;
    if (typeof values.amenities_pdf !== "string") {
      formData.append("file", values.amenities_pdf);

      pdfUrl = await fileUpload(formData);
      formData.delete("file");
    } else {
      pdfUrl = values.amenities_pdf;
    }
    setValues({ ...values, pending: false });
    const trAmenities = values.amenities.map((item, i) => {
      return { amenities: item.amenities.trim() };
    });
    const data = {
      amenities: trAmenities,
      amenities_pdf: pdfUrl,
    };
    dispatch(updateProjectList({ data, id }));
  };

  return (
    <>
      {(values.pending || loader) && <Loader />}
      <div className="project_edit_main_content amenities_body">
        <div className="create_from_row">
          <div className="project_edit_detail_column_content amenieties_file_select_content">
            <Input
              className="project_edit_detail_column_content amenieties_file_select_content"
              labelClassName="select_amenieties_file_btn"
              inputClassName=""
              errorClassName="err_text"
              type="file"
              label="Choose Pdf"
              id="amenities_pdf"
              name="amenities_pdf"
              onClick={(e) => (e.target.value = null)}
              onChange={handleInputChange}
              error={errors?.amenities_pdf || ""}
              labelOtherProps={addIcon}
              labelHeading={<h2>Amenities Pdf </h2>}
              accept={"application/pdf"}
            />
            <p className="create_project_review">
              The maximum size for an upload 50 MB
            </p>
            {values.amenities_pdf && (
              <>
                <Button
                  className=""
                  buttonClassName="remove_pdf_btn"
                  onClick={amenitiesPdfRemoveHandler}
                  text="Remove Pdf"
                  other={removeIcon}
                />
                <iframe
                  title="ampp"
                  src={values.amenities_pdf_preview}
                  type="application/pdf"
                  className="profile_edit_iframe"
                />
              </>
            )}
          </div>
          <div className="project_edit_detail_column_content">
            <h2>Amenities </h2>
            {values.amenities &&
              values.amenities.map((x, i) => {
                return (
                  <div key={i} className="project_edit_detail_divider">
                    <Input
                      className="project_edit_feature_input"
                      type="text"
                      placeholder="Enter Amenities"
                      id="amenities"
                      name="amenities"
                      value={x.amenities}
                      onChange={(e) => amenitiesHandler(e, i)}
                    />
                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.amenities.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_remove_btn"
                          onClick={() => amenitiesHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.amenities.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => amenitiesHandleAddClick(i)}
                          text="Add"
                          other={addIcon}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={saveDataHandler}
          text="Save"
        />
      </div>
    </>
  );
};

export default AmenitiesForm;
