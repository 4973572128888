import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserById, propertyAssignedUser } from "../../store/slice/userSlice";

const CustomerPropertyDetailModal = ({
  handleClose,
  show,
  values,
  setValues,
}) => {
  const dispatch = useDispatch();
  const showHideClassName = show && "user_detail_modal_show";
  let access_user_id_modal = values?.job_access_id;

  useEffect(() => {
    const data = {
      assign_user: access_user_id_modal,
    };
    access_user_id_modal &&
      dispatch(propertyAssignedUser({ data })).then((res) => {
        setValues({ ...values, propertyDetailModalData: res.payload });
      });
  }, [access_user_id_modal, dispatch]);

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">Customer Name</th>
                <th className="custom_data_table_heading">User (Main / Sub)</th>
                <th className="custom_data_table_heading">Phone Number</th>
                <th className="custom_data_table_heading">Email</th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {values.propertyDetailModalData &&
                values.propertyDetailModalData.map(
                  (item, i) =>
                    item && (
                      <tr className="custom_data_table_row" key={i}>
                        <td className="custom_data_table_item table_item">
                          {item.first_name} {item.last_name}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.id === values.job_user_id
                            ? "Main User"
                            : "Sub User"}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.phone_number}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.email}
                        </td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default CustomerPropertyDetailModal;
