import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getProjectList } from "../../store/slice/projectSlice";
import { getPlan } from "../../store/slice/planSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  createCustomerProperty,
  getCustomerPropertyByCustomerId,
  updateCustomerPropertyDetail,
} from "../../store/slice/customerPropertySlice";
import { useForm } from "../../hooks/useForm";
import Input from "../Common/Input";
import { benefitsTypeHandler } from "../../helpers/commonFunction";
import { addIcon, closeIcon, editIcon, removeIcon } from "../../icons";
import Button from "../Common/Button";
import { getUserList } from "../../store/slice/userSlice";
import { useState } from "react";
import CreateUserForProp from "./CreateUserForProp";
import { getContact } from "../../store/slice/contactSlice";

const CreatePropertyForm = ({
  userForProperty,
  propertyDetailByCustomerId,
  value,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id, page_number } = params;
  const navigate = useNavigate();
  const first = useRef(false);
  const projectList = useSelector((state) => state.project.projects);

  const contactList = useSelector((state) => state.contact.contactList.data);

  const planList = useSelector((state) => state.plan.planDetails?.data);
  const userList = useSelector((state) => state.user.users?.data);

  let InitialLegalDocsPreview;
  let InitialMyDocsPreview;
  let InitialDrawingsPreview;

  const [addUserModal, setAddUserModal] = useState(false);
  const [select_user, setSelect_user] = useState(null);

  const [legal_doc_err, setLegal_doc_err] = useState("");

  if (propertyDetailByCustomerId?.cp_legal_documents) {
    InitialLegalDocsPreview = JSON.parse(
      propertyDetailByCustomerId?.cp_legal_documents
    ).map((item) => {
      return `${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`;
    });
  }

  if (propertyDetailByCustomerId?.cp_documents) {
    InitialMyDocsPreview = JSON.parse(
      propertyDetailByCustomerId?.cp_documents
    ).map((item) => {
      return `${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`;
    });
  }

  if (propertyDetailByCustomerId?.cp_drawings) {
    InitialDrawingsPreview = JSON.parse(
      propertyDetailByCustomerId?.cp_drawings
    ).map((item) => {
      return `${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`;
    });
  }

  const initialFValues = {
    project_id: propertyDetailByCustomerId?.cp_project_id
      ? {
          value: propertyDetailByCustomerId?.project_name,
          label: propertyDetailByCustomerId?.project_name,
          id: propertyDetailByCustomerId?.cp_project_id,
        }
      : null,
    tower: propertyDetailByCustomerId?.cp_tower_name
      ? {
          value: propertyDetailByCustomerId.cp_tower_name,
          label: propertyDetailByCustomerId.cp_tower_name,
        }
      : null,
    property: propertyDetailByCustomerId?.cp_property_number
      ? propertyDetailByCustomerId?.cp_property_number
      : "",
    plan: propertyDetailByCustomerId?.cp_plan_id
      ? {
          value: propertyDetailByCustomerId.pl_display_name,
          label: propertyDetailByCustomerId.pl_display_name,
          id: propertyDetailByCustomerId.cp_plan_id,
        }
      : null,
    user_data: null,
    drawings:
      propertyDetailByCustomerId?.cp_drawings &&
      JSON.parse(propertyDetailByCustomerId?.cp_drawings).length > 0
        ? JSON.parse(propertyDetailByCustomerId?.cp_drawings)
        : [
            {
              title: "",
              doc: "",
            },
          ],
    drawings_preview: propertyDetailByCustomerId?.cp_drawings
      ? InitialDrawingsPreview
      : [],
    drawings_error: [],
    legal_documents:
      propertyDetailByCustomerId?.cp_legal_documents &&
      JSON.parse(propertyDetailByCustomerId?.cp_legal_documents).length > 0
        ? JSON.parse(propertyDetailByCustomerId?.cp_legal_documents)
        : [
            {
              title: "",
              doc: "",
            },
          ],
    legal_documents_preview: propertyDetailByCustomerId?.cp_legal_documents
      ? InitialLegalDocsPreview
      : [],
    legal_documents_error: [],
    documents:
      propertyDetailByCustomerId?.cp_documents &&
      JSON.parse(propertyDetailByCustomerId?.cp_documents).length > 0
        ? JSON.parse(propertyDetailByCustomerId?.cp_documents)
        : [
            {
              title: "",
              doc: "",
            },
          ],
    documents_preview: propertyDetailByCustomerId?.cp_documents
      ? InitialMyDocsPreview
      : [],
    documents_error: [],
    isPropUser: false,
    select_user: null,
    plan_documents: [],
    plan_drawings: [],
    plan_legal_documents: [],
    //Check box
    contacts: propertyDetailByCustomerId?.contacts
      ? JSON.parse(propertyDetailByCustomerId?.contacts)
      : [],
    property_contacts: propertyDetailByCustomerId?.property_contacts
      ? JSON.parse(propertyDetailByCustomerId?.property_contacts)
      : [],
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("project_id" in fieldValues) {
      temp.project_id = fieldValues?.project_id
        ? ""
        : "Project Id is required !";
    }
    if ("tower" in fieldValues) {
      temp.tower = fieldValues?.tower ? "" : "Tower name is required !";
    }
    if ("property" in fieldValues) {
      temp.property = fieldValues?.property
        ? ""
        : "Property name is required !";
    }
    // if ("plan" in fieldValues) {
    //   temp.plan = fieldValues?.plan ? "" : "Plan name is required !";
    // }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const filteredContactList =
    contactList && values.contacts && values.contacts.length > 0
      ? contactList.filter((contact) => values.contacts.includes(contact.id))
      : [];

  useEffect(() => {
    if (projectList?.length > 0 && values.project_id?.id) {
      let towerList =
        projectList?.find((pr) => pr.id === values.project_id?.id)
          ?.tower_names &&
        JSON.parse(
          projectList?.find((pr) => pr.id === values.project_id?.id)
            ?.tower_names
        );
      if (first.current) {
        setValues({ ...values, towers: towerList, tower: null, plan: null });
      } else {
        setValues({ ...values, towers: towerList });
      }
    }
  }, [setValues, values.project_id, projectList]);

  useEffect(() => {
    if (userForProperty) {
      setValues({ ...values, user_data: userForProperty, isPropUser: true });
    } else {
      setValues({
        ...values,
        user_data: propertyDetailByCustomerId,
        access_user_id:
          propertyDetailByCustomerId?.access_user_id &&
          JSON.parse(propertyDetailByCustomerId?.access_user_id),
        isPropUser: propertyDetailByCustomerId?.cp_user_id ? true : false,
      });
    }
  }, [dispatch, userForProperty, propertyDetailByCustomerId]);

  useEffect(() => {
    dispatch(getProjectList({ complaint: "true" }));
    dispatch(getUserList({ userType: "Customer", start: 0, limit: 100000000 }));
    dispatch(getContact({ start: 0, limit: 100000000 }));
    dispatch(getCustomerPropertyByCustomerId(id));
  }, [dispatch]);

  useEffect(() => {
    values.project_id && dispatch(getPlan({ projectId: values.project_id }));
  }, [dispatch, values.project_id]);

  const legalDocsHandler = (e, index) => {
    setLegal_doc_err("");
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.legal_documents,
      previewState: values.legal_documents_preview,
      error: values.legal_documents_error,
      prop: "legal_documents",
      previewProp: "legal_documents_preview",
      errorProp: "legal_documents_error",
      type: "benefitsTypeHandler",
      allowPdf: true,
    };
    benefitsTypeHandler(tempData);
  };

  const myDocsHandler = (e, index) => {
    setLegal_doc_err("");
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.documents,
      previewState: values.documents_preview,
      error: values.documents_error,
      prop: "documents",
      previewProp: "documents_preview",
      errorProp: "documents_error",
      type: "benefitsTypeHandler",
      allowPdf: true,
    };
    benefitsTypeHandler(tempData);
  };

  const drawingsHandler = (e, index) => {
    setLegal_doc_err("");
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.drawings,
      previewState: values.drawings_preview,
      error: values.drawings_error,
      prop: "drawings",
      previewProp: "drawings_preview",
      errorProp: "drawings_error",
      type: "benefitsTypeHandler",
      allowPdf: true,
    };
    benefitsTypeHandler(tempData);
  };

  const docImageRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.legal_documents,
      previewState: values.legal_documents_preview,
      type: "benefitsTypeImageRemoveHandler doc",
      prop: "legal_documents",
      previewProp: "legal_documents_preview",
    };
    benefitsTypeHandler(tempData);
  };

  const myDocImageRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.documents,
      previewState: values.documents_preview,
      type: "benefitsTypeImageRemoveHandler doc",
      prop: "documents",
      previewProp: "documents_preview",
    };
    benefitsTypeHandler(tempData);
  };

  const drawingsImageRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.drawings,
      previewState: values.drawings_preview,
      type: "benefitsTypeImageRemoveHandler doc",
      prop: "drawings",
      previewProp: "drawings_preview",
    };
    benefitsTypeHandler(tempData);
  };

  const legalDocsHandleRemoveClick = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.legal_documents,
      previewState: values.legal_documents_preview,
      prop: "legal_documents",
      previewProp: "legal_documents_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };

  const myDocsHandleRemoveClick = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.documents,
      previewState: values.documents_preview,
      prop: "documents",
      previewProp: "documents_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };

  const drawingsHandleRemoveClick = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.drawings,
      previewState: values.drawings_preview,
      prop: "drawings",
      previewProp: "drawings_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };
  const legalDocsHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.legal_documents,
      prop: "legal_documents",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        doc: "",
      },
    };
    benefitsTypeHandler(tempData);
  };

  const myDocsHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.documents,
      prop: "documents",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        doc: "",
      },
    };
    benefitsTypeHandler(tempData);
  };

  const drawingsHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.drawings,
      prop: "drawings",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        doc: "",
      },
    };
    benefitsTypeHandler(tempData);
  };

  const projectIdOptions = projectList?.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const towerIdOptions = values.towers?.map((t) => {
    return { value: t.name, label: t.name };
  });

  const userOptions = userList?.map((item) => {
    return { value: item.id, label: item.first_name + " " + item.last_name };
  });

  const planIdOptions =
    planList &&
    planList?.map((item) => {
      return {
        value: item.display_name,
        label: item.display_name,
        id: item.id,
      };
    });

  const checkDocuments = () => {
    const arrays = [values.legal_documents, values.documents, values.drawings];

    for (const array of arrays) {
      for (const item of array) {
        if ((item.doc && !item.title) || (item.title && !item.doc)) {
          setLegal_doc_err("Document and title both are required!");
          return false;
        }
      }
    }
    return true;
  };

  const customerPropertyHandler = async (e) => {
    e.preventDefault();
    if (validate() && checkDocuments()) {
      const temp_legal_data = {
        type: "fileUpload doc",
        state: values.legal_documents.filter(
          (document) => document.title !== "" && document.doc !== ""
        ),
      };
      const tempLegalDoc = await benefitsTypeHandler(temp_legal_data);

      const temp_documents_data = {
        type: "fileUpload doc",
        state: values.documents.filter(
          (document) => document.title !== "" && document.doc !== ""
        ),
      };
      const tempDocuments = await benefitsTypeHandler(temp_documents_data);

      const temp_drawings_data = {
        type: "fileUpload doc",
        state: values.drawings.filter(
          (document) => document.title !== "" && document.doc !== ""
        ),
      };
      const tempDrawing = await benefitsTypeHandler(temp_drawings_data);

      const data = {
        // user_id: userForProperty
        //   ? values.user_data.id
        //   : values.user_data.cp_user_id,
        project_id: values.project_id.id,
        tower_name: values.tower.value,
        property_number: values.property,
        // plan_id: values.plan ? values.plan?.id : null,
        // access_user_id: userForProperty
        //   ? JSON.stringify([values.user_data.id])
        //   : values.access_user_id,

        legal_documents: tempLegalDoc,
        documents: tempDocuments,
        drawings: tempDrawing,
      };

      if (values.isPropUser) {
        data.user_id = userForProperty
          ? values.user_data.id
          : values.user_data.cp_user_id;
        data.access_user_id = userForProperty
          ? JSON.stringify([values.user_data.id])
          : values.access_user_id;
      } else {
        if (select_user) {
          data.user_id = select_user.value;
          data.access_user_id = JSON.stringify([select_user.value]);
        }
      }

      if (values.plan?.id) {
        data.plan_id = values.plan?.id;
      }

      console.log("data", data);

      if (propertyDetailByCustomerId && id) {
        dispatch(
          updateCustomerPropertyDetail({
            data,
            id,
            navigate,
            page_number,
            value,
          })
        );
      } else {
        dispatch(createCustomerProperty({ data, navigate }));
      }
    }
  };

  const handleUserChange = (e) => {
    setSelect_user(e);
  };

  // Get plans documents and Decode data
  useEffect(() => {
    planList &&
      planList?.length > 0 &&
      planList.forEach((item) => {
        if (values && values?.plan?.value === item.display_name) {
          const documents = item && item.documents;
          const decodeDocuments = JSON.parse(documents && documents);

          const drawings = item && item.drawings;
          const decodeDrawingsDoc = JSON.parse(drawings && drawings);

          const legal_documents = item && item.legal_documents;
          const decodeLegalDoc = JSON.parse(legal_documents && legal_documents);
          setValues({
            ...values,
            plan_documents: decodeDocuments,
            plan_drawings: decodeDrawingsDoc,
            plan_legal_documents: decodeLegalDoc,
          });
        }
      });
  }, [planList, values.plan]);

  return (
    <>
      <div className="customer_properties_number_extra_added_content col_span_2">
        {values.isPropUser ? (
          <Input
            className="create_from_input_content"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            label="User"
            disabled={true}
            value={`${
              values.user_data?.first_name ? values.user_data?.first_name : "-"
            } ${
              values.user_data?.last_name ? values.user_data?.last_name : ""
            }`}
          />
        ) : (
          <div className="create_from_input_content user_dropdown">
            <button
              className="create_from_input_content_btn"
              onClick={() => setAddUserModal(true)}
            >
              {addIcon}Add
            </button>
            <label className="create_from_label">Assign Property to..</label>

            <Select
              value={select_user}
              className="basic-multi-select"
              placeholder="select user to assign property.."
              classNamePrefix="select"
              name="select_user"
              options={userOptions}
              onChange={(e) => {
                handleUserChange(e);
              }}
              isClearable
            />
          </div>
        )}
        <div className="create_from_input_content">
          <label className="create_from_label">Project </label>
          <Select
            value={values.project_id}
            className="basic-multi-select"
            classNamePrefix="select"
            name="projectid"
            options={projectIdOptions}
            onChange={(e) => {
              handleInputChange(e, "project_id");
              first.current = true;
            }}
          />
          {!values.project_id && errors?.project_id ? (
            <span className="err_text">{errors?.project_id}</span>
          ) : null}
        </div>

        <div className="create_from_input_content tower_dropdown">
          <label className="create_from_label">Tower </label>
          <Select
            value={values.tower}
            className="basic-multi-select"
            classNamePrefix="select"
            name="tower"
            options={towerIdOptions}
            onChange={(e) => handleInputChange(e, "tower")}
          />
          {!values.tower && errors?.tower ? (
            <span className="err_text">{errors?.tower}</span>
          ) : null}
        </div>
        <Input
          className="create_from_input_content"
          labelClassName="create_from_label"
          inputClassName="create_from_input"
          errorClassName="err_text"
          type="text"
          placeholder="Property number"
          label="Property Number"
          id="property"
          name="property"
          value={values.property}
          onChange={handleInputChange}
          error={errors?.property || ""}
        />
        <div className="create_from_input_content plan_dropdown">
          <label className="create_from_label">Plan </label>
          <Select
            value={values.plan}
            className="basic-multi-select"
            classNamePrefix="select"
            name="plan"
            options={planIdOptions}
            onChange={(e) => handleInputChange(e, "plan")}
            required
          />
          {!values.plan && errors?.plan ? (
            <span className="err_text">{errors?.plan}</span>
          ) : null}
        </div>
        <div></div>

        {/* Plan legal documents */}
        {values &&
          values?.plan_legal_documents?.length > 0 &&
          values.plan_legal_documents.map((x, i) => {
            return (
              <div className="project_edit_detail_column_content">
                <div className="create_from_input_content">
                  <label className="create_from_label">
                    Plan Legal Documents
                  </label>
                </div>
                <div key={i} className="project_edit_detail_divider">
                  <div className="project_edit_feature_input_file">
                    <img
                      className="profile_edit_iframe m-0"
                      alt="plan"
                      src={process.env.REACT_APP_FILE_BASE_URL + "/" + x.doc}
                    />
                  </div>
                  <Input
                    className="project_edit_feature_input"
                    type="text"
                    placeholder="My Document Title"
                    label="Title"
                    id="title"
                    name="title"
                    value={x.title}
                    disabled
                  />
                </div>
              </div>
            );
          })}

        {/* Plan drawings document */}
        {values &&
          values?.plan_drawings?.length > 0 &&
          values.plan_drawings.map((x, i) => {
            return (
              <div className="project_edit_detail_column_content">
                <div className="create_from_input_content">
                  <label className="create_from_label"> Plan Drawings</label>
                </div>
                <div key={i} className="project_edit_detail_divider">
                  <div className="project_edit_feature_input_file">
                    <img
                      className="profile_edit_iframe m-0"
                      alt="plan"
                      src={process.env.REACT_APP_FILE_BASE_URL + "/" + x.doc}
                    />
                  </div>
                  <Input
                    className="project_edit_feature_input"
                    type="text"
                    placeholder="My Document Title"
                    label="Title"
                    id="title"
                    name="title"
                    value={x.title}
                    disabled
                  />
                </div>
              </div>
            );
          })}

        {/* Plan Document */}
        {values &&
          values?.plan_documents?.length > 0 &&
          values.plan_documents.map((x, i) => {
            return (
              <div className="project_edit_detail_column_content">
                <div className="create_from_input_content">
                  <label className="create_from_label"> Plan Documents</label>
                </div>
                <div key={i} className="project_edit_detail_divider">
                  <div className="project_edit_feature_input_file">
                    <img
                      className="profile_edit_iframe m-0"
                      alt="plan"
                      src={process.env.REACT_APP_FILE_BASE_URL + "/" + x.doc}
                    />
                  </div>
                  <Input
                    className="project_edit_feature_input"
                    type="text"
                    placeholder="My Document Title"
                    label="Title"
                    id="title"
                    name="title"
                    value={x.title}
                    disabled
                  />
                </div>
              </div>
            );
          })}

        <div className="project_edit_detail_column_content">
          <div className="create_from_input_content">
            <label className="create_from_label">Legal Documents</label>
          </div>
          {values.legal_documents &&
            values.legal_documents.map((x, i) => {
              return (
                <div key={i} className="project_edit_detail_divider">
                  <div className="project_edit_feature_input_file">
                    <Input
                      className="create_from_input_content"
                      labelClassName="create_from_label"
                      errorClassName="err_text"
                      type="file"
                      id={`legal_document${i}`}
                      labelOtherProps={
                        <span>
                          {!values.legal_documents[i].doc ? addIcon : editIcon}
                        </span>
                      }
                      name="doc"
                      accept="image/png, image/jpeg, application/pdf"
                      onChange={(e) => legalDocsHandler(e, i)}
                      onClick={(e) => (e.target.value = null)}
                      imageProps={
                        x.doc && (
                          <>
                            <iframe
                              title="ampp"
                              src={values.legal_documents_preview[i]}
                              className="profile_edit_iframe"
                            />
                            <Button
                              buttonClassName="image_remove_btn"
                              onClick={() => docImageRemoveHandler(i)}
                              other={closeIcon}
                            />
                          </>
                        )
                      }
                    />
                  </div>
                  {values.legal_documents_error[i] && (
                    <span className="err_text">
                      {values.legal_documents_error[i]}
                    </span>
                  )}
                  <Input
                    className="project_edit_feature_input"
                    type="text"
                    placeholder="Legal Document Title"
                    label="Title"
                    id="title"
                    name="title"
                    value={x.title}
                    onChange={(e) => legalDocsHandler(e, i)}
                  />
                  <div className="project_edit_feature_add_remove_btn_row">
                    {values.legal_documents.length !== 1 && (
                      <Button
                        buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                        onClick={() => legalDocsHandleRemoveClick(i)}
                        text="Remove"
                        other={removeIcon}
                      />
                    )}
                    {values.legal_documents.length - 1 === i && (
                      <Button
                        buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                        onClick={() => legalDocsHandleAddClick(i)}
                        text="Add"
                        other={addIcon}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="project_edit_detail_column_content">
          <div className="create_from_input_content">
            <label className="create_from_label">My Documents</label>
          </div>
          {values.documents &&
            values.documents.map((x, i) => {
              return (
                <div key={i} className="project_edit_detail_divider">
                  <div className="project_edit_feature_input_file">
                    <Input
                      className="create_from_input_content"
                      labelClassName="create_from_label"
                      errorClassName="err_text"
                      type="file"
                      id={`documents${i}`}
                      labelOtherProps={
                        <span>
                          {!values.documents[i].doc ? addIcon : editIcon}
                        </span>
                      }
                      name="doc"
                      accept="image/png,  image/jpeg, application/pdf"
                      onChange={(e) => myDocsHandler(e, i)}
                      onClick={(e) => (e.target.value = null)}
                      imageProps={
                        x.doc && (
                          <>
                            <iframe
                              title="ampp"
                              src={values.documents_preview[i]}
                              className="profile_edit_iframe"
                            />
                            <Button
                              buttonClassName="image_remove_btn"
                              onClick={() => myDocImageRemoveHandler(i)}
                              other={closeIcon}
                            />
                          </>
                        )
                      }
                    />
                  </div>

                  {values.documents_error[i] && (
                    <span className="err_text">
                      {values.documents_error[i]}
                    </span>
                  )}
                  <Input
                    className="project_edit_feature_input"
                    type="text"
                    placeholder="My Document Title"
                    label="Title"
                    id="title"
                    name="title"
                    value={x.title}
                    onChange={(e) => myDocsHandler(e, i)}
                  />
                  <div className="project_edit_feature_add_remove_btn_row">
                    {values.documents.length !== 1 && (
                      <Button
                        buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                        onClick={() => myDocsHandleRemoveClick(i)}
                        text="Remove"
                        other={removeIcon}
                      />
                    )}
                    {values.documents.length - 1 === i && (
                      <Button
                        buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                        onClick={() => myDocsHandleAddClick(i)}
                        text="Add"
                        other={addIcon}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>

        <div className="project_edit_detail_column_content">
          <div className="create_from_input_content">
            <label className="create_from_label">My Drawing</label>
          </div>
          {values.drawings &&
            values.drawings.map((x, i) => {
              return (
                <div key={i} className="project_edit_detail_divider">
                  <div className="project_edit_feature_input_file">
                    <Input
                      className="create_from_input_content"
                      labelClassName="create_from_label"
                      errorClassName="err_text"
                      type="file"
                      id={`drawing${i}`}
                      labelOtherProps={
                        <span>
                          {!values.drawings[i].doc ? addIcon : editIcon}
                        </span>
                      }
                      name="doc"
                      accept="image/png, image/jpeg, application/pdf"
                      onChange={(e) => drawingsHandler(e, i)}
                      onClick={(e) => (e.target.value = null)}
                      imageProps={
                        x.doc && (
                          <>
                            <iframe
                              title="ampp"
                              src={values.drawings_preview[i]}
                              className="profile_edit_iframe"
                            />
                            <Button
                              buttonClassName="image_remove_btn"
                              onClick={() => drawingsImageRemoveHandler(i)}
                              other={closeIcon}
                            />
                          </>
                        )
                      }
                    />
                  </div>
                  {values.drawings_error[i] && (
                    <span className="err_text">{values.drawings_error[i]}</span>
                  )}
                  <Input
                    className="project_edit_feature_input"
                    type="text"
                    placeholder="My Drawings Title"
                    label="Title"
                    id="title"
                    name="title"
                    value={x.title}
                    onChange={(e) => drawingsHandler(e, i)}
                  />
                  <div className="project_edit_feature_add_remove_btn_row">
                    {values.drawings.length !== 1 && (
                      <Button
                        buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                        onClick={() => drawingsHandleRemoveClick(i)}
                        text="Remove"
                        other={removeIcon}
                      />
                    )}
                    {values.drawings.length - 1 === i && (
                      <Button
                        buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                        onClick={() => drawingsHandleAddClick(i)}
                        text="Add"
                        other={addIcon}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <span className="err_text">{legal_doc_err}</span>

        <div className="create_from_bottom_btn col_span_2">
          {id ? (
            <Button
              className="create_from_bottom_btn col_span_2"
              buttonClassName="comman_btn"
              onClick={customerPropertyHandler}
              text="Update Property"
            />
          ) : (
            <Button
              className="create_from_bottom_btn col_span_2"
              buttonClassName="comman_btn"
              onClick={customerPropertyHandler}
              text="Create Property"
            />
          )}
        </div>
      </div>
      <CreateUserForProp
        addUserModal={addUserModal}
        setAddUserModal={setAddUserModal}
      />
    </>
  );
};

export default CreatePropertyForm;
