import React from "react";

const Page404 = () => {
  return (
    <div>
      <div style={{ color: "grey", textAlign: "center", marginTop: "4rem" }}>
        <h1>Page 404 not found</h1>
      </div>
    </div>
  );
};

export default Page404;
