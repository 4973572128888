import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

//createPrivilege
export const createPrivilege = createAsyncThunk(
  "createPrivilege",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/projects/privilege/add",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Project Create Successfully !", {
          autoClose: 2000,
        });
        data.navigate(`${routes.privileges}/page/1`);
      }
      return response.data;
    } catch (error) {
      console.log("createPrivilegeError", error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//getPrivilege
export const getPrivilege = createAsyncThunk(
  "getPrivilege",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/projects/privilege/get/list?start=${data.start || 0}&limit=${
          data.limit || 10
        }`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("getPrivilege", error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
//getPrivilegeById
export const getPrivilegeById = createAsyncThunk(
  "getPrivilegeById",
  async (id) => {
    try {
      const response = await Axios.get(
        `/projects/privilege/get/${id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("getPrivilegeById", error.response);
    }
  }
);
//updatePrivilege
export const updatePrivilege = createAsyncThunk(
  "updatePrivilege",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/projects/privilege/update/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && !data.cb) {
        toast.info("Privilege updated successfully !", {
          autoClose: 2000,
        });
        data.navigate(`${routes.privileges}/page/${data.page_number}`);
      }
      if (data.cb) {
        toast.error("Privilege deleted successfully !", {
          autoClose: 2000,
        });
        return data.cb(null, response.data);
      }

      return response.data;
    } catch (error) {
      console.log("updatePrivilege", error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
//clearPrivilege
export const clearPrivilege = createAsyncThunk(
  "clearPrivilege",
  async (data, thunkAPI) => {
    return;
  }
);
const privilegeSlice = createSlice({
  name: "privilege",
  initialState: {
    createPrivilege: null,
    privilegeList: [],
    getPrivilegeById: null,
    updatePrivilege: null,
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //createPrivilege
    builder.addCase(createPrivilege.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(createPrivilege.fulfilled, (state, action) => {
      state.createPrivilege = action.payload;
      state.loader = false;
    });
    builder.addCase(createPrivilege.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getPrivilege
    builder.addCase(getPrivilege.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getPrivilege.fulfilled, (state, action) => {
      state.privilegeList = action.payload;
      state.loader = false;
    });
    builder.addCase(getPrivilege.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getPrivilegeById
    builder.addCase(getPrivilegeById.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getPrivilegeById.fulfilled, (state, action) => {
      state.getPrivilegeById = action.payload;
      state.loader = false;
    });
    builder.addCase(getPrivilegeById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //updatePrivilege
    builder.addCase(updatePrivilege.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(updatePrivilege.fulfilled, (state, action) => {
      state.updatePrivilege = action.payload;
      state.loader = false;
    });
    builder.addCase(updatePrivilege.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //clearPrivilege
    builder.addCase(clearPrivilege.fulfilled, (state, action) => {
      state.createPrivilege = null;
      state.updatePrivilege = null;
      state.getPrivilegeById = null;
      state.loader = false;
    });
  },
});
export default privilegeSlice.reducer;
