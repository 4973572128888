import React from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../constants";
import { deleteIcon, editIcon, viewIcon } from "../../icons";

const ContactItem = ({ item, handleContactDelete, handleContactView }) => {
  const { page_number } = useParams();

  return (
    <div
      className={`customer_properties_column ${item.user_type}`}
      key={item.id}
    >
      <div className="customer_properties_user_type_name">
        <p className="customer_properties_user_name">{item.contact_name}</p>
      </div>
      <div className="customer_properties_other_details">
        <span className="customer_properties_other_details_label">
          Phone Number
        </span>
        <p className="customer_properties_other_details_text">
          {item.phone_number}
        </p>
      </div>

      <div className="customer_properties_other_details">
        <span className="customer_properties_other_details_label">
          Service Name
        </span>
        <p className="customer_properties_other_details_text">
          {item.service_name ? item.service_name : "-"}
        </p>
      </div>

      <div className="customer_properties_other_details">
        <span className="customer_properties_other_details_label">Address</span>
        <p className="customer_properties_other_details_text">
          {item.contact_address ? item.contact_address : "-"}
        </p>
      </div>

      <div className="project_list_card_bottom_btn_row project_edit_link">
        <Link
          to={`${routes.contacts}/edit/${item.id}/${page_number}`}
          className="project_list_card_view_edit_btn link_red"
        >
          {editIcon}
          Contact Edit
        </Link>
        <button
          className="project_list_card_view_edit_btn link_red privilege_extra_class"
          onClick={() => handleContactView(item)}
        >
          {viewIcon}
          View
        </button>
        <div className="custom_data_table_view_edit_btn_item_row flex-end">
          <button
            className="custom_data_table_view_edit_item_btn"
            onClick={() => handleContactDelete(item.id)}
          >
            {deleteIcon}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactItem;
