import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import ProjectItem from "../../component/Project/ProjectItem";
import { routes } from "../../constants";
import "../../css/LoginPage.css";
import "../../css/ProjectForm.css";
import { clearProject, getProjectList } from "../../store/slice/projectSlice";

const ProjectListPage = () => {
  const dispatch = useDispatch();

  const loader = useSelector((state) => state.project.loader);

  const projectList = useSelector((state) => state.project.projects);

  useEffect(() => {
    dispatch(clearProject());
    dispatch(getProjectList({ admin: "true" }));
  }, [dispatch]);

  function ProjectList() {
    const listItems =
      projectList &&
      projectList.map((item, i) => <ProjectItem key={i} item={item} />);

    return <div className="col_lg_4 d_grid gap_15">{listItems}</div>;
  }

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content">
        <div className="creat_edit_project_btn_row">
          <Link to={`${routes.createProject}`} className="comman_btn ml-auto">
            Create Project
          </Link>
        </div>
        <ProjectList numbers={projectList} />
      </div>
    </>
  );
};

export default ProjectListPage;
