import React from "react";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../constants";
import { viewIcon, editIcon } from "../../icons";
import Button from "../Common/Button";

const LeadItem = ({ item, i, showModal, showUserModal, values }) => {
  const { page_number } = useParams();
  return (
    <tr className="custom_data_table_row" key={i}>
      <td className="custom_data_table_item table_item">
        {item.first_name} {item.last_name}
      </td>
      <td className="custom_data_table_item table_item">{item.project_name}</td>
      <td
        className={`custom_data_table_item table_item ${
          item.status === "confirmed"
            ? "lead_confirmed"
            : item.status === "rejected"
            ? "lead_rejected"
            : item.status === "submitted"
            ? "lead_submitted"
            : item.status === "visited"
            ? "lead_visited"
            : item.status === "converted"
            ? "lead_converted"
            : ""
        }`}
      >
        <span className="lead_table-status">
          {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
        </span>
      </td>

      <td>
        <Button
          className="custom_data_table_item table_item"
          buttonClassName="comman_btn user_referred_btn"
          onClick={(e) => showUserModal(item.referred_by, item.user_type)}
          text={item.referred_first_name + " " + item.referred_last_name}
        />
      </td>
      {/* <td className="custom_data_table_item table_item">
        {item.referred_first_name} {item.referred_last_name}
      </td> */}
      <td className="custom_data_table_item table_item">{item.user_type}</td>
      <td className="custom_data_table_item table_item">{item.phone_number}</td>

      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
        <div className="custom_data_table_view_edit_btn_item_row">
          <Button
            buttonClassName="custom_data_table_view_edit_item_btn"
            onClick={(e) => showModal(item.id)}
            text={viewIcon}
          />
          {/* <Link
            to={`${routes.leads}/${item.id}`}
            style={{ textDecoration: "none" }}
            className="custom_data_table_view_edit_item_btn "
          >
            {viewIcon}
          </Link> */}
          <Link
            to={`${routes.leads}/edit/${item.id}/${page_number}`}
            state={{ values }}
            className="custom_data_table_view_edit_item_btn "
          >
            {editIcon}
          </Link>
        </div>
      </td>
    </tr>
  );
};

export default LeadItem;
