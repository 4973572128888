import React from "react";

const ResetConfirmationModal = ({
  projectName,
  towerName,
  handleResetModalClose,
  showResetModal,
  resetFormHandler,
}) => {
  return (
    <>
      <div
        className={`user_detail_modal_bg  ${
          showResetModal && "user_detail_modal_show"
        }`}
        onClick={handleResetModalClose}
      ></div>
      <section
        className={`user_detail_modal_main user_delete_btn ${
          showResetModal && "user_detail_modal_show"
        }`}
      >
        <h4 className="modal-title">Reset Bulk Properties Form</h4>
        <div className="modal-body">
          <p>
            Are you sure you want to reset this Form, this will erase all your
            filled data and reset the Form!
          </p>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleResetModalClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={resetFormHandler}
          >
            Reset
          </button>
        </div>
      </section>
    </>
  );
};

export default ResetConfirmationModal;
