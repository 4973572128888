import React from "react";
import { Link } from "react-router-dom";
import "../../css/component.css";
import { useLocation } from "react-router-dom";

import Logo from "../../images/sangini-logo.png";
import { routes } from "../../constants";
import {
  aboutIcon,
  complaintIcon,
  dashboardIcon,
  feedbackIcon,
  inquiryIcon,
  leadIcon,
  noticeIcon,
  planIcon,
  privilegeIcon,
  projectIcon,
  propertiesIcon,
  sidebarSmallIcon,
  userIcon,
  serviceIcon,
} from "../../icons";

const Sidebar = () => {
  const { pathname } = useLocation();
  return (
    <div className="sidebar_container">
      <Link
        to={routes.dashboard}
        className={`sidebar_logo ${
          pathname.match(/^.*dashboard.*$/) && "active"
        } `}
      >
        <img src={Logo} alt="sangini logo" className="full_sidebar_logo" />
        {sidebarSmallIcon}
      </Link>
      <ul className="sidebar_nav">
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*dashboard.*$/) && "active"
            } `}
            to={routes.dashboard}
          >
            {dashboardIcon}
            <span>Dashboard</span>
          </Link>
        </li>

        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*company.*$/) && "active"
            } `}
            to={routes.company}
          >
            {aboutIcon}
            <span>About</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*privileges.*$/) && "active"
            } `}
            to={`${routes.privileges}/page/1`}
          >
            {privilegeIcon}
            <span>Privileges</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*service.*$/) && "active"
            } `}
            to={`${routes.service}/page/1`}
          >
            {serviceIcon}
            <span>Service</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*contacts.*$/) && "active"
            } `}
            to={`${routes.contacts}/page/1`}
          >
            {userIcon}
            <span>Contacts</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            to={routes.projects}
            className={`sidebar_link ${
              pathname.match(/^.*projects.*$/) && "active"
            } `}
          >
            {projectIcon}
            <span>Projects</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            to={routes.featuredProjects}
            className={`sidebar_link ${
              pathname.match(/^.*featuredProjects.*$/) && "active"
            } `}
          >
            {projectIcon}
            <span>Featured Projects</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*reward-history.*$/) && "active"
            } `}
            to={`${routes.rewardHistory}/page/1`}
          >
            {feedbackIcon}
            <span>Rewards History</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*leads.*$/) && "active"
            } `}
            to={`${routes.leads}/page/1`}
          >
            {leadIcon}
            <span>Leads</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*plans.*$/) && "active"
            } `}
            to={`${routes.plans}/page/1`}
          >
            {planIcon}
            <span>Plans</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*users.*$/) && "active"
            } `}
            to={`${routes.users}/page/1`}
          >
            {userIcon}
            <span>Users</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*properties.*$/) && "active"
            } `}
            to={`${routes.customerProperty}/page/1`}
          >
            {propertiesIcon}
            <span>Customer Properties</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*inquiries.*$/) && "active"
            } `}
            to={`${routes.inquiries}/page/1`}
          >
            {inquiryIcon}
            <span>Inquiries</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*complaints.*$/) && "active"
            } `}
            to={`${routes.complaints}/page/1`}
          >
            {complaintIcon}
            <span>Complaints</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*feedback.*$/) && "active"
            } `}
            to={`${routes.feedBack}/page/1`}
          >
            {feedbackIcon}
            <span>Feedbacks</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*notifications.*$/) && "active"
            } `}
            to={`${routes.notifications}/page/1`}
          >
            {noticeIcon}
            <span>Notifications</span>
          </Link>
        </li>
        <li className="sidebar_item">
          <Link
            className={`sidebar_link ${
              pathname.match(/^.*mobileConfiguration.*$/) && "active"
            } `}
            to={`${routes.mobileConfiguration}`}
          >
            {privilegeIcon}
            <span>Mobile Configuration</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
