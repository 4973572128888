import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";
// /createCustomerProperty
export const createCustomerProperty = createAsyncThunk(
  "createCustomerProperty",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/add-property",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Property created successfully !", {
          autoClose: 3000,
        });
        data.navigate(`${routes.customerProperty}/page/1`);
        return response.data;
      }
    } catch (error) {
      if (error.response.status === 500) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
        console.log("createCustomerProperty", error.response.data.message);
      }
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
//getCustomerPropertyList
export const getCustomerPropertyList = createAsyncThunk(
  "getCustomerPropertyDetail",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get/customer-property?project_id=${
          data.project_id ? data.project_id : ""
        }&phone_number=${data.phone_number ? data.phone_number : ""}&start=${
          data.start || 0
        }&limit=${data.limit || 10}&app_status=${
          data.mobileStatus || ""
        }&tower_name=${data.tower_name || ""}&property=${data.property || ""}`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("getCustomerPropertyList", error.response);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//getCustomerPropertyList
export const getCustomerPropertyListForCSV = createAsyncThunk(
  "getCustomerPropertyListForCSV",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get/customer-property?project_id=${
          data?.project_id ? data?.project_id : ""
        }&phone_number=${data?.phone_number ? data?.phone_number : ""}&start=${
          data.start || 0
        }&limit=${data.limit || 10}&app_status=${data.mobileStatus || ""}`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("getCustomerPropertyList", error.response);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
//getCustomerPropertyById
export const getCustomerPropertyById = createAsyncThunk(
  "getCustomerPropertyById",
  // async (id, thunkAPI) => {
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        // `/users/get/customer-property/${id}`,
        `/users/get/customer-property/${data.id}?user_status=${
          data.user_status || ""
        }`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("getCustomerPropertyById", error.response);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
// /getCustomerPropertyByCustomerId
export const getCustomerPropertyByCustomerId = createAsyncThunk(
  "getCustomerPropertyByCustomerId",
  async (id, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get/customer-property-id/${id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("getCustomerPropertyByCustomerId", error.response);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
//updateCustomerPropertyDetail
export const updateCustomerPropertyDetail = createAsyncThunk(
  "updateCustomerPropertyDetail",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/users/customer-property/update/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && data.cb) {
        toast.success(data.toastMsg, {
          autoClose: 2000,
        });
        return data.cb(null, response.data);
      }
      if (response.status === 200 && !data.cb) {
        toast.success("Property updated successfully !", {
          autoClose: 3000,
        });
        data.navigate(`${routes.customerProperty}/page/${data.page_number}`, {
          state: { value: data.value },
        });
      }
      return response.data;
    } catch (error) {
      console.log(
        "updateCustomerPropertyError098789",
        error?.response?.data?.message
      );
      toast.error(error?.response?.data?.message, {
        autoClose: 3000,
      });
    }
  }
);
//clearCustomerProperty
export const clearCustomerProperty = createAsyncThunk(
  "clearCustomerProperty",
  () => {
    return;
  }
);
//csv file upload
export const csvFileUpload = createAsyncThunk(
  "csvFileUpload",
  async (data, thunkAPI) => {
    try {
      const uploadConfig = await Axios.post(
        `/users/csv/upload`,
        data,
        authHeaders()
      );
      return uploadConfig.data.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// /createBulkProperties
export const createBulkProperties = createAsyncThunk(
  "createBulkProperties",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/add-bulk-property",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Bulk Properties created successfully!", {
          autoClose: 3000,
        });
        data.navigate(`${routes.customerProperty}/page/1`);
        return response.data;
      }
    } catch (error) {
      if (error.response.status === 500) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
      }
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

//createSlice
const customerPropertySlice = createSlice({
  name: "customerProperty",
  initialState: {
    customerPropertyList: [],
    createCustomerProperty: null,
    createBulkProperties: null,
    getCustomerPropertyId: [],
    getCustomerPropertyCustomerId: null,
    updateProperty: null,
    csvFiles: [],
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //createCustomerProperty
    builder.addCase(createCustomerProperty.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(createCustomerProperty.fulfilled, (state, action) => {
      state.createCustomerProperty = action.payload;
      state.loader = false;
    });
    builder.addCase(createCustomerProperty.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getCustomerPropertyDetail
    builder.addCase(getCustomerPropertyList.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getCustomerPropertyList.fulfilled, (state, action) => {
      state.customerPropertyList = action.payload;
      state.loader = false;
    });
    builder.addCase(getCustomerPropertyList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getCustomerPropertyById
    builder.addCase(getCustomerPropertyById.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getCustomerPropertyById.fulfilled, (state, action) => {
      state.getCustomerPropertyId = action.payload;
      state.loader = false;
    });
    builder.addCase(getCustomerPropertyById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getCustomerPropertyByCustomerId
    builder.addCase(
      getCustomerPropertyByCustomerId.pending,
      (state, action) => {
        state.loader = true;
      }
    );
    builder.addCase(
      getCustomerPropertyByCustomerId.fulfilled,
      (state, action) => {
        state.getCustomerPropertyCustomerId = action.payload;
        state.loader = false;
      }
    );
    builder.addCase(
      getCustomerPropertyByCustomerId.rejected,
      (state, action) => {
        state.loader = false;
        state.error = action.payload;
      }
    );
    //updateCustomerPropertyDetail
    builder.addCase(updateCustomerPropertyDetail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateCustomerPropertyDetail.fulfilled, (state, action) => {
      state.updateProperty = action.payload;
      state.loader = false;
    });
    builder.addCase(updateCustomerPropertyDetail.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //csv file uploaded
    builder.addCase(csvFileUpload.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(csvFileUpload.fulfilled, (state, action) => {
      state.csvFiles = action.payload;
      state.loader = false;
    });
    builder.addCase(csvFileUpload.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //createBulkProperties
    builder.addCase(createBulkProperties.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(createBulkProperties.fulfilled, (state, action) => {
      state.createBulkProperties = action.payload;
      state.loader = false;
    });
    builder.addCase(createBulkProperties.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    //clearProject
    builder.addCase(clearCustomerProperty.fulfilled, (state) => {
      state.createCustomerProperty = null;
      state.updateProperty = null;
      state.getCustomerPropertyCustomerId = null;
    });
  },
});

export default customerPropertySlice.reducer;
