import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContact } from "../../store/slice/contactSlice";
import Loader from "../../component/Common/Loader";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import { useForm } from "../../hooks/useForm";
import { Link } from "react-router-dom";
import { updateCustomerPropertyDetail } from "../../store/slice/customerPropertySlice";
import { useRef } from "react";
import { searchIcon, viewIcon } from "../../icons";
import ImportContactModal from "./ImportContactModal";

const ImportContactEditProperty = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const ref = useRef(false);
  const { id } = params;

  const contactList = useSelector((state) => state.contact.contactList.data);
  const loader = useSelector((state) => state.contact.loader);

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState("");

  const projectDetails = location?.state?.propertyDetailByCustomerId;

  const mergedContacts = Array.from(
    new Set([
      ...(projectDetails?.property_contacts
        ? JSON.parse(projectDetails?.property_contacts)
        : []),
      ...(projectDetails?.contacts ? JSON.parse(projectDetails?.contacts) : []),
    ])
  );

  const initialFValues = {
    contactsArr: projectDetails?.contacts
      ? JSON.parse(projectDetails?.contacts)
      : [],
    contactsListCheck: mergedContacts,
    search: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    if (ref.current) {
      const filter = setTimeout(() => {
        dispatch(
          getContact({
            search: values.search,
          })
        );
      }, 1000);
      return () => clearTimeout(filter);
    }
  }, [values.search]);

  useEffect(() => {
    dispatch(getContact({}));
    ref.current = true;
    if (projectDetails) {
      // Merge and remove duplicates when projectDetails changes
      const mergedContacts = Array.from(
        new Set([
          ...(projectDetails?.property_contacts
            ? JSON.parse(projectDetails?.property_contacts)
            : []),
          ...(projectDetails?.contacts
            ? JSON.parse(projectDetails?.contacts)
            : []),
        ])
      );
      setValues({
        ...values,
        contactsListCheck: mergedContacts,
      });
    }
  }, [dispatch, projectDetails]);

  const onChangeImportContactsHandler = (id) => {
    let tempContactsHandler = [...values.contactsListCheck];
    if (tempContactsHandler.includes(id)) {
      let index = tempContactsHandler.findIndex((obj) => obj === id);
      if (index > -1) {
        tempContactsHandler.splice(index, 1);
      }
      setValues({ ...values, contactsListCheck: tempContactsHandler });
    } else {
      setValues({
        ...values,
        contactsListCheck: [...values.contactsListCheck, id],
      });
    }
  };

  const saveButtonHandler = (e) => {
    e.preventDefault();
    const data = {
      property_contacts: values.contactsListCheck,
    };
    dispatch(updateCustomerPropertyDetail({ data, id }));
  };

  const hideModal = () => {
    setShow(false);
    setModalData("");
  };

  const viewIconHandler = (item) => {
    setShow(true);
    setModalData(item);
  };

  return (
    <div className="project_edit_content assign_contacts_page">
      {loader && <Loader />}
      <div className="comman_btn_container project_edit_page_body center_back_btn contact_page_filter">
        <button className="comman_btn back_btn" onClick={() => navigate(-1)}>
          Back
        </button>
        <div className="user_data_search_select_box_row contact-filter">
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder="Search by Service"
              id="search"
              name="search"
              value={values.search}
              onChange={handleInputChange}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
        </div>
      </div>

      <div className="project_edit_main_content center_table_body">
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">Select</th>
                <th className="custom_data_table_heading">Service Name</th>
                <th className="custom_data_table_heading">Company Name</th>
                <th className="custom_data_table_heading">Contact Name</th>
                <th className="custom_data_table_heading">Contact Number</th>
                <th className="custom_data_table_heading">Address</th>
                <th className="custom_data_table_heading"></th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {contactList?.length === 0 || !contactList ? (
                <tr className="custom_data_table_row">
                  <td className="custom_data_table_item"></td>
                  <td className="custom_data_table_item"></td>
                  <td className="custom_data_table_item"></td>
                  <td
                    className="custom_data_table_item"
                    style={{ textAlign: "center" }}
                  >
                    No data Found
                  </td>
                </tr>
              ) : (
                <>
                  {contactList &&
                    contactList.map((item, i) => {
                      const isContactInArr = values.contactsArr.includes(
                        item.id
                      );
                      const isContactChecked =
                        values.contactsListCheck.includes(item.id);
                      return (
                        <tr className="custom_data_table_row" key={i}>
                          <td
                            className="custom_data_table_item"
                            style={{ textAlign: "center" }}
                          >
                            <Input
                              inputClassName="create_from_radio_checkbox contacts_check"
                              type="checkbox"
                              id="confirmed"
                              name="confirmed"
                              onChange={() =>
                                onChangeImportContactsHandler(item.id)
                              }
                              // checked={values.contactsListCheck.includes(item.id)}
                              checked={isContactChecked}
                              disabled={isContactInArr}
                            />
                          </td>
                          <td
                            className="custom_data_table_item"
                            style={{ textAlign: "center" }}
                          >
                            {item.service_name ? item.service_name : "-"}
                          </td>
                          <td
                            className="custom_data_table_item"
                            style={{ textAlign: "center" }}
                          >
                            {item.company_name ? item.company_name : "-"}
                          </td>
                          <td
                            className="custom_data_table_item"
                            style={{ textAlign: "center" }}
                          >
                            {item.contact_name ? item.contact_name : "-"}
                          </td>

                          <td
                            className="custom_data_table_item"
                            style={{ textAlign: "center" }}
                          >
                            {item.phone_number ? item.phone_number : "-"}
                          </td>
                          <td
                            className="custom_data_table_item "
                            style={{ textAlign: "center" }}
                          >
                            <p className="text-overflow-address">
                              {item.contact_address
                                ? item.contact_address
                                : "-"}
                            </p>
                          </td>
                          <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                            <div className="custom_data_table_view_edit_btn_item_row">
                              <Button
                                buttonClassName="custom_data_table_view_edit_item_btn"
                                onClick={(e) => viewIconHandler(item)}
                                text={viewIcon}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
        </div>
        {/* {
          <Pagination
            totalRecords={totalCounts}
            onPageChange={onPageChange}
            startValue={pageNum}
          />
        } */}
        <Button
          className="project_submit_bottom_btn"
          buttonClassName="comman_btn"
          onClick={saveButtonHandler}
          text="Save"
        />
      </div>
      <ImportContactModal
        handleClose={hideModal}
        show={show}
        modalData={modalData}
      >
        <p>Modal</p>
      </ImportContactModal>
    </div>
  );
};

export default ImportContactEditProperty;
