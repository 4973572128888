import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearInquiry, getInquiryDetail } from "../../store/slice/inquirySlice";
import Select from "react-select";
import Loader from "../../component/Common/Loader";
import { useForm } from "../../hooks/useForm";
import Input from "../../component/Common/Input";
import { searchIcon } from "../../icons";
import Button from "../../component/Common/Button";
import InquiryItem from "../../component/Inquiry/InquiryItem";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../constants";
import Pagination from "../../component/Pagination/Pagination";
import InquiryDetailModal from "./InquiryDetailModal";

const statusOptions = [
  { value: "responded", label: "Responded" },
  { value: "received", label: "Received" },
];

const InquiryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;
  const loader = useSelector((state) => state.inquiry.loader);

  const location = useLocation();
  const value = location?.state?.value;

  const initialFValues = {
    phone_number: value?.phone_number ? value?.phone_number : "",
    status: value?.status
      ? value?.status
      : {
          value: "received",
          label: "Received",
        },
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const [show, setShow] = useState(false);
  const [inquiryId, setInquiryId] = useState("");

  const showModal = (i) => {
    setShow(true);
    setInquiryId(i);
  };

  const hideModal = () => {
    setShow(false);
  };

  const mobileUserHandler = (e) => {
    page_number !== 1 && navigate(`${routes.inquiries}/page/1`);
    setValues({ ...values, phone_number: e.target.value });
    let page_num;
    if (page_number === 1) {
      page_num = 0;
    } else {
      page_num = (page_number - 1) * 2;
    }
    e.preventDefault();
    dispatch(
      getInquiryDetail({
        start: page_num,
        limit: 10,
        status: values.status.value,
        inquiry_type: "",
        phone_number: e.target.value,
      })
    );
  };

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.inquiries}/page/1`);
      dispatch(clearInquiry());
      dispatch(
        getInquiryDetail({
          status: values.status.value,
          inquiry_type: "",
          phone_number: values.phone_number,
        })
      );
    }
  }, [dispatch, values.status]);

  useEffect(() => {
    navigate(`${routes.inquiries}/page/${page_number}`);
    dispatch(clearInquiry());
    // if (+page_number === 1)
    dispatch(
      getInquiryDetail({
        start: (page_number - 1) * 10,
        status: values.status.value,
        inquiry_type: "",
        phone_number: values.phone_number,
      })
    );
    ref.current = true;
  }, [dispatch]);

  const inquiryDetail = useSelector(
    (state) => state.inquiry.inquiryDetail?.data
  );
  const totalCount = useSelector(
    (state) => state.inquiry.inquiryDetail?.totalCount
  );

  const onPageChange = (page_number) => {
    dispatch(
      getInquiryDetail({
        status: values.status.value,
        inquiry_type: "",
        start: page_number,
        phone_number: values.phone_number,
        limit: 10,
      })
    );
  };

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content">
        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="tel"
              placeholder="Search by Mobile-number"
              id="phone_number"
              name="phone_number"
              maxLength={10}
              value={values.phone_number}
              onChange={mobileUserHandler}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div className="user_data_search">
            <Select
              value={values.status}
              onChange={(e) => handleInputChange(e, "status")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="status"
              options={statusOptions}
              required
            />
          </div>
        </div>
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">Name</th>
                <th className="custom_data_table_heading">Email</th>
                <th className="custom_data_table_heading">User Type</th>
                <th className="custom_data_table_heading">Project Name</th>
                <th className="custom_data_table_heading">Phone Number</th>
                <th className="custom_data_table_heading">Status</th>
                <th className="custom_data_table_heading">Created At</th>
                <th className="custom_data_table_heading"></th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {inquiryDetail &&
                inquiryDetail.map((item, i) => {
                  return (
                    <InquiryItem
                      item={item}
                      i={i}
                      showModal={showModal}
                      values={values}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination totalRecords={totalCount} onPageChange={onPageChange} />
        <InquiryDetailModal
          show={show}
          handleClose={hideModal}
          inquiryId={inquiryId}
        >
          <p>Modal</p>
        </InquiryDetailModal>
      </div>
    </>
  );
};

export default InquiryPage;
