import React from "react";

export default function Button(props) {
  const { text, onClick, className, buttonClassName, other, type, isDisabled } =
    props;

  return (
    <div className={className}>
      <button
        onClick={onClick}
        className={buttonClassName}
        type={type}
        disabled={isDisabled}
      >
        {other && other}
        {text}
      </button>
    </div>
  );
}
