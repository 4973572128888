import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCompanyDetail } from "../../store/slice/companyDetailSlice";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import CompanyList from "../../component/Company/CompanyList";

const CompanyDetailPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyDetail());
  }, [dispatch]);

  const companyDetail = useSelector(
    (state) => state.company.getCompanyDetail || []
  );

  const loader = useSelector((state) => state.company.loader);

  let address;
  let follow_me;
  let facts;

  if (companyDetail?.length > 0) {
    address = JSON.parse(companyDetail[0].address);
    follow_me = JSON.parse(companyDetail[0].follow_me);
    facts = JSON.parse(companyDetail[0].facts);
  }

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content about_page_edit_create_btn">
        <div className="creat_edit_project_btn_row">
          {companyDetail.length > 0 ? (
            <Link to={routes.editCompany} className="comman_btn ml-auto">
              Edit details
            </Link>
          ) : (
            <Link to={routes.createCompany} className="comman_btn ml-auto">
              Create Detail
            </Link>
          )}
        </div>

        {companyDetail.length > 0 && (
          <CompanyList
            companyDetail={companyDetail}
            address={address}
            follow_me={follow_me}
            facts={facts}
          />
        )}
      </div>
    </>
  );
};

export default CompanyDetailPage;
