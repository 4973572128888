import moment from "moment";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ViewPrivilegeModal = ({ values, viewModalData, handleViewClose }) => {
  const [nav1, setNav1] = useState(null);
  const showHideClassName = values && "user_detail_modal_show";

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleViewClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="project_edit_main_content">
          <div className="about_page_section plan_page_section">
            <div className="about_page_slider">
              <Slider asNavFor={nav1} ref={(slider1) => setNav1(slider1)}>
                {viewModalData?.privilege_image && (
                  <div className="plan_slider_box">
                    <img
                      src={
                        process.env.REACT_APP_FILE_BASE_URL +
                        "/" +
                        viewModalData?.privilege_image
                      }
                      alt="plan item"
                      className="plan_slider_box_img"
                    />
                    <a
                      href={`${process.env.REACT_APP_FILE_BASE_URL}/${viewModalData?.privilege_image}`}
                      target="_blank"
                      className="plan_photo_view"
                      rel="noreferrer"
                    >
                      <i className="fa-solid fa-eye"></i>
                    </a>
                    <div className="plan_slider_box_text_content">
                      <h2 className="plan_slider_heading">Privilege Image</h2>
                    </div>
                  </div>
                )}
              </Slider>
            </div>
            <div className="about_detaile_row">
              <div className="about_detaile_text_content">
                <h2>Privilege Name </h2>
                <p>{viewModalData?.privilege_name}</p>
              </div>
              <div className="about_detaile_text_content">
                <h2>Privilege Type</h2>
                <p>{viewModalData?.privilege_type}</p>
              </div>
              <div className="about_detaile_text_content">
                <h2>Phone Number</h2>
                <p>{viewModalData?.privilege_phone_number}</p>
              </div>
              <div className="about_detaile_text_content">
                <h2>Created At </h2>
                <p>
                  {moment(viewModalData?.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </p>
              </div>
              {viewModalData?.updated_at && (
                <div className="about_detaile_text_content">
                  <h2>Updated At </h2>
                  <p>
                    {moment(viewModalData?.updated_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </p>
                </div>
              )}
            </div>
            <button
              type="button"
              onClick={handleViewClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
            >
              Close
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewPrivilegeModal;
