import React from "react";

const UnVerifyPropertyModal = ({
  openUnVerifyModal,
  unVerifyPropertyData,
  unVerifyPropertyHandler,
  closeUnVerifyModal,
  reject,
  setReject,
}) => {
  return (
    <>
      <div
        className={`user_detail_modal_bg  ${
          openUnVerifyModal && "user_detail_modal_show"
        }`}
        onClick={closeUnVerifyModal}
      ></div>
      <section
        className={`user_detail_modal_main verify_user_modal ${
          openUnVerifyModal && "user_detail_modal_show"
        }`}
      >
        <div className="verify_user_modal_content">
          <h4 className="modal-title">Are you sure?</h4>
          <div className="modal-body">
            <p>
              {reject
                ? "You want to Reject Following property for User?"
                : "You want to UnVerify Following property for User?"}

              {/* {`You want to UnVerify Following property for User?`} */}
            </p>
          </div>
          <div className="modal-body">
            <div className="detail_container_content">
              <div className="detail_container">
                <h2 className="detail_label">User Name</h2>
                <span className="detail_content">
                  {unVerifyPropertyData?.owner_first_name +
                    " " +
                    unVerifyPropertyData?.owner_last_name}
                </span>
              </div>
              <div className="detail_container">
                <h2 className="detail_label">Project Name</h2>
                <span className="detail_content">
                  {unVerifyPropertyData?.project_name}
                </span>
              </div>
              <div className="detail_container">
                <h2 className="detail_label">Tower Name</h2>
                <span className="detail_content">
                  {unVerifyPropertyData?.tower_name}
                </span>
              </div>
              <div className="detail_container">
                <h2 className="detail_label">Property Number</h2>
                <span className="detail_content">
                  {unVerifyPropertyData?.property_number}
                </span>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeUnVerifyModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => unVerifyPropertyHandler()}
            >
              {reject ? "Yes, reject" : "Yes, UnVerify"}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default UnVerifyPropertyModal;
