import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import Loader from "../../component/Common/Loader";
import { getLeadById } from "../../store/slice/leadSlice";
import CreateLeadPage from "./CreateLeadPage";

const EditLeadPage = () => {
  const [isLeadDetail, setIsLeadDetail] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const { values } = location?.state;

  const leadDetails = useSelector((state) => state.lead.leadId);
  const loader = useSelector((state) => state.lead.loader);

  useEffect(() => {
    if (leadDetails.length === 0 && id) {
      dispatch(getLeadById(id));
    } else {
      setIsLeadDetail(false);
    }
  }, [leadDetails, id, dispatch]);

  if (isLeadDetail) {
    return <div />;
  }

  return (
    <>
      {loader && <Loader />}
      <CreateLeadPage leadDetails={leadDetails} locationValues={values} />
    </>
  );
};

export default EditLeadPage;
