import moment from "moment";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImportContactModal = ({ handleClose, show, modalData }) => {
  const [nav1, setNav1] = useState(null);
  const showHideClassName = show && "user_detail_modal_show";

  console.log("modalData", modalData);

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <h2 className="assign_view_modal_heading">Contact Details</h2>
        <div className="inquiry_complaint_detail_section">
          <div className="inquiry_complaint_detail_content">
            <div className="about_page_section plan_page_section">
              <div className="about_page_slider">
                <Slider asNavFor={nav1} ref={(slider1) => setNav1(slider1)}>
                  {modalData?.image && (
                    <div className="plan_slider_box">
                      <img
                        src={
                          process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          modalData?.image
                        }
                        alt="plan item"
                        className="plan_slider_box_img"
                      />
                      <a
                        href={`${process.env.REACT_APP_FILE_BASE_URL}/${modalData?.image}`}
                        target="_blank"
                        className="plan_photo_view"
                        rel="noreferrer"
                      >
                        <i className="fa-solid fa-eye"></i>
                      </a>
                      <div className="plan_slider_box_text_content">
                        <h2 className="plan_slider_heading">Service Image</h2>
                      </div>
                    </div>
                  )}
                  {modalData?.contact_certificate && (
                    <div className="plan_slider_box">
                      <img
                        src={
                          process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          modalData?.contact_certificate
                        }
                        alt="plan item"
                        className="plan_slider_box_img"
                      />
                      <a
                        href={`${process.env.REACT_APP_FILE_BASE_URL}/${modalData?.contact_certificate}`}
                        target="_blank"
                        className="plan_photo_view"
                        rel="noreferrer"
                      >
                        <i className="fa-solid fa-eye"></i>
                      </a>
                      <div className="plan_slider_box_text_content">
                        <h2 className="plan_slider_heading">
                          Contact Certificate
                        </h2>
                      </div>
                    </div>
                  )}
                </Slider>
              </div>
            </div>
            <div className="inquiry_complaint_detail_body">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Service Name
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {modalData?.service_name}
                </p>
              </div>

              <div className="create_update_in_single_div">
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    Contact Name
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {modalData?.contact_name}
                  </p>
                </div>
                {modalData?.phone_number && (
                  <div className="inquiry_complaint_detail_text">
                    <h2 className="inquiry_complaint_detail_heading">
                      phone Number
                    </h2>
                    <p className="inquiry_complaint_detail_name">
                      {modalData?.phone_number}
                    </p>
                  </div>
                )}
              </div>

              {modalData?.contact_address && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading assign_view_modalbtn">
                    Address
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {modalData?.contact_address}
                  </p>
                </div>
              )}
              <div className="create_update_in_single_div">
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    Created At
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {moment(modalData?.created_at).format("DD/MM/YYYY, h:mm A")}
                  </p>
                </div>

                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    Updated At
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {modalData?.updated_at ? (
                      <>
                        {moment(modalData?.updated_at).format(
                          "DD/MM/YYYY, h:mm A"
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn comman_btn"
          >
            Close
          </button>
        </div>
      </section>
    </>
  );
};

export default ImportContactModal;
