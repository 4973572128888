import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";
//Action

//getUserList
export const getUserList = createAsyncThunk(
  "getUserList",
  async (data, thunkAPI) => {
    try {
      let url;
      url = `/users/get?phone_number=${data.mobileNum || ""}&user_type=${
        data.userType || ""
      }&user_status=${data.userStatus || ""}&project_id=${
        data.projectId || ""
      }&start=${data.start || 0}&limit=${data.limit || 10}&user_login_status=${
        data.mobileStatus || ""
      }&user_finder=${data.user_finder || "all"}`;
      const response = await Axios.get(url, authHeaders());
      if (data.callBack) {
        return data.cb(response.data);
      }

      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("getUserError", error.response);
      data.cb(null, error);
    }
  }
);

export const getUserListForCSV = createAsyncThunk(
  "getUserListForCSV",
  async (data, thunkAPI) => {
    try {
      let url;
      url = `/users/get?phone_number=${data.mobileNum || ""}&user_type=${
        data.userType || ""
      }&user_status=${data.userStatus || ""}&project_id=${
        data.projectId || ""
      }&start=${data.start || 0}&limit=${
        data.limit || 100000
      }&user_login_status=${data.mobileStatus || ""}`;
      const response = await Axios.get(url, authHeaders());
      if (data.callBack) {
        return data.cb(response.data);
      }

      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("getUserError", error.response);
      data.cb(null, error);
    }
  }
);
//getUserById
export const getUserById = createAsyncThunk("getUserById", async (data) => {
  try {
    const response = await Axios.get(
      `/users/get/user-detail/${data.id}?user_type=${data.user_type || ""}`,
      authHeaders()
    );
    return response.data;
  } catch (error) {
    console.log("err", error.response);
  }
});
//getUserRewardsHistoryById
export const getRewardsHistoryById = createAsyncThunk(
  "getRewardsHistoryById",
  async (id) => {
    try {
      const response = await Axios.get(
        `/users/get/rewards-history?user_id=${id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("err", error.response);
    }
  }
);
//get reward history
export const getRewardsHistory = createAsyncThunk(
  "getRewardsHistory",
  async (data) => {
    try {
      const response = await Axios.get(
        `/users/get/rewards-history?reward_status=${
          data.reward_status || ""
        }&phone_number=${data?.phoneNumber || ""}&start=${
          data.start || 0
        }&limit=${data.limit || 10}`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
      // return response.data;
    } catch (error) {
      console.log("err", error.response);
    }
  }
);
//propertyAssignUser
export const propertyAssignedUser = createAsyncThunk(
  "propertyAssignedUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/get/customer-property-assign-user-detail/",
        data.data,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("error", error.response);
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);
//sendOtp
export const sendOtp = createAsyncThunk("sendOtp", async (data, thunkAPI) => {
  try {
    const response = await Axios.post("/users/send-otp", data);
    return response.config.data;
  } catch (error) {
    console.log("error", error.response);
    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});
//verifyOtp
export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/users/verify-otp", data.data);
      if (response.data.user_type !== "ADMIN" && !data.noToast) {
        toast.error("You are not authorized !", {
          autoClose: 2000,
        });
        throw new Error("You are not authorized !");
      }
      if (response.status === 200) {
        toast.success("OTP Verified !", {
          autoClose: 2000,
        });
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
      toast.error(error, {
        autoClose: 2000,
      });
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);
//registerUser
export const registerUser = createAsyncThunk(
  "registerUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post("/users/register", data.data);
      if (response.status === 200) {
        toast.success("Create User Successfully !", {
          autoClose: 3000,
        });
        return data.cb(response.data);
      }
      return response.data;
    } catch (error) {
      console.log("ERROR123456789", error.response);
      return data.cb(null, error);
    }
  }
);
//verifyUser
export const verifyUser = createAsyncThunk(
  "verifyUser",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/users/verify-user",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("User Verified !", {
          autoClose: 2000,
        });
        data.navigate(`${routes.customerProperty}/page/1`);
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        toast.error(error.response.data.message, {
          autoClose: 3000,
        });
      }
      console.log("err", error.response);
    }
  }
);

//createSlice
const initialUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const initialState = {
  userById: null,
  rewardsById: null,
  rewardHistory: [],
  users: [],
  csvUsers: [],
  registerUser: null,
  sendOtp: null,
  otp: null,
  loader: false,
  error: "",
  user: initialUser,
  verifyUser: null,
  propertyAssignedUser: [],
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
    },
  },

  extraReducers: (builder) => {
    //sendOtp
    builder.addCase(sendOtp.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.loader = false;
      state.sendOtp = action.payload;
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // verifyOtp
    builder.addCase(verifyOtp.pending, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loader = false;
      state.user = action.payload;
      !action.payload.message &&
        localStorage.setItem("user", JSON.stringify(action.payload));
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getUserList
    builder.addCase(getUserList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.loader = false;
      state.users = action.payload;
    });
    builder.addCase(getUserList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getUserListForCSV
    builder.addCase(getUserListForCSV.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getUserListForCSV.fulfilled, (state, action) => {
      state.loader = false;
      state.csvUsers = action.payload;
    });
    builder.addCase(getUserListForCSV.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //propertyAssignedUser
    builder.addCase(propertyAssignedUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(propertyAssignedUser.fulfilled, (state, action) => {
      state.loader = false;
      state.propertyAssignedUser = action.payload;
    });
    builder.addCase(propertyAssignedUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getUserListById
    builder.addCase(getUserById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.loader = false;
      state.userById = action.payload;
    });
    builder.addCase(getUserById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getRewardsHistoryById
    builder.addCase(getRewardsHistoryById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getRewardsHistoryById.fulfilled, (state, action) => {
      state.loader = false;
      state.rewardsById = action.payload;
    });
    builder.addCase(getRewardsHistoryById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getRewardsHistory
    builder.addCase(getRewardsHistory.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getRewardsHistory.fulfilled, (state, action) => {
      state.loader = false;
      state.rewardHistory = action.payload;
    });
    builder.addCase(getRewardsHistory.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //registerUser
    builder.addCase(registerUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loader = false;
      state.registerUser = action.payload;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //verifyUser
    builder.addCase(verifyUser.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(verifyUser.fulfilled, (state, action) => {
      state.loader = false;
      state.verifyUser = action.payload;
    });
    builder.addCase(verifyUser.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});
export const { logout } = userSlice.actions;
export default userSlice.reducer;
