import React from "react";
import Button from "../../Common/Button";

const ProjectNavbar = ({ menu, setMenu }) => {
  return (
    <ul className="project_edit_navbar">
      <li
        className={`project_edit_items ${
          menu === "basicInfo" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Basic Info"
          onClick={() => setMenu("basicInfo")}
        />
      </li>
      {/* <li
        className={`project_edit_items ${
          menu === "keyFeatures" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text=" Key Features"
          onClick={() => setMenu("keyFeatures")}
        />
      </li> */}
      {/* <li
        className={`project_edit_items ${
          menu === "amenities" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Amenities"
          onClick={() => setMenu("amenities")}
        />
      </li>
      <li
        className={`project_edit_items ${
          menu === "specifications" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Specifications"
          onClick={() => setMenu("specifications")}
        />
      </li> */}
      <li
        className={`project_edit_items ${menu === "medias" ? "open" : "close"}`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Medias"
          onClick={() => setMenu("medias")}
        />
      </li>
      {/* <li
        className={`project_edit_items ${
          menu === "benefits" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Benefits"
          onClick={() => setMenu("benefits")}
        />
      </li> */}
      <li
        className={`project_edit_items ${
          menu === "privilege" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Privilege"
          onClick={() => setMenu("privilege")}
        />
      </li>
      <li
        className={`project_edit_items ${
          menu === "contact" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Important Contacts"
          onClick={() => setMenu("contact")}
        />
      </li>
      <li
        className={`project_edit_items ${
          menu === "towerNames" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Tower Names"
          onClick={() => setMenu("towerNames")}
        />
      </li>
      <li
        className={`project_edit_items ${
          menu === "addresses" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Addresses"
          onClick={() => setMenu("addresses")}
        />
      </li>
      <li
        className={`project_edit_items ${
          menu === "rewardsPoints" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Reward Points"
          onClick={() => setMenu("rewardsPoints")}
        />
      </li>
      <li
        className={`project_edit_items ${
          menu === "onsiteActuals" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Onsite Actuals"
          onClick={() => setMenu("onsiteActuals")}
        />
      </li>
      {/* <li
        className={`project_edit_items ${
          menu === "layoutAndPlan" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Layout And Plan"
          onClick={() => setMenu("layoutAndPlan")}
        />
      </li> */}

      <li
        className={`project_edit_items ${
          menu === "legalDocuments" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Documents"
          onClick={() => setMenu("legalDocuments")}
        />
      </li>

      <li
        className={`project_edit_items ${
          menu === "notificationContact" ? "open" : "close"
        }`}
      >
        <Button
          buttonClassName="project_edit_link"
          text="Notification Contact"
          onClick={() => setMenu("notificationContact")}
        />
      </li>
    </ul>
  );
};

export default ProjectNavbar;
