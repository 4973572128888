import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getLeadById } from "../../store/slice/leadSlice";
import "../../css/LoginPage.css";
import Loader from "../../component/Common/Loader";
import LeadDetail from "../../component/Lead/LeadDetail";
import moment from "moment";
import { getUserById } from "../../store/slice/userSlice";

const LeadReferModal = ({ hideUserModal, showUser, refUserId, userType }) => {
  const showHideClassName = showUser && "user_detail_modal_show";
  const dispatch = useDispatch();

  const loader = useSelector((state) => state.lead.loader);
  useEffect(() => {
    if (refUserId) {
      dispatch(getUserById({ id: refUserId, user_type: userType }));
    }
  }, [refUserId, dispatch]);

  const leadListId = useSelector((state) => state.lead.leadId);
  const userById = useSelector((state) => state.user.userById);

  return (
    <>
      {loader && <Loader />}
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={hideUserModal}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        {/* <div className="comman_btn_container center_back_btn">
        <Link to={routes.leads} className="comman_btn back_btn">
          Back
        </Link>
      </div> */}
        <div className="project_edit_main_content">
          <div className="about_page_section plan_page_section">
            <div className="about_detaile_text_content">
              <h2>Name </h2>
              <p>
                {userById && userById[0]?.first_name}
                {userById && userById[0]?.last_name}
              </p>
            </div>
            <div className="about_detaile_text_content">
              <h2>Mobile Number </h2>
              <p>{userById && userById[0]?.phone_number}</p>
            </div>
            <div className="about_detaile_text_content">
              <h2>Email </h2>
              <p>{userById && userById[0]?.email}</p>
            </div>
            <div className="about_detaile_text_content">
              <h2>User Type </h2>
              <p>{userById && userById[0]?.user_type}</p>
            </div>
            <div className="about_detaile_text_content">
              <h2>Created At </h2>
              <p>
                {moment(userById && userById[0]?.created_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </p>
            </div>

            {userById &&
              userById[0]?.user_type === "CUSTOMER" &&
              userById?.map((item, i) => {
                return (
                  <div className="user_property" key={i}>
                    <div className="about_detaile_text_content">
                      <h2>Project Name </h2>
                      <p>{item?.project_name}</p>
                    </div>
                    <div className="about_detaile_text_content">
                      <h2>Tower Name </h2>
                      <p>{item?.tower_name}</p>
                    </div>
                    <div className="about_detaile_text_content">
                      <h2>Property Number </h2>
                      <p>{item?.property_number}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <button
          type="button"
          onClick={hideUserModal}
          className="user_detail_modal_close_btn comman_btn"
        >
          Close
        </button>
      </section>
    </>
  );
};

export default LeadReferModal;
