import Select from "react-select";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlan } from "../../store/slice/planSlice";
import { getProjectList } from "../../store/slice/projectSlice";
import { verifyUser } from "../../store/slice/userSlice";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import Input from "../Common/Input";
import Button from "../Common/Button";

const VerifyCustomer = ({ getUserData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const first = useRef(false);

  const initialFValues = {
    project_id: null,
    tower: {
      value: getUserData && getUserData.tower_name,
      label: getUserData && getUserData.tower_name,
    },
    towers: [],
    property: getUserData
      ? getUserData.property_number
        ? getUserData.property_number
        : "-"
      : "",
    phone_number: getUserData ? getUserData?.phone_number : "",
    property_err: "",
    project_err: "",
    plan_err: "",
    tower_err: "",
    plan: null,
  };

  useEffect(() => {
    dispatch(getProjectList({ complaint: "true" }));
  }, [dispatch]);

  const projectList = useSelector((state) => state.project.projects);
  const planList = useSelector((state) => state.plan.planDetails?.data);

  useEffect(() => {
    const list = projectList.filter(
      (item) => item.id === getUserData?.project_id
    );

    setValues({
      ...values,
      project_id:
        list?.length > 0
          ? { value: list[0].name, label: list[0].name, id: list[0].id }
          : null,
    });
  }, [getUserData, projectList]);

  const { values, handleInputChange, setValues } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    if (projectList?.length > 0 && values.project_id?.id) {
      const towerList =
        projectList?.find((per) => per.id === values.project_id.id)?.tower_names
          .length &&
        JSON.parse(
          projectList?.find((per) => per.id === values.project_id.id)
            .tower_names
        )?.map((item) => ({
          value: item.name,
          label: item.name,
        }));
      if (first.current) {
        setValues({ ...values, tower: null, plan: null, towers: towerList });
      } else {
        setValues({ ...values, towers: towerList });
      }
    }
  }, [projectList, values.project_id]);

  useEffect(() => {
    values.project_id && dispatch(getPlan({ projectId: values.project_id }));
  }, [dispatch, values.project_id]);

  const projectIdOptions = projectList.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const planIdOptions = planList?.map((item) => {
    return { value: item.display_name, label: item.display_name, id: item.id };
  });

  const towerIdOptions = values.towers?.map((item) => {
    return { value: item.label, label: item.label };
  });

  const validate = () => {
    let isValid = false;
    if (!values.project_id) {
      setValues({ ...values, project_err: "Project is necessary" });
    } else if (!values.tower) {
      setValues({ ...values, tower_err: "Tower is necessary" });
    } else if (!values.property?.trim()) {
      setValues({ ...values, property_err: "Property number is necessary" });
    }
    // else if (!values.plan) {
    //   setValues({ ...values, plan_err: "Plan is necessary" });
    // }
    else {
      setValues({
        ...values,
        project_err: "",
        property_err: "",
        plan_err: "",
        tower_err: "",
      });
      isValid = true;
    }
    return isValid;
  };

  const verifyUserHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        user_id: getUserData.id,
        project_id: values.project_id.id,
        tower_name: values.tower?.value,
        property_number: values.property.trim(),
        plan_id: values.plan ? values.plan?.id : 0,
        access_user_id: getUserData.id && JSON.stringify([getUserData.id]),
        phone_number: values.phone_number,
      };
      dispatch(verifyUser({ data, navigate }));
    }
  };

  return (
    <div className="customer_properties_number_extra_added_content col_span_2">
      <Input
        className="create_from_input_content"
        labelClassName="create_from_label"
        inputClassName="create_from_input"
        type="text"
        placeholder="User Name"
        label="User"
        disabled={true}
        value={`${getUserData?.first_name} ${getUserData?.last_name}` || ""}
      />
      <Input
        className="create_from_input_content"
        labelClassName="create_from_label"
        inputClassName="create_from_input"
        type="text"
        placeholder="Phone Number"
        label="Phone Number"
        name="phone_number"
        id="phone_number"
        onChange={handleInputChange}
        value={`${values?.phone_number}` || ""}
      />
      <div className="create_from_input_content">
        <label className="create_from_label">Project </label>
        <Select
          value={values.project_id}
          className="basic-multi-select"
          classNamePrefix="select"
          name="project_id"
          options={projectIdOptions}
          onChange={(e) => {
            handleInputChange(e, "project_id");
            first.current = true;
          }}
        />
        {values.project_err && (
          <span className="err_text">{values.project_err}</span>
        )}
      </div>
      <div className="create_from_input_content tower_dropdown_1">
        <label className="create_from_label">Tower </label>
        <Select
          value={values.tower}
          className="basic-multi-select"
          classNamePrefix="select"
          name="tower"
          options={towerIdOptions}
          onChange={(e) => handleInputChange(e, "tower")}
        />
        {values.tower_err && (
          <span className="err_text">{values.tower_err}</span>
        )}
      </div>
      <div>
        <Input
          className="create_from_input_content"
          labelClassName="create_from_label"
          inputClassName="create_from_input"
          type="text"
          placeholder="Property number"
          label="Property Number"
          id="property"
          name="property"
          value={values.property}
          onChange={handleInputChange}
        />
        {values.property_err && (
          <span className="err_text">{values.property_err}</span>
        )}
      </div>
      <div className="create_from_input_content plan_dropdown_1">
        <label className="create_from_label">Plan </label>
        <Select
          value={values.plan}
          className="basic-multi-select"
          classNamePrefix="select"
          name="plan"
          options={planIdOptions}
          onChange={(e) => handleInputChange(e, "plan")}
        />
        {values.plan_err && <span className="err_text">{values.plan_err}</span>}
      </div>
      <Button
        className="create_from_bottom_btn col_span_2"
        buttonClassName="comman_btn"
        onClick={verifyUserHandler}
        text="Verify User"
      />
    </div>
  );
};

export default VerifyCustomer;
