import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "../../../hooks/useForm";
import { addIcon, removeIcon } from "../../../icons";
import { updateProjectList } from "../../../store/slice/projectSlice";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import { notificationContactValidation } from "./ValidationForm";

const NotificationContact = ({ projectDetails }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const [error, setError] = useState("");

  const initialFValues = {
    contact: projectDetails?.contacts_for_notification
      ? JSON.parse(projectDetails?.contacts_for_notification)
      : [{ contact_number: "" }],
    pending: false,
  };

  const contactHandler = (e, index) => {
    setError("");
    const { name, value } = e.target;
    const list = [...values.contact];
    list[index][name] = value;
    setValues({
      ...values,
      contact: list,
    });
  };

  const contactHandleRemoveClick = (index) => {
    setError("");
    const list = [...values.contact];
    list.splice(index, 1);
    setValues({
      ...values,
      contact: list,
    });
  };

  const contactHandleAddClick = () => {
    setValues({
      ...values,
      contact: [...values.contact, { contact_number: "" }],
    });
  };

  const { values, setValues } = useForm(
    initialFValues,
    true
    // validate
  );

  const saveDataHandler = async (e) => {
    e.preventDefault();
    let validate = notificationContactValidation(values.contact);
    if (validate) {
      setError("Number required!");
    } else {
      setError("");
      setValues({ ...values, pending: true });
      const data = {
        contacts_for_notification: values.contact,
      };
      dispatch(updateProjectList({ data, id }));
      setValues({ ...values, pending: false });
    }
  };

  return (
    <div className="project_edit_main_content amenities_body">
      <div className="create_from_row">
        <div className="project_edit_detail_column_content">
          <h2>Notification Contacts </h2>
          {values.contact &&
            values.contact.map((x, i) => {
              return (
                <div key={i} className="project_edit_detail_divider">
                  <Input
                    className="project_edit_feature_input"
                    type="text"
                    placeholder="Enter Contact number"
                    id="contact_number"
                    name="contact_number"
                    value={x.contact_number}
                    onChange={(e) => contactHandler(e, i)}
                  />

                  <div className="project_edit_feature_add_remove_btn_row">
                    {values.contact.length !== 1 && (
                      <Button
                        buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_remove_btn"
                        onClick={() => contactHandleRemoveClick(i)}
                        text="Remove"
                        other={removeIcon}
                      />
                    )}

                    {values.contact.length - 1 === i && (
                      <Button
                        buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                        onClick={() => contactHandleAddClick(i)}
                        text="Add"
                        other={addIcon}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          {error && <span className="err_text">{error}</span>}
        </div>
      </div>
      <Button
        className="project_submit_bottom_btn center_back_btn"
        buttonClassName="comman_btn"
        onClick={saveDataHandler}
        text="Save"
      />
    </div>
  );
};

export default NotificationContact;
