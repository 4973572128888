import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import InquiryDetail from "../../component/Inquiry/InquiryDetail";
import { routes } from "../../constants";
import { getInquiryDetailById } from "../../store/slice/inquirySlice";

const InquiryDetailsPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id, page_number } = params;

  const inquiriesDetails = useSelector(
    (state) => state.inquiry.inquiryDetailId
  );

  const loader = useSelector((state) => state.inquiry.loader);

  useEffect(() => {
    dispatch(getInquiryDetailById(id));
  }, [dispatch, id]);
  return (
    <>
      {loader && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.inquiries}/page/${page_number}`}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <InquiryDetail inquiriesDetails={inquiriesDetails} />.
    </>
  );
};

export default InquiryDetailsPage;
