import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../component/Common/Input";
import { getUserList, verifyOtp } from "../../store/slice/userSlice";

const SendOtpModal = ({ values, setValues, deleteOtpModalClose }) => {
  const { page_number } = useParams();
  const dispatch = useDispatch();
  const otpHandler = (e) => {
    setValues({ ...values, otp: e.target.value });
  };

  const verifyOtpHandler = () => {
    const data = {
      otp: values.otp,
      user_id: values.userId,
      del_req: "1",
      phone_number: values.phoneNumber,
    };
    dispatch(verifyOtp({ data, noToast: true })).then((res) => {
      setValues({ ...values, otp: "", sendOtpForDelete: false });
      dispatch(
        getUserList({
          start: (page_number - 1) * 10,
          userType: values.userType.value,
          mobileNum: values.mobileNum,
          userStatus: values.userStatus.value,
          mobileStatus: values.mobileStatus.value,
          limit: 10,
          projectId: values.project_id?.id,
        })
      );
    });
  };

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${
          values.sendOtpForDelete && "user_detail_modal_show"
        }`}
        onClick={deleteOtpModalClose}
      ></div>
      <section
        className={`user_detail_modal_main user_delete_btn ${
          values.sendOtpForDelete && "user_detail_modal_show"
        }`}
      >
        {/* <h4 className="modal-title">Are you sure?</h4> */}
        <form className="login_input_content">
          <Input
            className="login_input_row null"
            inputClassName="sendotp_for_delete"
            errorClassName="err_text"
            type="tel"
            placeholder="Enter OTP"
            label="OTP"
            id="otp"
            name="otp"
            value={values.otp}
            onChange={(e) => otpHandler(e)}
            // error={errors?.phoneNumber || ""}
            // maxLength={10}
            autoFocus={true}
          />
        </form>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={verifyOtpHandler}
          >
            Verify OTP
          </button>
          {/* <button type="button" className="btn btn-danger" onClick={sendOtpModal}>
            Delete
          </button> */}
        </div>
      </section>
    </>
  );
};

export default SendOtpModal;
