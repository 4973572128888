import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPrivilege } from "../../../store/slice/privilegeSlice";
import { updateProjectList } from "../../../store/slice/projectSlice";
import Loader from "../../Common/Loader";
import Button from "../../Common/Button";
import { useForm } from "../../../hooks/useForm";
import Input from "../../Common/Input";
import Pagination from "../../Pagination/PaginationWithoutUrl";

const PrivilegeForm = ({ projectDetails }) => {
  const privilegeList = useSelector(
    (state) => state.privilege.privilegeList.data
  );
  const totalCounts = useSelector(
    (state) => state.privilege.privilegeList.totalCount
  );

  const initialFValues = {
    privilegesListCheck: projectDetails?.privileges
      ? JSON.parse(projectDetails?.privileges)
      : [],
  };

  const { values, setValues } = useForm(initialFValues, true);

  const dispatch = useDispatch();

  const params = useParams();
  const { id } = params;

  const loader = useSelector((state) => state.project.loader);

  const onChangePrivilegesHandler = (id) => {
    let tempPrivilegesHandler = [...values.privilegesListCheck];
    if (tempPrivilegesHandler.includes(id)) {
      let index = tempPrivilegesHandler.findIndex((obj) => obj === id);
      if (index > -1) {
        tempPrivilegesHandler.splice(index, 1);
      }
      setValues({ ...values, privilegesListCheck: tempPrivilegesHandler });
    } else {
      setValues({
        ...values,
        privilegesListCheck: [...values.privilegesListCheck, id],
      });
    }
  };

  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    dispatch(getPrivilege({}));
  }, [dispatch]);

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(getPrivilege({ start: page }));
  };

  const saveButtonHandler = (e) => {
    e.preventDefault();
    const data = {
      privileges: values.privilegesListCheck,
    };
    dispatch(updateProjectList({ data, id }));
  };
  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content center_table_body">
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">Select</th>
                <th className="custom_data_table_heading">Privilege Type</th>
                <th className="custom_data_table_heading">Privilege Name</th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {privilegeList &&
                privilegeList.map((item, i) => {
                  return (
                    <tr className="custom_data_table_row" key={i}>
                      <td
                        className="custom_data_table_item"
                        style={{ textAlign: "center" }}
                      >
                        <Input
                          inputClassName="create_from_radio_checkbox privileges_check"
                          // labelClassName="create_from_radio_label"
                          type="checkbox"
                          id="confirmed"
                          name="confirmed"
                          onChange={() => onChangePrivilegesHandler(item.id)}
                          checked={values.privilegesListCheck.includes(item.id)}
                        />
                      </td>
                      <td
                        className="custom_data_table_item"
                        style={{ textAlign: "center" }}
                      >
                        {item.privilege_type}
                      </td>
                      <td
                        className="custom_data_table_item"
                        style={{ textAlign: "center" }}
                      >
                        {item.privilege_name}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {
          <Pagination
            totalRecords={totalCounts}
            onPageChange={onPageChange}
            startValue={pageNum}
          />
        }
        <Button
          className="project_submit_bottom_btn"
          buttonClassName="comman_btn"
          onClick={saveButtonHandler}
          text="Save"
        />
      </div>
    </>
  );
};

export default PrivilegeForm;
