import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateProjectList } from "../../../store/slice/projectSlice";
import { useForm } from "../../../hooks/useForm";
import Loader from "../../Common/Loader";
import Input from "../../Common/Input";
import Button from "../../Common/Button";

const RewardPointsForm = ({ projectDetails }) => {
  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;
  const loader = useSelector((state) => state.project.loader);

  const initialFValues = {
    lead_confirmation: projectDetails?.rewards_points
      ? JSON.parse(projectDetails?.rewards_points)?.lead_confirmation
      : "",
    site_visit: projectDetails?.rewards_points
      ? JSON.parse(projectDetails?.rewards_points)?.site_visit
      : "",
    lead_conversion: projectDetails?.rewards_points
      ? JSON.parse(projectDetails?.rewards_points)?.lead_conversion
      : "",
    lead_confirmation_broker: projectDetails?.rewards_points_broker
      ? JSON.parse(projectDetails?.rewards_points_broker)?.lead_confirmation
      : "",
    site_visit_broker: projectDetails?.rewards_points_broker
      ? JSON.parse(projectDetails?.rewards_points_broker)?.site_visit
      : "",
    lead_conversion_broker: projectDetails?.rewards_points_broker
      ? JSON.parse(projectDetails?.rewards_points_broker)?.lead_conversion
      : "",
  };

  const validate = (fieldValues = values) => {
    var temp = { ...errors };

    if ("lead_confirmation" in fieldValues) {
      temp.lead_confirmation = fieldValues.lead_confirmation
        ? ""
        : "Lead confirmation is required !";
    }
    if ("site_visit" in fieldValues) {
      temp.site_visit = fieldValues.site_visit
        ? ""
        : "Site visit is required !";
    }
    if ("lead_conversion" in fieldValues) {
      temp.lead_conversion = fieldValues.lead_conversion
        ? ""
        : "Lead conversion is required !";
    }
    if ("lead_confirmation_broker" in fieldValues) {
      temp.lead_confirmation_broker = fieldValues.lead_confirmation_broker
        ? ""
        : "Lead confirmation is required !";
    }
    if ("site_visit_broker" in fieldValues) {
      temp.site_visit_broker = fieldValues.site_visit_broker
        ? ""
        : "Site visit is required !";
    }
    if ("lead_conversion_broker" in fieldValues) {
      temp.lead_conversion_broker = fieldValues.lead_conversion_broker
        ? ""
        : "Lead conversion is required !";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const saveDataHandler = (e) => {
    e.preventDefault();

    if (validate()) {
      const data = {
        rewards_points: {
          lead_confirmation: values.lead_confirmation,
          site_visit: values.site_visit,
          lead_conversion: values.lead_conversion,
        },
        rewards_points_broker: {
          lead_confirmation: values.lead_confirmation_broker,
          site_visit: values.site_visit_broker,
          lead_conversion: values.lead_conversion_broker,
        },
      };
      dispatch(updateProjectList({ data, id }));
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content center_table_body">
        <div className="project_edit_detail_column_content">
          <h2>Rewards Points</h2>
          <h3>For Customer</h3>
          <Input
            className="project_edit_feature_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Lead Confirmation"
            label="Lead Confirmation "
            id="lead_confirmation"
            name="lead_confirmation"
            value={values.lead_confirmation}
            onChange={handleInputChange}
            error={errors?.lead_confirmation || ""}
          />
          <Input
            className="project_edit_feature_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Site Visit"
            label="Site Visit "
            id="site_visit"
            name="site_visit"
            value={values.site_visit}
            onChange={handleInputChange}
            error={errors?.site_visit || ""}
          />
          <Input
            className="project_edit_feature_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Lead Conversion"
            label="Lead Conversion "
            id="lead_conversion"
            name="lead_conversion"
            value={values.lead_conversion}
            onChange={handleInputChange}
            error={errors?.lead_conversion || ""}
          />
          <h3 style={{ marginTop: "1.5rem" }}>For Broker</h3>
          <Input
            className="project_edit_feature_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Lead Confirmation"
            label="Lead Confirmation "
            id="lead_confirmation_broker"
            name="lead_confirmation_broker"
            value={values.lead_confirmation_broker}
            onChange={handleInputChange}
            error={errors?.lead_confirmation_broker || ""}
          />
          <Input
            className="project_edit_feature_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Site Visit"
            label="Site Visit "
            id="site_visit_broker"
            name="site_visit_broker"
            value={values.site_visit_broker}
            onChange={handleInputChange}
            error={errors?.site_visit_broker || ""}
          />
          <Input
            className="project_edit_feature_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Lead Conversion"
            label="Lead Conversion "
            id="lead_conversion_broker"
            name="lead_conversion_broker"
            value={values.lead_conversion_broker}
            onChange={handleInputChange}
            error={errors?.lead_conversion_broker || ""}
          />
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={saveDataHandler}
          text="Save"
        />
      </div>
    </>
  );
};

export default RewardPointsForm;
