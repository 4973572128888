import moment from "moment";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ViewServiceModal = ({ show, handleClose, viewModalData }) => {
  const [nav1, setNav1] = useState(null);

  const showHideClassName = show && "user_detail_modal_show";

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        {/* <div className="inquiry_complaint_detail_section">
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_video_img">
              <p className="inquiry_complaint_detail_status">Service Image</p>
              {viewModalData?.image ? (
                <iframe
                  title="service"
                  src={`${process.env.REACT_APP_FILE_BASE_URL}/${viewModalData?.image}`}
                  alt="Service Img"
                  className="plan_slider_box_img"
                />
              ) : (
                <img
                  src="https://www.medstartr.com/main/images/no-image.png"
                  alt="default pic"
                  className="default_image"
                />
              )}
            </div>
            <div className="inquiry_complaint_detail_body">
              <div className="inquiry_complaint_detail_create_updated_date">
                <div className="inquiry_complaint_detail_create_date">
                  <h2>Created At </h2>
                  <p>
                    {moment(viewModalData?.created_at).format(
                      "DD/MM/YYYY, h:mm A"
                    )}
                  </p>
                </div>
                {viewModalData?.updated_at && (
                  <div className="inquiry_complaint_detail_updated_date">
                    <h2>Updated At </h2>
                    <p>
                      {moment(viewModalData?.updated_at).format(
                        "DD/MM/YYYY, h:mm A"
                      )}
                    </p>
                  </div>
                )}
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Service Name
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {viewModalData?.service_name}
                </p>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            Close
          </button>
        </div> */}
        <div className="project_edit_main_content">
          <div className="about_page_section plan_page_section">
            <div className="about_page_slider">
              <Slider asNavFor={nav1} ref={(slider1) => setNav1(slider1)}>
                {viewModalData?.image && (
                  <div className="plan_slider_box">
                    <img
                      src={
                        process.env.REACT_APP_FILE_BASE_URL +
                        "/" +
                        viewModalData?.image
                      }
                      alt="plan item"
                      className="plan_slider_box_img"
                    />
                    <a
                      href={`${process.env.REACT_APP_FILE_BASE_URL}/${viewModalData?.image}`}
                      target="_blank"
                      className="plan_photo_view"
                      rel="noreferrer"
                    >
                      <i className="fa-solid fa-eye"></i>
                    </a>
                    <div className="plan_slider_box_text_content">
                      <h2 className="plan_slider_heading">Service Image</h2>
                    </div>
                  </div>
                )}
                {viewModalData?.certificate && (
                  <div className="plan_slider_box">
                    <img
                      src={
                        process.env.REACT_APP_FILE_BASE_URL +
                        "/" +
                        viewModalData?.certificate
                      }
                      alt="plan item"
                      className="plan_slider_box_img"
                    />
                    <a
                      href={`${process.env.REACT_APP_FILE_BASE_URL}/${viewModalData?.certificate}`}
                      target="_blank"
                      className="plan_photo_view"
                      rel="noreferrer"
                    >
                      <i className="fa-solid fa-eye"></i>
                    </a>
                    <div className="plan_slider_box_text_content">
                      <h2 className="plan_slider_heading">Certificate</h2>
                    </div>
                  </div>
                )}
                {/* {planData?.documents &&
                  JSON.parse(planData?.documents).map((item, i) => {
                    return (
                      <div key={i} className="plan_slider_box">
                        {item?.doc ? (
                          <>
                            {item.doc.split(".pdf").length > 1 ? (
                              <iframe
                                src={
                                  process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  item.doc
                                }
                                alt="plan item"
                                className="plan_slider_box_img plan_slider_pdf"
                              />
                            ) : (
                              <img
                                src={
                                  process.env.REACT_APP_FILE_BASE_URL +
                                  "/" +
                                  item.doc
                                }
                                alt="plan item"
                                className="plan_slider_box_img"
                              />
                            )}
                            <a
                              href={`${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`}
                              target="_blank"
                              className="plan_photo_view"
                              rel="noreferrer"
                            >
                              <i className="fa-solid fa-eye"></i>
                            </a>
                          </>
                        ) : null}
                        <div className="plan_slider_box_text_content">
                          <h2 className="plan_slider_heading">Contact Service</h2>
                          {/* {item.title && ( */}
                {/* <p className="plan_slider_peragraph"> */}
                {/* {item.title}Image */}
                {/* </p> */}
                {/* )} */}
                {/* </div>
                      </div>
                    );
                  })} */}
                {/* {planData?.drawings &&
                  JSON.parse(planData?.drawings).map((item, i) => {
                    return (
                      <div key={i} className="plan_slider_box">
                        {item.doc.split(".pdf").length > 1 ? (
                          <iframe
                            src={
                              process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              item.doc
                            }
                            alt="plan item"
                            className="plan_slider_box_img plan_slider_pdf"
                          />
                        ) : (
                          <img
                            src={
                              process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              item.doc
                            }
                            alt="plan item"
                            className="plan_slider_box_img"
                          />
                        )}
                        <a
                          href={`${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`}
                          target="_blank"
                          className="plan_photo_view"
                          rel="noreferrer"
                        >
                          <i className="fa-solid fa-eye"></i>
                        </a>
                        <div className="plan_slider_box_text_content">
                          <h2 className="plan_slider_heading">Drawings</h2>
                          {item.title && (
                            <p className="plan_slider_peragraph">
                              {item.title}
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  })} */}
                {/* {planData?.legal_documents &&
                  JSON.parse(planData?.legal_documents).map((item, i) => {
                    return (
                      <div key={i} className="plan_slider_box">
                        {item.doc.split(".pdf").length > 1 ? (
                          <iframe
                            src={
                              process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              item.doc
                            }
                            alt="plan item"
                            className="plan_slider_box_img plan_slider_pdf"
                          />
                        ) : (
                          <img
                            src={
                              process.env.REACT_APP_FILE_BASE_URL +
                              "/" +
                              item.doc
                            }
                            alt="plan item"
                            className="plan_slider_box_img"
                          />
                        )}
                        <a
                          href={`${process.env.REACT_APP_FILE_BASE_URL}/${item.doc}`}
                          target="_blank"
                          className="plan_photo_view"
                          rel="noreferrer"
                        >
                          <i className="fa-solid fa-eye"></i>
                        </a>
                        <div className="plan_slider_box_text_content">
                          <h2 className="plan_slider_heading">
                            Legal Documents
                          </h2>
                          {item.title && (
                            <p className="plan_slider_peragraph">
                              {item.title}
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  })} */}
              </Slider>
            </div>
            <div className="about_detaile_row">
              <div className="about_detaile_text_content">
                <h2>Service Name </h2>
                <p>{viewModalData?.service_name}</p>
              </div>
              {/* <div className="about_detaile_text_content">
                <h2>Plan Name</h2>
                <p>{planData?.display_name}</p>
              </div> */}

              <div className="about_detaile_text_content">
                <h2>Created At </h2>
                <p>
                  {moment(viewModalData?.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </p>
              </div>
              {viewModalData?.updated_at && (
                <div className="about_detaile_text_content">
                  <h2>Updated At </h2>
                  <p>
                    {moment(viewModalData?.updated_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </p>
                </div>
              )}
            </div>
            <button
              type="button"
              onClick={handleClose}
              className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
            >
              Close
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewServiceModal;
