import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import ContactItem from "../../component/Contact/ContactItem";
import Pagination from "../../component/Pagination/Pagination";
import { routes } from "../../constants";
import {
  clearContact,
  getContact,
  updateContact,
} from "../../store/slice/contactSlice";
import DeleteModal from "../Privilege/DeleteModal";
import { useForm } from "../../hooks/useForm";
import Select from "react-select";
import { getServiceList } from "../../store/slice/serviceSlice";
import ContactViewModal from "./ContactViewModal";

const ContactListPage = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;

  const [viewData, setViewData] = useState("");

  const loader = useSelector((state) => state.contact.loader);

  const initialFValues = {
    showDeleteModal: false,
    showViewModal: false,
    itemId: 0,
    id: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const handleContactDelete = (id) => {
    setValues({
      module: "Contact",
      showDeleteModal: true,
      itemId: id,
    });
  };

  const handleContactView = (item) => {
    setViewData(item);
    setValues({
      showViewModal: true,
    });
  };

  useEffect(() => {
    if (ref.current) {
      navigate(`/contacts/page/1`);
      dispatch(
        getContact({
          id: values.id?.id,
        })
      );
    }
  }, [values]);

  useEffect(() => {
    dispatch(clearContact());
    if (page_number === 1)
      dispatch(
        getContact({
          id: values.id?.id,
        })
      );
    dispatch(getServiceList({ start: 0, limit: 10000 }));
    ref.current = true;
  }, [dispatch]);

  const serviceList = useSelector((state) => state?.service?.serviceList?.data);

  const serviceDropdown =
    serviceList?.map((item) => ({
      label: item.service_name,
      value: item.id,
      id: item.id,
    })) || [];

  const serviceOptions = [
    { value: "", label: "All Service", id: "" },
    ...serviceDropdown,
  ];

  const contactList = useSelector((state) => state.contact.contactList?.data);

  const totalCount = useSelector(
    (state) => state.contact.contactList?.totalCount
  );

  // console.log("contactList", contactList);

  const listItems =
    contactList &&
    contactList.map((item) => (
      <ContactItem
        key={item.id}
        item={item}
        handleContactDelete={handleContactDelete}
        handleContactView={handleContactView}
      />
    ));

  const onPageChange = (page_number) => {
    dispatch(
      getContact({
        start: page_number,
        limit: 10,
        id: values.id?.id,
      })
    );
  };

  const handleDeleteClose = () => {
    setValues({ showDeleteModal: false });
  };

  const handleViewClose = () => {
    setValues({ showViewModal: false });
  };

  const deleteContactHandler = (id) => {
    setValues({ showDeleteModal: false });
    const data = {
      delete_status: "1",
    };

    dispatch(
      updateContact({
        data,
        id,
        page_number,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getContact({ start: (page_number - 1) * 10 }));
          }
        },
      })
    );
  };

  // const listItems = [];

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content">
        <div className="creat_edit_project_btn_row">
          <Link to={routes.createContact} className="comman_btn ml-auto">
            Create Contact
          </Link>
        </div>
        <div className="user_data_search_select_box_row">
          <div className="user_data_select lead_status-options">
            <Select
              placeholder="Service..."
              value={values.id}
              onChange={(e) => handleInputChange(e, "id")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="id"
              id="id"
              options={serviceOptions}
            />
          </div>
        </div>

        {listItems && listItems?.length === 0 ? (
          <div className="no_data_found_container">
            <h2 className="user_detail-nodata">No Data Found !</h2>
          </div>
        ) : (
          <>
            <div className="col_lg_4 d_grid gap_15 top-margin">{listItems}</div>
            <Pagination totalRecords={totalCount} onPageChange={onPageChange} />
          </>
        )}

        <ContactViewModal
          values={values.showViewModal}
          viewData={viewData}
          handleViewClose={handleViewClose}
        />
        <DeleteModal
          values={values}
          handleDeleteClose={handleDeleteClose}
          handleDeleteConfirm={deleteContactHandler}
        />
      </div>
    </>
  );
};

export default ContactListPage;
