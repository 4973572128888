import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRewardsHistoryById } from "../../store/slice/userSlice";
import moment from "moment";

const RewardsHistory = ({ userId }) => {
  const dispatch = useDispatch();

  const userRewards = useSelector((state) => state.user.rewardsById);
  useEffect(() => {
    dispatch(getRewardsHistoryById(userId));
  }, []);

  if (userRewards?.length === 0) {
    return <h2 className="user_detail-nodata">No Data Found !</h2>;
  }

  return (
    <div className="project_edit_main_content">
      <div className="custom_data_table_content">
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              <th className="custom_data_table_heading">Name</th>
              <th className="custom_data_table_heading">Project Name</th>
              <th className="custom_data_table_heading">Reward Points</th>
              <th className="custom_data_table_heading">Lead Status</th>
              <th className="custom_data_table_heading">Reward Status</th>
              <th className="custom_data_table_heading">Date</th>
              <th className="custom_data_table_heading">Order Id</th>
              <th className="custom_data_table_heading">Coupon Name</th>
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {userRewards &&
              userRewards.map((item, i) => {
                return (
                  <tr className="custom_data_table_row" key={i}>
                    <td className="custom_data_table_item table_item">
                      {item?.first_name} {item?.last_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.project_name}
                    </td>
                    <td
                      className={`custom_data_table_item table_item ${
                        item?.reward_status === "credited"
                          ? "credited_points"
                          : "debited_points"
                      }`}
                    >
                      {item?.reward_points}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.lead_status && (item?.lead_status).toUpperCase()}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.reward_status &&
                        (item?.reward_status).toUpperCase()}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {moment(item?.created_at).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.reward_detail &&
                        JSON.parse(item?.reward_detail).order_id}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.reward_detail &&
                        JSON.parse(item?.reward_detail).coupon_name}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RewardsHistory;
