import moment from "moment";
import React from "react";

const InquiryDetail = ({ inquiriesDetails }) => {
  return (
    <div className="inquiry_complaint_detail_section">
      <div className="inquiry_complaint_detail_content">
        <div className="inquiry_complaint_detail_video_img">
          <p className="inquiry_complaint_detail_status">
            {inquiriesDetails?.status}
          </p>
          {inquiriesDetails?.attachment ? (
            <iframe
              title="inquiry"
              src={inquiriesDetails?.attachment}
              alt="inquiry item"
              className="plan_slider_box_img"
            />
          ) : (
            <img
              src="https://www.medstartr.com/main/images/no-image.png"
              alt="default pic"
              className="default_image"
            />
          )}
        </div>
        <div className="inquiry_complaint_detail_body">
          <div className="inquiry_complaint_detail_create_updated_date">
            <div className="inquiry_complaint_detail_create_date">
              <h2>Created At </h2>
              <p>
                {moment(inquiriesDetails?.created_at).format(
                  "DD/MM/YYYY, h:mm A"
                )}
              </p>
            </div>
            <div className="inquiry_complaint_detail_updated_date">
              <h2>Updated At </h2>
              <p>
                {moment(inquiriesDetails?.updated_at).format(
                  "DD/MM/YYYY, h:mm A"
                )}
              </p>
            </div>
          </div>
          <div className="inquiry_complaint_detail_text">
            <h2 className="inquiry_complaint_detail_heading">Name</h2>
            <p className="inquiry_complaint_detail_name">
              {inquiriesDetails?.name}
            </p>
          </div>
          <div className="inquiry_complaint_detail_text">
            <h2 className="inquiry_complaint_detail_heading">Email</h2>
            <p className="inquiry_complaint_detail_name">
              {inquiriesDetails?.email}
            </p>
          </div>
          <div className="inquiry_complaint_detail_text">
            <h2 className="inquiry_complaint_detail_heading">Phone Number</h2>
            <p className="inquiry_complaint_detail_name">
              {inquiriesDetails?.phone_number}
            </p>
          </div>
          <div className="inquiry_complaint_detail_text">
            <h2 className="inquiry_complaint_detail_heading">Remarks</h2>
            <p className="inquiry_complaint_detail_name">
              {inquiriesDetails?.remarks}
            </p>
          </div>
          <div className="inquiry_complaint_detail_text">
            <h2 className="inquiry_complaint_detail_heading">
              Services Detail
            </h2>
            <p className="inquiry_complaint_detail_name">
              {inquiriesDetails?.services_detail}
            </p>
          </div>
          <div className="inquiry_complaint_detail_text">
            <h2 className="inquiry_complaint_detail_heading">Message</h2>
            <p className="inquiry_complaint_detail_name">
              {inquiriesDetails?.message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryDetail;
