import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";

export const getMobileConfigDetail = createAsyncThunk(
  "getMobileConfigDetail",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        "/users/app-status-update/1",
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

export const addMobileConfigData = createAsyncThunk(
  "addMobileConfigData",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/users/app-status-update?id=${data.id || ""}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Mobile Configuration Updated Successfully !", {
          autoClose: 2000,
        });
      }
      return response.data;
    } catch (error) {
      console.log("error", error.response);
    }
  }
);

const mobileConfigSlice = createSlice({
  name: "mobileConfig",
  initialState: {
    mobileConfigDetail: null,
    addMobileConfig: null,
    error: null,
    loader: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMobileConfigDetail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMobileConfigDetail.fulfilled, (state, action) => {
      state.loader = false;
      state.mobileConfigDetail = action.payload;
    });
    builder.addCase(getMobileConfigDetail.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    builder.addCase(addMobileConfigData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addMobileConfigData.fulfilled, (state, action) => {
      state.loader = false;
      state.addMobileConfig = action.payload;
    });
    builder.addCase(addMobileConfigData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default mobileConfigSlice.reducer;
