import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CreatePrivilegePage from "./CreatePrivilegePage";
import Loader from "../../component/Common/Loader";
import { getPrivilegeById } from "../../store/slice/privilegeSlice";

const EditPrivilegePage = () => {
  const [isPrivilegeDetail, setIsPrivilegeDetail] = useState(true);

  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;

  const loader = useSelector((state) => state.privilege.loader);

  const privilegeDetails = useSelector(
    (state) => state.privilege.getPrivilegeById
  );

  const updatePrivilege = useSelector(
    (state) => state.privilege.updatePrivilege
  );

  useEffect(() => {
    if (!privilegeDetails && id) {
      dispatch(getPrivilegeById(id));
    } else {
      setIsPrivilegeDetail(false);
    }
  }, [dispatch, id, privilegeDetails]);

  useEffect(() => {
    dispatch(getPrivilegeById(id));
  }, [id, updatePrivilege, dispatch]);

  if (isPrivilegeDetail) {
    return <div />;
  }

  return (
    <>
      {loader && <Loader />}
      <CreatePrivilegePage privilegeDetails={privilegeDetails} />
    </>
  );
};

export default EditPrivilegePage;
