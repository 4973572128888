import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { editIcon, searchIcon, viewIcon } from "../../icons";
import {
  clearCustomerProperty,
  getCustomerPropertyList,
  getCustomerPropertyListForCSV,
} from "../../store/slice/customerPropertySlice";
import Select from "react-select";
import {
  getProjectList,
  getTowersByProject,
} from "../../store/slice/projectSlice";
import CsvToJson from "../../component/CsvToJson/CsvToJson";
import moment from "moment";
import CustomerPropertyDetailModal from "../../component/Property/CustomerPropertyDetailModal";
import { CSVLink } from "react-csv";

const mobileStatusOptions = [
  { value: "", label: "All" },
  { value: "1", label: "App user" },
  { value: "0", label: "Not app user" },
];

const assignOptions = [
  { value: "", label: "All" },
  { value: "ASSIGN", label: "Assigned" },
  { value: "UNASSIGN ", label: "Not Assigned" },
];

const CustomerPropertiesListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;

  const csvInstance = useRef();

  const towerList = useSelector((state) => state.project.towerList);

  const [csv, setCsv] = useState(false);

  useEffect(() => {
    if (csv && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsv(false);
      });
    }
  }, [csv]);

  const location = useLocation();
  const value = location?.state?.value;

  const initialFValues = {
    phone_number: value?.phone_number ? value?.phone_number : "",
    project_id: value?.project_id ? value?.project_id : null,
    mobileStatus: value?.mobileStatus ? value?.mobileStatus : "",
    tower_name: value?.tower_name ? value?.tower_name : "",
    property: value?.property ? value?.property : "",
    propertyDetail: false,
    propertyDetailModalData: [],
    job_access_id: "",
    job_user_id: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.customerProperty}/page/1`);
      dispatch(clearCustomerProperty());
      dispatch(
        getCustomerPropertyList({
          project_id: values.project_id?.id,
          phone_number: values.phone_number,
          mobileStatus: values.mobileStatus.value,
          tower_name: values.tower_name?.value,
          property: values.property?.value,
        })
      );
    }
  }, [
    dispatch,
    values.phone_number,
    values.project_id?.id,
    values.mobileStatus.value,
    values.tower_name,
    values.property,
  ]);

  useEffect(() => {
    // if (!value) {
    navigate(`${routes.customerProperty}/page/${page_number}`);
    dispatch(clearCustomerProperty());
    dispatch(
      getCustomerPropertyList({
        start: (page_number - 1) * 10,
        project_id: values.project_id?.id,
        phone_number: values.phone_number,
        mobileStatus: values.mobileStatus.value,
        tower_name: values.tower_name?.value,
        property: values.property?.value,
      })
    );
    ref.current = true;
  }, [dispatch]);

  useEffect(() => {
    if (
      values.project_id === null ||
      values.project_id === "" ||
      values.project_id?.id == ""
    ) {
      setValues({
        ...values,
        tower_name: "",
      });
    } else {
      dispatch(getTowersByProject({ id: values.project_id?.id }));
      {
        !value &&
          setValues({
            ...values,
            tower_name: "",
          });
      }
    }
  }, [values.project_id]);

  useEffect(() => {
    dispatch(getProjectList({ admin: "true" }));
  }, [dispatch]);

  const projectList = useSelector((state) => state.project.projects);

  const projectIdOptions = projectList?.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const towerOptions = towerList?.map((item) => {
    return { value: item.name, label: item.name };
  });

  const allTowerOptions = towerOptions && [
    { value: "", label: "All Towers" },
    ...towerOptions,
  ];

  const projectOptions = projectIdOptions && [
    { value: "All Projects", label: "All Projects", id: "" },
    ...projectIdOptions,
  ];

  const loader = useSelector((state) => state.customerProperty.loader);

  const CustomerPropertyList = useSelector(
    (state) => state.customerProperty.customerPropertyList?.data
  );

  const totalCount = useSelector(
    (state) => state.customerProperty.customerPropertyList?.totalCount
  );

  const mobileUserHandler = (e) => {
    page_number !== 1 && navigate(`${routes.customerProperty}/page/1`);
    setValues({ ...values, phone_number: e.target.value });
    let page_num;
    if (page_number === 1) {
      page_num = 0;
    } else {
      page_num = (page_number - 1) * 2;
    }
    dispatch(
      getCustomerPropertyList({
        phone_number: e.target.value,
        start: page_num,
        limit: 10,
      })
    );
  };

  const onPageChange = (page_number) => {
    dispatch(
      getCustomerPropertyList({
        project_id: values.project_id?.id,
        tower_name: values.tower_name?.value,
        phone_number: values.phone_number,
        mobileStatus: values.mobileStatus.value,
        property: values.property?.value,
        start: page_number,
        limit: 10,
      })
    );
  };

  const propertyModalHandler = (access_id, id) => {
    setValues({
      ...values,
      propertyDetail: true,
      job_access_id: JSON.parse(access_id),
      job_user_id: +id,
    });
  };

  const hideModal = () => {
    setValues({
      ...values,
      propertyDetail: false,
      propertyDetailModalData: [],
    });
  };

  const propertyDownloadHandler = () => {
    dispatch(
      getCustomerPropertyListForCSV({
        limit: 1000000,
        project_id: values.project_id?.id,
        phone_number: values.phone_number,
        mobileStatus: values.mobileStatus.value,
        tower_name: values.tower_name?.value,
        property: values.property?.value,
      })
    ).then((res) => {
      setCsv([
        [
          "First Name",
          "Last Name",
          "Phone number",
          "Project name",
          "Tower name",
          "Property number",
          "Address",
          "Birth date",
          "Email",
          "User type",
          "User status",
          "Wallet balance",
          "Created At",
        ],
        ...res.payload?.data?.map((item) => {
          return [
            item.first_name,
            item.last_name,
            item.phone_number,
            item.project_name,
            item.tower_name,
            item.property_number,
            item.address,
            item.birth_date &&
              moment(item.birth_date).format("DD/MM/YYYY, kk:mm:ss"),
            item.email,
            item.user_type,
            item.user_status,
            item.wallet_balance,
            item.created_at &&
              moment(item.created_at).format("DD/MM/YYYY, kk:mm:ss"),
          ];
        }),
      ]);
    });
  };

  return (
    <>
      {loader && <Loader />}
      <div>
        <div className="project_edit_main_content">
          <div className="creat_edit_project_btn_row customer_property-header">
            <CsvToJson />
            <div>
              <Link
                to={routes.createBulkProperties}
                className="comman_btn ml-auto creat_edit_project_btn_row"
              >
                Create Bulk Properties
              </Link>
              <Link
                to={routes.createCustomerProperty}
                className="comman_btn ml-auto creat_edit_project_btn_row"
              >
                Create Customer Property
              </Link>
              <div className="creat_edit_project_btn_row mt-15">
                <button
                  className="download_btn comman_btn"
                  onClick={(e) => propertyDownloadHandler()}
                >
                  Export Property CSV
                </button>
              </div>
              <div className="creat_edit_project_btn_row">
                {csv.length > 0 ? (
                  <CSVLink
                    className="comman_btn w-0"
                    data={csv}
                    filename="customerproperties.csv"
                    ref={csvInstance}
                  >
                    Export Property CSV
                  </CSVLink>
                ) : undefined}
              </div>
            </div>
          </div>
          <div className="user_data_search_select_box_row">
            <div className="user_data_search m-0">
              <Input
                inputClassName="user_data_search_input"
                type="search"
                placeholder="Search by Mobile-number"
                id="phone_number"
                name="phone_number"
                maxLength={10}
                value={values.phone_number}
                onChange={mobileUserHandler}
              />
              <Button
                buttonClassName="user_data_search_btn"
                text={searchIcon}
              />
            </div>
            <div className="user_data_select lead_status-options">
              <Select
                placeholder="Select Assign..."
                value={values.property}
                onChange={(e) => handleInputChange(e, "property")}
                className="basic-multi-select"
                classNamePrefix="select"
                name="property"
                id="property"
                options={assignOptions}
                menuPortalTarget={document?.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </div>
            <div className="user_data_select lead_status-options">
              <Select
                placeholder="Select Project..."
                value={values.project_id}
                onChange={(e) => handleInputChange(e, "project_id")}
                className="basic-multi-select"
                classNamePrefix="select"
                name="project_id"
                id="project_id"
                options={projectOptions}
                menuPortalTarget={document?.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </div>
            <div className="user_data_select lead_status-options">
              <Select
                placeholder="Select Tower..."
                value={values.tower_name}
                onChange={(e) => handleInputChange(e, "tower_name")}
                className="basic-multi-select"
                classNamePrefix="select"
                name="tower_name"
                id="tower_name"
                options={allTowerOptions}
                menuPortalTarget={document?.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </div>
            <div className="user_data_select lead_status-options">
              <Select
                placeholder="App Status..."
                value={values.mobileStatus}
                onChange={(e) => handleInputChange(e, "mobileStatus")}
                className="basic-multi-select"
                classNamePrefix="select"
                name="mobileStatus"
                options={mobileStatusOptions}
                menuPortalTarget={document?.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </div>
          </div>

          {CustomerPropertyList?.length === 0 ? (
            <div className="no-results-found-container">
              <h2 className="c-red">No results found</h2>
            </div>
          ) : (
            <div className="custom_data_table_content">
              <table className="custom_data_table">
                <thead className="custom_data_table_head">
                  <tr>
                    <th className="custom_data_table_heading">Customer Name</th>
                    <th className="custom_data_table_heading">Project Name</th>
                    <th className="custom_data_table_heading"></th>
                    <th className="custom_data_table_heading">Tower Name</th>
                    <th className="custom_data_table_heading">
                      Property Number
                    </th>
                    <th className="custom_data_table_heading">Phone Number</th>
                    <th className="custom_data_table_heading">Created At</th>
                    <th className="custom_data_table_heading">Email</th>
                    <th className="custom_data_table_heading"></th>
                  </tr>
                </thead>
                <tbody className="custom_data_table_body">
                  {CustomerPropertyList?.map((item, i) => {
                    return (
                      <tr className="custom_data_table_row" key={i}>
                        <td className="custom_data_table_item table_item">
                          {item.first_name} {item.last_name}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.project_name}
                        </td>
                        <td className={`custom_data_table_item table_item`}>
                          {item.user_login_status === "1" && (
                            <i className="mobile-icon fa-solid fa-mobile-screen-button"></i>
                          )}
                        </td>
                        <td className={`custom_data_table_item table_item`}>
                          {item.tower_name}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.property_number}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.phone_number}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {moment(item.created_at).format("DD/MM/YYYY, h:mm A")}
                        </td>
                        <td className="custom_data_table_item table_item">
                          {item.email}
                        </td>

                        <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                          <div className="custom_data_table_view_edit_btn_item_row">
                            <Link
                              to={`${routes.customerProperty}/edit/${item.id}/${page_number}`}
                              state={{
                                value: {
                                  phone_number: values.phone_number,
                                  project_id: values.project_id,
                                  mobileStatus: values.mobileStatus,
                                  tower_name: values.tower_name,
                                  property: values.property,
                                },
                              }}
                              className="custom_data_table_view_edit_item_btn "
                            >
                              {editIcon}
                            </Link>
                            <button
                              className="custom_data_table_view_edit_item_btn"
                              onClick={(e) =>
                                propertyModalHandler(
                                  item.access_user_id,
                                  item.user_id
                                )
                              }
                            >
                              {viewIcon}
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {<Pagination totalRecords={totalCount} onPageChange={onPageChange} />}
          <CustomerPropertyDetailModal
            show={values.propertyDetail}
            values={values}
            setValues={setValues}
            handleClose={hideModal}
          >
            <p>Modal</p>
          </CustomerPropertyDetailModal>
        </div>
      </div>
    </>
  );
};

export default CustomerPropertiesListPage;
