import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CreatePropertyForm from "../../component/Property/CreatePropertyForm";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import { getUserList, registerUser } from "../../store/slice/userSlice";
import { useForm } from "../../hooks/useForm";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import TextArea from "../../component/Common/TextArea";
import { toast } from "react-toastify";
import CommonDatePicker from "../../component/Common/CommonDatePicker";
import moment from "moment/moment";

const CustomerPropertiesPage = () => {
  const initialFValues = {
    phone_number: "",
    phone_number_error: "",
    user: false,
    userNotFound: false,
    first_name: "",
    last_name: "",
    birth_date: "",
    email: "",
    hide: false,
    address: "",
    userForProperty: "",
  };

  const dispatch = useDispatch();
  const loader = useSelector((state) => state.customerProperty.loader);
  const nextButtonHandler = async () => {
    if (!values.phone_number) {
      setValues({
        ...values,
        phone_number_error: "mobile Number is require!",
      });
    } else {
      await dispatch(
        getUserList({
          mobileNum: values.phone_number,
          cb: (userData, error) => {
            if (error) {
              console.log("error", error);
            } else {
              if (userData[0]?.user_type === "BROKER") {
                toast.error("We can not allot property to broker", {
                  autoClose: 4000,
                });
              } else if (userData.length > 0) {
                setValues({
                  ...values,
                  user: true,
                  userNotFound: false,
                  userForProperty: userData[0],
                  hide: true,
                });
              } else {
                setValues({
                  ...values,
                  user: false,
                  userNotFound: true,
                  hide: true,
                });
              }
            }
          },
          callBack: true,
        })
      );
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues) {
      temp.first_name = fieldValues.first_name
        ? ""
        : "First name is required !";
    }
    if ("last_name" in fieldValues) {
      temp.last_name = fieldValues.last_name ? "" : "Last name is required !";
    }
    if ("phone_number" in fieldValues) {
      if (!fieldValues.phone_number) {
        temp.phone_number = "Phone number is required !";
      } else {
        temp.phone_number =
          fieldValues.phone_number.length === 10
            ? ""
            : "Phone number is not valid !";
      }
    }
    // if ("birth_date" in fieldValues) {
    //   temp.birth_date = fieldValues.birth_date
    //     ? ""
    //     : "Birth Date is required !";
    // }
    // if ("last_name" in fieldValues) {
    //   temp.last_name = fieldValues.last_name ? "" : "Last name is required !";
    // }
    // if ("email" in fieldValues) {
    //   temp.email = fieldValues.email ? "" : "Email is required !";
    // }
    // if ("address" in fieldValues) {
    //   temp.address = fieldValues.address ? "" : "Address is required !";
    // }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const submitButtonHandler = () => {
    validate();
    const data = {
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      phone_number: values.phone_number.trim(),
      // birth_date:
      //   values.birth_date && moment(values.birth_date).format("YYYY-MM-DD"),
      user_type: "CUSTOMER",
      register_status: "1",
      email: values.email.trim(),
      address: values.address.trim(),
    };
    if(values.birth_date && values.birth_date != '') {
      data.birth_date = moment(values.birth_date).format("YYYY-MM-DD");
    }
    if (validate()) {
      setValues({ ...values, user: !values.user });
      dispatch(
        registerUser({
          data,
          cb: (userData, error) => {
            if (error) {
              console.log("error", error);
            } else {
              nextButtonHandler(true);
            }
          },
        })
      );
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.customerProperty}/page/1`}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          {!values.hide && (
            <div className="create_from_input_content ">
              <Input
                labelClassName="create_from_label"
                inputClassName="create_from_input"
                errorClassName="err_text"
                type="tel"
                placeholder="Enter mobile number"
                label="Mobile Number"
                id="phone_number"
                name="phone_number"
                maxLength={10}
                value={values.phone_number}
                onChange={handleInputChange}
                error={errors?.phone_number || ""}
              />
              <Button
                className="create_from_bottom_btn property-next-btn"
                buttonClassName="comman_btn"
                type="submit"
                onClick={nextButtonHandler}
                text="Next"
              />
            </div>
          )}
          {values.user ? (
            <CreatePropertyForm userForProperty={values.userForProperty} />
          ) : (
            values.userNotFound &&
            values.phone_number && (
              <div className="customer_properties_number_extra_added_content col_span_2">
                <Input
                  className="create_from_input_content null"
                  labelClassName="create_from_label"
                  inputClassName="create_from_input"
                  errorClassName="err_text"
                  type="text"
                  placeholder="Enter Your first name"
                  label="First name"
                  id="first_name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleInputChange}
                  error={errors?.first_name || ""}
                />
                <Input
                  className="create_from_input_content"
                  labelClassName="create_from_label"
                  inputClassName="create_from_input"
                  errorClassName="err_text"
                  type="text"
                  placeholder="Enter Your last name"
                  label="Last name"
                  id="last_name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleInputChange}
                  error={errors?.last_name || ""}
                />
                <Input
                  className="create_from_input_content"
                  labelClassName="create_from_label"
                  inputClassName="create_from_input"
                  type="tel"
                  label="Mobile Number"
                  id="phone_number"
                  name="phone_number"
                  maxLength={10}
                  disabled={true}
                  value={values.phone_number}
                  onChange={handleInputChange}
                />
                {/* <Input
                  className="create_from_input_content"
                  labelClassName="create_from_label"
                  inputClassName="create_from_input"
                  errorClassName="err_text"
                  type="text"
                  placeholder="Enter Birth Date (YYYY/MM/DD)"
                  label="Birth Date"
                  id="birth_date"
                  name="birth_date"
                  value={values.birth_date}
                  onChange={handleInputChange}
                  error={errors?.birth_date || ""}
                /> */}
                <CommonDatePicker
                  values={values}
                  setValues={setValues}
                  errors={errors}
                  setErrors={setErrors}
                  label={"Select Birth Date"}
                  name="birth_date"
                  dateFormat={"dd/MM/yyyy"}
                  maxDate={new Date()}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                />
                <Input
                  className="create_from_input_content"
                  labelClassName="create_from_label"
                  inputClassName="create_from_input"
                  errorClassName="err_text"
                  type="text"
                  placeholder="Enter Email"
                  label="Email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors?.email || ""}
                />
                <TextArea
                  className="create_from_input_content col_span_2"
                  labelClassName="create_from_label"
                  inputClassName="create_from_input"
                  errorClassName="err_text"
                  type="text"
                  placeholder="Enter Address"
                  label="Address"
                  id="address"
                  name="address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors?.address || ""}
                />
                <Button
                  className="create_from_bottom_btn col_span_2"
                  buttonClassName="comman_btn"
                  onClick={submitButtonHandler}
                  text="Submit"
                />
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerPropertiesPage;
