import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import { getNoticeById } from "../../store/slice/noticeSlice";
import CreateNoticePage from "./CreateNoticePage";

const EditNoticePage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const notifications = useSelector((state) => state.notifications.noticeId);
  const loader = useSelector((state) => state.notifications.loader);

  useEffect(() => {
    if (notifications.length === 0 && id) {
      dispatch(getNoticeById(id));
    } else {
      setIsLoading(false);
    }
  }, [dispatch, notifications, id]);

  if (isLoading) {
    return <div />;
  }
  return (
    <>
      {loader && <Loader />}
      <div>
        <CreateNoticePage notifications={notifications} />
      </div>
    </>
  );
};

export default EditNoticePage;
