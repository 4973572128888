import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { json, Link, useLocation } from "react-router-dom";
import { routes } from "../../constants";
import { editIcon } from "../../icons";
import {
  clearCustomerProperty,
  getCustomerPropertyById,
  updateCustomerPropertyDetail,
} from "../../store/slice/customerPropertySlice";
import { useState } from "react";
import VerifyPropertyModal from "./VerifyPropertyModal";
import UnVerifyPropertyModal from "./UnVerifyPropertyModal";

const Properties = ({ userId, userType }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const verificationStatus = location?.state?.value?.userStatus?.value;

  // console.log("verificationStatus======>", verificationStatus);

  const propertyById = useSelector(
    (state) => state.customerProperty.getCustomerPropertyId
  );

  // console.log("propertyById", propertyById);

  const [verifyCondirmation, setVerifyConfirmation] = useState(false);
  const [propertyData, setPropertyData] = useState("");

  const [openUnVerifyModal, setOpenUnVerifyModal] = useState(false);
  const [unVerifyPropertyData, setUnVerifyPropertyData] = useState("");

  const [storingVerificationStatus, setStoringVerificationStatus] =
    useState(verificationStatus);

  const [reject, setReject] = useState(false);

  useEffect(() => {
    dispatch(clearCustomerProperty());
    // dispatch(getCustomerPropertyById(userId));
    dispatch(
      getCustomerPropertyById({
        id: userId,
        user_status: verificationStatus,
      })
    );
  }, [dispatch]);

  if (propertyById?.length === 0) {
    return <h2 className="user_detail-nodata">No Data Found !</h2>;
  }

  //Handler for Opening verify Modal
  const showVerifyConfirmationModal = (property) => {
    setVerifyConfirmation(true);
    setPropertyData(property);
  };

  //Handler for closing verify Modal
  const handleVerifyCondirmationClose = () => {
    setVerifyConfirmation(false);
    setPropertyData("");
  };

  //Main handler for property Verifying
  const verifyPropertyHandler = () => {
    const data = {
      property_status: "verified",
    };
    dispatch(
      updateCustomerPropertyDetail({
        id: propertyData?.id,
        data,
        toastMsg: "Property has been verified!",
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            // dispatch(getCustomerPropertyById(userId));
            dispatch(
              getCustomerPropertyById({
                id: userId,
                user_status: verificationStatus,
              })
            );
            setVerifyConfirmation(false);
            setPropertyData("");
          }
        },
      })
    );
  };

  //handler for opening Un-verify Modal and setting data in state
  const showModalToUnVerifyProperties = (property) => {
    setOpenUnVerifyModal(true);
    setUnVerifyPropertyData(property);
  };

  //handler for closing Un-verify Modal
  const closeUnVerifyModal = () => {
    setOpenUnVerifyModal(false);
    setUnVerifyPropertyData("");
  };

  //Main Handler for property Un-Verifying
  const unVerifyPropertyHandler = () => {
    const data = {
      property_status: "unverified",
    };

    if (reject) {
      data.user_id = unVerifyPropertyData?.access_user_id_1;
    }

    dispatch(
      updateCustomerPropertyDetail({
        id: unVerifyPropertyData?.id,
        data,
        toastMsg: reject
          ? "Property has been Rejected!"
          : "Property has been UnVerified!",
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            // dispatch(getCustomerPropertyById(userId));
            dispatch(
              getCustomerPropertyById({
                id: userId,
                user_status: verificationStatus,
              })
            );
            setOpenUnVerifyModal(false);
            setUnVerifyPropertyData("");
          }
        },
      })
    );
  };

  // console.log(
  //   "+JSON.parse(item?.access_user_id",
  //   JSON?.parse(propertyById[0]?.access_user_id)
  // );

  return (
    <div className="project_edit_main_content">
      <div className="custom_data_table_content">
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              <th className="custom_data_table_heading">Project Name</th>
              <th className="custom_data_table_heading">Tower Name</th>
              <th className="custom_data_table_heading">User type</th>
              <th className="custom_data_table_heading">Property Number</th>
              <th className="custom_data_table_heading">Status</th>
              <th className="custom_data_table_heading"></th>
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {userType === "CUSTOMER" &&
              propertyById?.map((item, i) => {
                return (
                  <tr className="custom_data_table_row" key={i}>
                    <td className="custom_data_table_item table_item">
                      {item?.project_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.tower_name}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {/* {+item?.access_user_id_1 ===
                      +JSON.parse(item?.access_user_id)[0]
                        ? "Main user"
                        : "Sub user"} */}
                      {item?.access_user_id_1 !== null &&
                      item?.access_user_id !== null
                        ? +item.access_user_id_1 ===
                          +JSON.parse(item.access_user_id)[0]
                          ? "Main user"
                          : "Sub user"
                        : "Sub user"}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.property_number}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item?.property_status === "unverified" ? (
                        <span className="custom_data_table_view_edit_item_btn">
                          <button
                            className="comman_btn prop_verify_btn reject_verify_un_verify_btn"
                            onClick={() => {
                              setReject(true);
                              showModalToUnVerifyProperties(item);
                            }}
                          >
                            Reject
                          </button>
                          <button
                            className="comman_btn prop_verify_btn"
                            onClick={() => showVerifyConfirmationModal(item)}
                          >
                            Verify
                          </button>
                        </span>
                      ) : (
                        <span className="custom_data_table_view_edit_item_btn">
                          <button
                            className="comman_btn prop_verify_btn reject_verify_un_verify_btn"
                            onClick={() => {
                              setReject(true);
                              showModalToUnVerifyProperties(item);
                            }}
                          >
                            Reject
                          </button>
                          <button
                            className="comman_btn prop_verify_btn"
                            onClick={() => {
                              setReject(false);
                              showModalToUnVerifyProperties(item);
                            }}
                          >
                            UnVerify
                          </button>
                        </span>
                      )}
                    </td>
                    <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                      <div className="custom_data_table_view_edit_btn_item_row">
                        {/* {item?.property_status === "unverified" ? (
                          <>
                            <span className="custom_data_table_view_edit_item_btn">
                              <button
                                className="comman_btn prop_verify_btn"
                                onClick={() => {
                                  setReject(true);
                                  showModalToUnVerifyProperties(item);
                                }}
                              >
                                Reject
                              </button>
                            </span>
                            <button
                              className="comman_btn prop_verify_btn"
                              onClick={() => showVerifyConfirmationModal(item)}
                            >
                              Verify
                            </button>
                          </>
                        ) : (
                          <button
                            className="comman_btn prop_verify_btn"
                            onClick={() => {
                              setReject(false);
                              showModalToUnVerifyProperties(item);
                            }}
                          >
                            UnVerify
                          </button>
                        )} */}
                        <Link
                          to={`${routes.customerProperty}/edit/${item?.id}/1`}
                          className="custom_data_table_view_edit_item_btn "
                        >
                          {editIcon}
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <VerifyPropertyModal
        verifyCondirmation={verifyCondirmation}
        handleVerifyCondirmationClose={handleVerifyCondirmationClose}
        verifyPropertyHandler={verifyPropertyHandler}
        propertyData={propertyData}
        storingVerificationStatus={storingVerificationStatus}
      />
      <UnVerifyPropertyModal
        openUnVerifyModal={openUnVerifyModal}
        unVerifyPropertyData={unVerifyPropertyData}
        unVerifyPropertyHandler={unVerifyPropertyHandler}
        closeUnVerifyModal={closeUnVerifyModal}
        reject={reject}
        setReject={setReject}
      />
    </div>
  );
};

export default Properties;
