import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

//createNotice
export const createNotice = createAsyncThunk(
  "createNotice",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        "/company/add-notification",
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Notification Create Successfully !", {
          autoClose: 2000,
        });
        data.navigate(`${routes.notifications}/page/1`);
      }
      return response.data;
    } catch (error) {
      console.log("createNoticeError", error.response);
    }
  }
);
// /getNotice
export const getNotice = createAsyncThunk(
  "getNotice",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/company/get/notifications?start=${data.start || 0}&limit=${
          data.limit || 10
        }`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("err", error.response);
    }
  }
);
//getNoticeById
export const getNoticeById = createAsyncThunk(
  "getNoticeById",
  async (id, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/company/get/notifications/${id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("err", error.response);
    }
  }
);
//clearNotice
export const clearNotice = createAsyncThunk("clearNotice", () => {
  return;
});
//updateNoticeList
export const updateNoticeList = createAsyncThunk(
  "updateNoticeList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/company/update-notifications/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Notification Updated Successfully !", {
          autoClose: 2000,
        });
        data.navigate(routes.notifications);
      }
      return response.data;
    } catch (error) {
      console.log("updateNoticeError", error.response);
    }
  }
);
//createSlice
const noticeSlice = createSlice({
  name: "notifications",
  initialState: {
    createNotice: null,
    notifications: [],
    noticeId: [],
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //getNotice
    builder.addCase(getNotice.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getNotice.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.loader = false;
    });
    builder.addCase(getNotice.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //createNotice
    builder.addCase(createNotice.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(createNotice.fulfilled, (state, action) => {
      state.createNotice = action.payload;
      state.loader = false;
    });
    builder.addCase(createNotice.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getNoticeById
    builder.addCase(getNoticeById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getNoticeById.fulfilled, (state, action) => {
      state.noticeId = action.payload;
      state.loader = false;
    });
    builder.addCase(getNoticeById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //updateNoticeList
    builder.addCase(updateNoticeList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateNoticeList.fulfilled, (state, action) => {
      state.loader = false;
      state.createNotice = action.payload;
    });
    builder.addCase(updateNoticeList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //clearNotice
    builder.addCase(clearNotice.fulfilled, (state, action) => {
      state.noticeId = [];
      state.createNotice = null;
    });
  },
});

export default noticeSlice.reducer;
