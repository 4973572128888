export const getInitialLeadState = (leadDetails) => {
  return {
    first_name: leadDetails?.first_name ? leadDetails?.first_name : "",
    last_name: leadDetails?.last_name ? leadDetails?.last_name : "",
    lead_email: leadDetails?.lead_email ? leadDetails?.lead_email : "",
    phone_number: leadDetails?.phone_number ? leadDetails?.phone_number : "",
    project_id: leadDetails?.project_id
      ? {
          value: leadDetails?.project_name,
          label: leadDetails?.project_name,
          id: leadDetails?.project_id,
        }
      : null,
    user_type: leadDetails?.user_type ? leadDetails?.user_type : "CUSTOMER",
    remarks: leadDetails?.remarks ? leadDetails.remarks : "",
    referred_by: leadDetails?.referred_by
      ? {
          value: leadDetails?.referred_first_name,
          label:
            leadDetails?.referred_first_name +
            " " +
            leadDetails?.referred_last_name,
          id: leadDetails?.referred_by,
        }
      : null,
    lead_status_sub: leadDetails?.lead_status_sub
      ? leadDetails?.lead_status_sub
      : "",
    submitted: false,
    confirmed: false,
    visited: false,
    converted: false,
    rejected: false,
  };
};
export const leadValidate = (temp, fieldValues, values, setErrors) => {
  if ("first_name" in fieldValues) {
    temp.first_name = fieldValues?.first_name ? "" : "First name is required !";
  }
  if ("last_name" in fieldValues) {
    temp.last_name = fieldValues?.last_name ? "" : "Last name is required !";
  }
  if ("phone_number" in fieldValues) {
    if (!fieldValues.phone_number) {
      temp.phone_number = "Phone number is required !";
    } else {
      temp.phone_number =
        fieldValues.phone_number.length === 10
          ? ""
          : "Phone number is not valid !";
    }
  }
  if ("project_id" in fieldValues) {
    temp.project_id = fieldValues?.project_id ? "" : "Project Id is required !";
  }
  if ("referred_by" in fieldValues) {
    temp.referred_by = fieldValues?.referred_by
      ? ""
      : "Referred name is required !";
  }
  if ("remarks" in fieldValues) {
    temp.remarks = fieldValues?.remarks ? "" : "Remarks is required !";
  }
  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
