import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

export const getServiceList = createAsyncThunk(
  "getServiceList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/projects/get/contact-services/list?start=${data.start || 0}&limit=${
          data.limit || 10
        }`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("getContact", error);
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const createService = createAsyncThunk(
  "createService",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/projects/add-contact-service`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success(data.toast, {
          autoClose: 3000,
        });
        data.navigate(`${routes.service}/page/1`);
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

export const updateService = createAsyncThunk("updateService", async (data) => {
  try {
    const response = await Axios.put(
      `/projects/update/contact-service/${data.id}`,
      data.data,
      authHeaders()
    );
    if (response.status === 200 && !data.cb) {
      toast.info(data.toast, {
        autoClose: 3000,
      });
      data.navigate(`${routes.service}/page/1`);
      return response.data;
    }
    if (data.cb) {
      return data.cb(null, response.data);
    }
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: 3000 });
  }
});

//clear service
export const clearService = createAsyncThunk(
  "clearService",
  async (data, thunkAPI) => {
    return;
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    serviceList: [],
    createService: "",
    updateService: "",
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //GET SERVICE LIST
    builder.addCase(getServiceList.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getServiceList.fulfilled, (state, action) => {
      state.serviceList = action.payload;
      state.loader = false;
    });
    builder.addCase(getServiceList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //CREATE SERVICE
    builder.addCase(createService.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(createService.fulfilled, (state, action) => {
      state.createService = action.payload;
      state.loader = false;
    });
    builder.addCase(createService.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //UPDATE SERVICE
    builder.addCase(updateService.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(updateService.fulfilled, (state, action) => {
      state.updateService = action.payload;
      state.loader = false;
    });
    builder.addCase(updateService.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //clear Service
    builder.addCase(clearService.fulfilled, (state, action) => {
      state.serviceList = null;
      state.createService = null;
      state.updateService = null;
      state.loader = false;
    });
  },
});

export default serviceSlice.reducer;
