import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { routes } from "../../constants";
import Axios, { authHeaders } from "../../helpers/axios";

//getInquiryDetail
export const getInquiryDetail = createAsyncThunk(
  "getInquiryDetail",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get/inquiries?status=${data.status}&phone_number=${
          data?.phone_number ? data.phone_number : ""
        }&inquiry_type=${data.inquiry_type}&start=${data.start || 0}&limit=${
          data.limit || 10
        }`,
        authHeaders()
      );
      return {
        data: response.data,
        totalCount: response.headers["x-total-count"],
      };
    } catch (error) {
      console.log("getInquiryError", error.response);
    }
  }
);
//getInquiryDetailById
export const getInquiryDetailById = createAsyncThunk(
  "getInquiryDetailById",
  async (id, thunkAPI) => {
    try {
      const response = await Axios.get(
        `/users/get/inquiry/${id}`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      console.log("getInquiryError", error.response);
    }
  }
);
//clearInquiry
export const clearInquiry = createAsyncThunk("clearInquiry", () => {
  return;
});
//updateInquiryList
export const updateInquiryList = createAsyncThunk(
  "updateInquiryList",
  async (data, thunkAPI) => {
    try {
      const response = await Axios.put(
        `/users/update/inquiry/${data.id}`,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        toast.success("Inquiry Updated Successfully !", {
          autoClose: 2000,
        });
        data.navigate(`${routes.inquiries}/page/${data.page_number}`, {
          state: { value: data.value },
        });
      }
      return response.data;
    } catch (error) {
      console.log("updateInquiryError", error.response);
    }
  }
);
//createSlice
const inquirySlice = createSlice({
  name: "inquiry",
  initialState: {
    updateInquiry: [],
    inquiryDetail: [],
    inquiryDetailId: null,
    loader: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //getInquiryDetail
    builder.addCase(getInquiryDetail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getInquiryDetail.fulfilled, (state, action) => {
      state.inquiryDetail = action.payload;
      state.loader = false;
    });
    builder.addCase(getInquiryDetail.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //getInquiryDetailById
    builder.addCase(getInquiryDetailById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getInquiryDetailById.fulfilled, (state, action) => {
      state.inquiryDetailId = action.payload;
      state.loader = false;
    });
    builder.addCase(getInquiryDetailById.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //updateInquiryList
    builder.addCase(updateInquiryList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateInquiryList.fulfilled, (state, action) => {
      state.updateInquiry = action.payload;
      state.loader = false;
    });
    builder.addCase(updateInquiryList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //clearInquiry
    builder.addCase(clearInquiry.fulfilled, (state, action) => {
      state.inquiryDetailId = null;
    });
  },
});

export default inquirySlice.reducer;
