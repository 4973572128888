import React from "react";
import DatePicker from "react-datepicker";

const CommonDatePicker = ({
  values,
  setValues,
  errors,
  setErrors,
  label,
  name,
  dateFormat,
  showTimeInput,
  showTimeSelect,
  hasInfoIcon,
  timeIntervals,
  tooltipText,
  type,
  defaultValue,
  minDate,
  maxDate,
  showMonthDropdown,
  showYearDropdown,
}) => {
  //   const today = new Date();
  return (
    <div className="date_picker_container">
      <label className="create_from_label required">
        {label}
        {hasInfoIcon && (
          <div className="create_from_label_info_icon tooltip">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
            <span className="tooltiptext">{tooltipText}</span>
          </div>
        )}
      </label>
      <DatePicker
        placeholderText={`${label}`}
        wrapperClassName="date-picker_container create_from_input_content"
        className="date-picker_input create_from_input"
        selected={values[name]}
        onChange={(date) => {
          setValues({ ...values, [name]: date });
          setErrors({ ...errors, [name]: "" });
        }}
        // minDate={type !== "issue" && today}
        defaultValue={defaultValue}
        minDate={minDate && minDate}
        dateFormat={dateFormat ? dateFormat : "dd/MM/yyyy"}
        showTimeInput={showTimeInput}
        showTimeSelect={showTimeSelect}
        timeIntervals={timeIntervals}
        onKeyDown={(e) => e.preventDefault()}
        maxDate={maxDate && maxDate}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode="select"
      />
      {errors[name] && <span className="err_text">{errors[name]}</span>}
    </div>
  );
};

export default CommonDatePicker;
