import moment from "moment";
import React from "react";

const ContactFormModal = ({ handleClose, show, modalData }) => {
  const showHideClassName = show && "user_detail_modal_show";

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_video_img">
              {/* <p className="inquiry_complaint_detail_status">
                {modalData?.service_name}
              </p> */}
              {modalData?.image ? (
                <iframe
                  title="inquiry"
                  src={`${process.env.REACT_APP_FILE_BASE_URL}/${modalData?.image}`}
                  alt="Feedback item"
                  className="plan_slider_box_img"
                />
              ) : (
                <img
                  src="https://www.medstartr.com/main/images/no-image.png"
                  alt="default pic"
                  className="default_image"
                />
              )}
            </div>
            <div className="inquiry_complaint_detail_body">
              <div className="inquiry_complaint_detail_create_updated_date">
                <div className="inquiry_complaint_detail_create_date">
                  <h2>Created At </h2>
                  <p>
                    {moment(modalData?.created_at).format("DD/MM/YYYY, h:mm A")}
                  </p>
                </div>
                {modalData?.updated_at && (
                  <div className="inquiry_complaint_detail_updated_date">
                    <h2>Updated At </h2>
                    <p>
                      {moment(modalData?.updated_at).format(
                        "DD/MM/YYYY, h:mm A"
                      )}
                    </p>
                  </div>
                )}
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Contact Name
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {modalData?.contact_name}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">
                  Service Name
                </h2>
                <p className="inquiry_complaint_detail_name">
                  {modalData?.service_name}
                </p>
              </div>
              {modalData?.phone_number && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">
                    phone Number
                  </h2>
                  <p className="inquiry_complaint_detail_name">
                    {modalData?.phone_number}
                  </p>
                </div>
              )}
              {modalData?.contact_address && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">Address</h2>
                  <p className="inquiry_complaint_detail_name">
                    {modalData?.contact_address}
                  </p>
                </div>
              )}
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            Close
          </button>
        </div>
      </section>
    </>
  );
};

export default ContactFormModal;
