import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeadById } from "../../store/slice/leadSlice";
import "../../css/LoginPage.css";
import Loader from "../../component/Common/Loader";
import LeadDetail from "../../component/Lead/LeadDetail";

const LeadDetailModal = ({ handleClose, show, leadId }) => {
  const showHideClassName = show && "user_detail_modal_show";
  const dispatch = useDispatch();

  const loader = useSelector((state) => state.lead.loader);
  useEffect(() => {
    if (leadId) {
      dispatch(getLeadById(leadId));
    }
  }, [leadId, dispatch]);

  const leadListId = useSelector((state) => state.lead.leadId);

  return (
    <>
      {loader && <Loader />}
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        {/* <div className="comman_btn_container center_back_btn">
        <Link to={routes.leads} className="comman_btn back_btn">
          Back
        </Link>
      </div> */}
        <LeadDetail leadListId={leadListId} />
        <button
          type="button"
          onClick={handleClose}
          className="user_detail_modal_close_btn comman_btn"
        >
          Close
        </button>
      </section>
    </>
  );
};

export default LeadDetailModal;
