import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../component/Common/Button";
import {
  getFeaturedProjectList,
  updateProjectList,
} from "../../store/slice/projectSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";

const FeaturedProject = () => {
  const dispatch = useDispatch();

  let featuredProject = useSelector((state) => state.project.featuredProjects);
  const unFeaturedProject = useSelector(
    (state) => state.project.unFeaturedProjects
  );

  useEffect(() => {
    dispatch(getFeaturedProjectList({ featured: "1" }));
    dispatch(getFeaturedProjectList({ featured: "0" }));
  }, [dispatch]);

  const editFeaturedHandler = (featured, id) => {
    let data;
    if (featured === "1") {
      data = {
        featured_project: "0",
        order_number: 0,
      };
    } else {
      data = {
        featured_project: "1",
        order_number: featuredProject.length + 1,
      };
    }

    dispatch(
      updateProjectList({
        data,
        id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getFeaturedProjectList({ featured: "1" }));
            dispatch(getFeaturedProjectList({ featured: "0" }));
          }
        },
      })
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      featuredProject,
      result.source.index,
      result.destination.index
    );

    featuredProject = items;
  };

  const saveFeaturedHandler = () => {
    featuredProject.forEach((element, i) => {
      const data = {
        order_number: i + 1,
      };
      dispatch(updateProjectList({ data, id: element.id, toast: 1 }));
    });
    toast.info("Project Update Successfully !", {
      autoClose: 2000,
    });
  };

  return (
    <div className="project_edit_main_content">
      <div className="custom_data_table_content">
        <h2 className="featured_project_heading">Projects</h2>
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              <th className="custom_data_table_heading"></th>
              <th className="custom_data_table_heading"> Project Name</th>
              <th className="custom_data_table_heading">Possession Status</th>
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {unFeaturedProject &&
              unFeaturedProject?.map((item, i) => {
                return (
                  <tr className="custom_data_table_row" key={i}>
                    <td
                      className="custom_data_table_item featured_btn_width"
                      style={{ textAlign: "center" }}
                    >
                      {/* <i className="fa-solid fa-plus comman_btn"></i> */}
                      <Button
                        buttonClassName="comman_btn featured_btn"
                        className="create_from_bottom_btn feedback_status-btn"
                        onClick={() =>
                          editFeaturedHandler(item.featured_project, item.id)
                        }
                        text={<i className="fa-solid fa-plus"></i>}
                      />
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item.name.toUpperCase()}
                    </td>
                    <td className="custom_data_table_item table_item">
                      {item.possession_status}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="custom_data_table_content">
        <h2 className="featured_project_heading">Featured Projects</h2>
        <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="custom_data_table_content"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <table className="custom_data_table">
                  <thead className="custom_data_table_head">
                    <tr>
                      <th className="custom_data_table_heading"></th>
                      <th className="custom_data_table_heading">
                        {" "}
                        Project Name
                      </th>
                      <th className="custom_data_table_heading">
                        Possession Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="custom_data_table_body">
                    {featuredProject &&
                      featuredProject?.map((item, i) => {
                        return (
                          <>
                            <Draggable
                              key={item.id}
                              draggableId={`${item.id}`}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <tr
                                  className="custom_data_table_row"
                                  key={i}
                                  provided={provided}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <td
                                    className="custom_data_table_item featured_btn_width"
                                    style={{ textAlign: "center" }}
                                  >
                                    <Button
                                      buttonClassName="comman_btn unfeatured_btn"
                                      className="create_from_bottom_btn feedback_status-btn"
                                      onClick={() =>
                                        editFeaturedHandler(
                                          item.featured_project,
                                          item.id
                                        )
                                      }
                                      text={
                                        <i className="fa-solid fa-minus"></i>
                                      }
                                    />
                                  </td>
                                  <td className="custom_data_table_item table_item">
                                    {item.name.toUpperCase()}
                                  </td>
                                  <td className="custom_data_table_item table_item">
                                    {item.possession_status}
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          </>
                        );
                      })}
                  </tbody>
                </table>
                <Button
                  buttonClassName="comman_btn save_featured"
                  className="create_from_bottom_btn feedback_status-btn"
                  onClick={() => saveFeaturedHandler()}
                  text="Save"
                />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default FeaturedProject;
