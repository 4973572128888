import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../component/Common/Button";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { routes } from "../../constants";
import { editIcon, viewIcon } from "../../icons";
import {
  clearComplaint,
  getComplaintDetail,
  updateComplaint,
  updateFeedback,
} from "../../store/slice/complaintSlice";
import FeedBackDetailModal from "./FeedBackDetailModal";

const FeedBackPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const value = location?.state?.value;
  const params = useParams();
  const { page_number } = params;
  const loader = useSelector((state) => state.complaint.loader);
  const [mobileNum, setMobileNum] = useState(value ? value : "");

  const [show, setShow] = useState(false);
  const [feedBackId, setFeedBackId] = useState(null);

  const showModal = (i) => {
    setShow(true);
    setFeedBackId(i);
  };

  const hideModal = () => {
    setShow(false);
    setFeedBackId(null);
    dispatch(clearComplaint());
  };

  const mobileUserHandler = (e) => {
    page_number !== 1 && navigate(`${routes.feedBack}/page/1`);
    setMobileNum(e.target.value);
    e.preventDefault();
    let page_num;
    if (page_number === 1) {
      page_num = 0;
    } else {
      page_num = (page_number - 1) * 2;
    }
    dispatch(
      getComplaintDetail({
        status: "",
        req_type: "suggestion",
        start: page_num,
        limit: 10,
        phone_number: e.target.value,
      })
    );
  };

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.feedBack}/page/1`);
      dispatch(clearComplaint());
      dispatch(
        getComplaintDetail({
          status: "",
          req_type: "suggestion",
          phone_number: mobileNum,
        })
      );
    }
  }, [dispatch, mobileNum]);

  useEffect(() => {
    navigate(`${routes.feedBack}/page/${page_number}`);
    dispatch(clearComplaint());
    // if (+page_number === 1)
    dispatch(
      getComplaintDetail({
        status: "",
        start: (page_number - 1) * 10,
        req_type: "suggestion",
        phone_number: mobileNum,
      })
    );
    ref.current = true;
  }, [dispatch]);

  // useEffect(() => {
  //   console.log("page_number", page_number);
  //   if (!page_number) {
  //     dispatch(
  //       getComplaintDetail({
  //         phone_number: mobileNum,
  //         status: "",
  //         req_type: "suggestion",
  //       })
  //     );
  //   }
  // }, [dispatch, mobileNum, page_number]);

  const complaintDetail = useSelector(
    (state) => state.complaint.complaintDetails?.data
  );

  const totalCount = useSelector(
    (state) => state.complaint.complaintDetails?.totalCount
  );

  const editFeedbackHandler = (id, status) => {
    if (status === "closed") {
      toast.success("Feedback status already updated", {
        autoClose: 3000,
      });
    } else {
      const data = {
        status: "closed",
      };

      dispatch(
        updateFeedback({
          data,
          id,
          cb: (response, error) => {
            if (error) {
              console.log("error", error);
            } else {
              +page_number === 1
                ? onPageChange(0)
                : onPageChange((+page_number - 1) * 10);
            }
          },
          page_number,
        })
      );
    }
  };

  const onPageChange = (page_number) => {
    dispatch(
      getComplaintDetail({
        status: "",
        req_type: "suggestion",
        start: page_number,
        phone_number: mobileNum,
        limit: 10,
      })
    );
  };
  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content">
        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <input
              className="user_data_search_input"
              type="search"
              name="userProperty"
              placeholder="Search by Mobile-number"
              value={mobileNum}
              onChange={mobileUserHandler}
            />
            <button className="user_data_search_btn" type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </button>
          </div>
        </div>
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">Name</th>
                <th className="custom_data_table_heading"> Project Name</th>
                <th className="custom_data_table_heading">Subject</th>
                <th className="custom_data_table_heading">Phone Number</th>
                <th className="custom_data_table_heading">Status</th>
                <th className="custom_data_table_heading"></th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {complaintDetail &&
                complaintDetail.map((item, i) => {
                  return (
                    <tr className="custom_data_table_row" key={i}>
                      <td className="custom_data_table_item table_item">
                        {item.user_first_name} {item.user_last_name}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.project_name}
                      </td>

                      {/* <td className="custom_data_table_item table_item">
                        {item.tower_name}
                      </td> */}
                      <td className="custom_data_table_item table_item">
                        {item.subject}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.phone_number}
                      </td>

                      <td className="custom_data_table_item table_item">
                        <Button
                          buttonClassName="comman_btn"
                          className="create_from_bottom_btn feedback_status-btn"
                          onClick={() =>
                            editFeedbackHandler(item.complaint_id, item.status)
                          }
                          text={
                            item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)
                          }
                        />
                      </td>
                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <Link
                            to={`${routes.feedBack}/edit/${item.complaint_id}`}
                            state={{
                              feedbackState: item.reply,
                              value: mobileNum,
                              page_number,
                            }}
                            // {{
                            //   pathname: `${routes.feedBack}/edit/${item.complaint_id}`,
                            //   state: {
                            //     feedbackState: item.reply,
                            //   },
                            // }}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </Link>
                          <Button
                            buttonClassName="custom_data_table_view_edit_item_btn"
                            onClick={(e) => showModal(item.complaint_id)}
                            text={viewIcon}
                          />
                        </div>
                      </td>
                      {/* <td className="custom_data_table_item lead_btn_container table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <Button
                            buttonClassName="custom_data_table_view_edit_item_btn"
                            onClick={(e) => showModal(item.complaint_id)}
                            text={viewIcon}
                          />
                        </div>
                      </td> */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {<Pagination totalRecords={totalCount} onPageChange={onPageChange} />}
        <FeedBackDetailModal
          show={show}
          handleClose={hideModal}
          feedBackId={feedBackId}
        >
          <p>Modal</p>
        </FeedBackDetailModal>
      </div>
    </>
  );
};

export default FeedBackPage;
