import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "../../component/Common/Input";
import { useForm } from "../../hooks/useForm";
import Button from "../../component/Common/Button";
import { imageProps } from "../../props";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceData } from "../../component/Project/ProjectForms/ServiceForms";
import { createService, updateService } from "../../store/slice/serviceSlice";
import { fileUpload } from "../../helpers/fileUpload";
import Loader from "../../component/Common/Loader";

const CreateService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [uploading, setUploading] = useState(false);
  const editService = location?.state?.item;

  const loader = useSelector((state) => state?.contact?.loader);

  let initialImagePreview;
  // let initialCertificatePreview;

  if (editService?.image) {
    initialImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${editService.image}`;
  }

  // if (editService?.certificate) {
  //   initialCertificatePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${editService.certificate}`;
  // }

  const initialFValues = {
    service_name: editService?.service_name ? editService?.service_name : "",
    image: editService?.image ? editService?.image : "",
    image_preview: editService?.image ? initialImagePreview : "",
    // certificate: editService?.certificate ? editService?.certificate : "",
    // certificate_preview: editService?.certificate
    //   ? initialCertificatePreview
    //   : "",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("service_name" in fieldValues) {
      temp.service_name = fieldValues.service_name
        ? ""
        : "Service Name is required !";
    }

    if ("image" in fieldValues) {
      temp.image = "";
      if (!fieldValues.image) {
        temp.image = "Image required !";
      }

      if (fieldValues.image.size > 5000000) {
        temp.image = "Image size should be less than 5Mb !";
        setValues({
          ...values,
          image: "",
          image_preview: "",
        });
      }
      if (
        fieldValues.image.name &&
        !fieldValues.image.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.topic_image = "Please select a valid Image !";
        setValues({
          ...values,
          image: "",
          image_preview: "",
        });
      }
    }

    // if ("certificate" in fieldValues) {
    //   temp.certificate = "";
    //   if (!fieldValues.certificate) {
    //     temp.certificate = "Image required !";
    //   }

    //   if (fieldValues.certificate.size > 5000000) {
    //     temp.certificate = "Image size should be less than 5Mb !";
    //     setValues({
    //       ...values,
    //       certificate: "",
    //       certificate_preview: "",
    //     });
    //   }
    //   if (
    //     fieldValues.certificate.name &&
    //     !fieldValues.certificate.name?.match(/.(jpg|jpeg|png|gif)$/i)
    //   ) {
    //     temp.certificate = "Please select a valid Image !";
    //     setValues({
    //       ...values,
    //       image: "",
    //       certificate_preview: "",
    //     });
    //   }
    // }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const imageRemoveHandler = () => {
    setValues({ ...values, image: "", image_preview: "" });
  };

  // const certificateImageRemoveHandler = () => {
  //   setValues({ ...values, certificate: "", certificate_preview: "" });
  // };

  const createEditServiceHandler = async (e) => {
    e.preventDefault();

    if (validate()) {
      setUploading(true);
      const formData = new FormData();
      let imageUrl;
      if (values.image && typeof values.image !== "string") {
        formData.append("file", values.image);
        if (validate()) {
          imageUrl = await fileUpload(formData);
        }
        formData.delete("file");
      } else {
        imageUrl = values.image;
      }

      const data = {
        service_name: values.service_name,
        image: imageUrl,
        // certificate: certificateUrl,
      };

      editService
        ? dispatch(
            updateService({
              data,
              id: editService.id,
              toast: "Service updated successfully",
              navigate,
            })
          ).then(() => {
            setUploading(false);
          })
        : dispatch(
            createService({
              data,
              toast: "Service created successfully",
              navigate,
            })
          ).then(() => {
            setUploading(false);
          });
    }
  };

  return (
    <>
      {(uploading || loader) && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <button className="comman_btn back_btn" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Service Name"
            label="Service Name"
            id="service_name"
            name="service_name"
            value={values.service_name}
            onChange={handleInputChange}
            error={errors?.service_name || ""}
          />

          <div className="create_from_input_content null"></div>

          <Input
            className="create_from_input_content"
            labelClassName="create_from_label"
            errorClassName="err_text"
            type="file"
            id="image"
            name="image"
            label="Image"
            accept=".jpg, .jpeg, .png"
            onChange={handleInputChange}
            error={errors?.image || ""}
            onClick={(e) => (e.target.value = null)}
            imageProps={imageProps(
              values.image,
              values.image_preview,
              imageRemoveHandler,
              "image"
            )}
          />
          {/* 
          <Input
            className="create_from_input_content"
            labelClassName="create_from_label"
            errorClassName="err_text"
            type="file"
            id="certificate"
            name="certificate"
            labelHeading={<h2>Certificate </h2>}
            accept=".jpg, .jpeg, .png"
            onChange={handleInputChange}
            error={errors?.certificate || ""}
            onClick={(e) => (e.target.value = null)}
            imageProps={imageProps(
              values.certificate,
              values.certificate_preview,
              certificateImageRemoveHandler,
              "certificate"
            )}
          /> */}
          <Button
            className="create_from_bottom_btn col_span_2 mt-15"
            buttonClassName="comman_btn"
            onClick={createEditServiceHandler}
            text={editService ? "Edit Service" : "Create Service"}
          />
        </div>
      </div>
    </>
  );
};

export default CreateService;
