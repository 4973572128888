import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { editIcon, searchIcon, viewIcon } from "../../icons";
import {
  clearComplaint,
  getComplaintDetail,
} from "../../store/slice/complaintSlice";
import ComplaintDetailModal from "./ComplaintDetailModal";

const statusOptions = [
  // { value: "", label: "All" },
  { value: "closed", label: "Closed" },
  { value: "resolved", label: "Resolved" },
  { value: "pending", label: "Pending" },
  { value: "reopen", label: "Reopen" },
];

const ComplaintPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;

  const location = useLocation();
  const value = location?.state?.value;

  const loader = useSelector((state) => state.complaint.loader);

  const initialFValues = {
    phone_number: value?.phone_number ? value?.phone_number : "",
    status: value?.status
      ? value?.status
      : {
          value: "pending",
          label: "Pending",
        },
    // status: value?.status
    //   ? value?.status
    //   : {
    //       value: "",
    //       label: "All",
    //     },
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const [show, setShow] = useState(false);
  const [complaintId, setComplaintId] = useState(null);

  const showModal = (i) => {
    setShow(true);
    setComplaintId(i);
  };

  const hideModal = () => {
    setShow(false);
    setComplaintId(null);
    dispatch(clearComplaint());
  };

  useEffect(() => {
    dispatch(clearComplaint());
  }, []);

  const mobileUserHandler = (e) => {
    page_number !== 1 && navigate(`${routes.complaints}/page/1`);
    setValues({ ...values, phone_number: e.target.value });
    let page_num;
    if (page_number === 1) {
      page_num = 0;
    } else {
      page_num = (page_number - 1) * 2;
    }
    e.preventDefault();
    dispatch(
      getComplaintDetail({
        start: page_num,
        limit: 10,
        status: values.status.value,
        phone_number: e.target.value,
        req_type: "complaint",
      })
    );
  };

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.complaints}/page/1`);
      dispatch(clearComplaint());
      dispatch(
        getComplaintDetail({
          status: values.status.value,
          phone_number: values.phone_number,
          req_type: "complaint",
        })
      );
    }
  }, [dispatch, values.status]);

  useEffect(() => {
    navigate(`${routes.complaints}/page/${page_number}`);
    dispatch(clearComplaint());
    // if (+page_number === 1)
    dispatch(
      getComplaintDetail({
        start: (page_number - 1) * 10,
        status: values.status.value,
        phone_number: values.phone_number,
        req_type: "complaint",
      })
    );
    ref.current = true;
  }, [dispatch]);

  const complaintDetail = useSelector(
    (state) => state.complaint.complaintDetails?.data
  );

  const totalCount = useSelector(
    (state) => state.complaint.complaintDetails?.totalCount
  );

  const onPageChange = (page_number) => {
    dispatch(
      getComplaintDetail({
        status: values.status.value,
        req_type: "complaint",
        start: page_number,
        phone_number: values.phone_number,
        limit: 10,
      })
    );
  };

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content">
        <div className="user_data_search_select_box_row">
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder="Search by Mobile-number"
              id="phone_number"
              name="phone_number"
              maxLength={10}
              value={values.phone_number}
              onChange={mobileUserHandler}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div className="user_data_select">
            <Select
              value={values.status}
              onChange={(e) => handleInputChange(e, "status")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="status"
              options={statusOptions}
            />
          </div>
        </div>
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">Name</th>
                <th className="custom_data_table_heading"> Project Name</th>
                <th className="custom_data_table_heading">Tower Name</th>
                <th className="custom_data_table_heading">Subject</th>
                <th className="custom_data_table_heading">Phone Number</th>
                <th className="custom_data_table_heading">Status</th>
                <th className="custom_data_table_heading">Created At</th>
                <th className="custom_data_table_heading">Updated At</th>
                <th className="custom_data_table_heading"></th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {complaintDetail &&
                complaintDetail.map((item, i) => {
                  return (
                    <tr className="custom_data_table_row" key={i}>
                      <td className="custom_data_table_item table_item">
                        {item.user_first_name} {item.user_last_name}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.project_name}
                      </td>

                      <td className="custom_data_table_item table_item">
                        {item.tower_name}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.subject}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.phone_number}
                      </td>

                      <td className="custom_data_table_item table_item">
                        {item.status.charAt(0).toUpperCase() +
                          item.status.slice(1)}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {moment(item.created_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.updated_at
                          ? moment(item.updated_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )
                          : "-"}
                      </td>
                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <Link
                            to={`${routes.complaints}/edit/${item.complaint_id}/${page_number}`}
                            state={{ value: values }}
                            className="custom_data_table_view_edit_item_btn"
                          >
                            {editIcon}
                          </Link>
                          <Button
                            buttonClassName="custom_data_table_view_edit_item_btn"
                            onClick={(e) => showModal(item.complaint_id)}
                            text={viewIcon}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {<Pagination totalRecords={totalCount} onPageChange={onPageChange} />}
        <ComplaintDetailModal
          show={show}
          handleClose={hideModal}
          complaintId={complaintId}
        >
          <p>Modal</p>
        </ComplaintDetailModal>
      </div>
    </>
  );
};

export default ComplaintPage;
