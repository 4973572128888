import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import TextArea from "../../component/Common/TextArea";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import {
  createPrivilege,
  updatePrivilege,
} from "../../store/slice/privilegeSlice";
import { imageProps } from "../../props";
import { fileUpload } from "../../helpers/fileUpload";

const CreatePrivilegePage = ({ privilegeDetails }) => {
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let initialImagePreview;
  if (privilegeDetails?.privilege_image) {
    initialImagePreview = `${process.env.REACT_APP_FILE_BASE_URL}/${privilegeDetails?.privilege_image}`;
  }

  const initialFValues = {
    privilege_type: privilegeDetails?.privilege_type
      ? privilegeDetails?.privilege_type
      : "",

    privilege_name: privilegeDetails?.privilege_name
      ? privilegeDetails?.privilege_name
      : "",

    privilege_phone_number: privilegeDetails?.privilege_phone_number
      ? privilegeDetails?.privilege_phone_number
      : "",

    privilege_address: privilegeDetails?.privilege_address
      ? privilegeDetails?.privilege_address
      : "",

    privilege_description: privilegeDetails?.privilege_description
      ? privilegeDetails?.privilege_description
      : "",
    image: privilegeDetails?.privilege_image
      ? privilegeDetails?.privilege_image
      : "",
    image_preview: privilegeDetails?.privilege_image ? initialImagePreview : "",
  };
  const params = useParams();
  const { id, page_number } = params;
  const loader = useSelector((state) => state.privilege.loader);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("privilege_type" in fieldValues) {
      temp.privilege_type = fieldValues.privilege_type
        ? ""
        : "Privilege type is required !";
    }
    if ("privilege_name" in fieldValues) {
      temp.privilege_name = fieldValues.privilege_name
        ? ""
        : "Name is required !";
    }
    if ("privilege_address" in fieldValues) {
      temp.privilege_address = fieldValues.privilege_address
        ? ""
        : "Address is required !";
    }
    if ("privilege_phone_number" in fieldValues) {
      if (!fieldValues.privilege_phone_number) {
        temp.privilege_phone_number = "Phone number is required !";
      } else {
        temp.privilege_phone_number =
          fieldValues.privilege_phone_number.length === 10
            ? ""
            : "Phone number is not valid !";
      }
    }
    if ("privilege_description" in fieldValues) {
      temp.privilege_description = fieldValues.privilege_description
        ? ""
        : "Description is required !";
    }

    if ("image" in fieldValues) {
      temp.image = "";
      // if (!fieldValues.image) {
      //   temp.image = "Image required !";
      // }

      if (fieldValues.image.size > 5000000) {
        temp.image = "Image size should be less than 5Mb !";
        setValues({
          ...values,
          image: "",
          image_preview: "",
        });
      }
      if (
        fieldValues.image.name &&
        !fieldValues.image.name?.match(/.(jpg|jpeg|png|gif)$/i)
      ) {
        temp.image = "Please select a valid Image !";
        setValues({
          ...values,
          image: "",
          image_preview: "",
        });
      }
    }

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const createPrivilegeHandler = async (e) => {
    e.preventDefault();
    setUploading(true);
    const formData = new FormData();

    let imageUrl;
    if (values.image && typeof values.image !== "string") {
      formData.append("file", values.image);
      if (validate()) {
        imageUrl = await fileUpload(formData);
      }
      formData.delete("file");
    } else {
      imageUrl = values.image;
    }

    if (validate()) {
      const data = {
        privilege_type: values.privilege_type.trim(),
        privilege_name: values.privilege_name.trim(),
        privilege_phone_number: values.privilege_phone_number.trim(),
        privilege_address: values.privilege_address.trim(),
        privilege_description: values.privilege_description.trim(),
        privilege_image: imageUrl,
      };

      if (id) {
        dispatch(
          updatePrivilege({
            data,
            id,
            navigate,
            page_number,
          })
        );
      } else {
        dispatch(createPrivilege({ data, navigate }));
      }
    }
    setUploading(false);
  };

  const imageRemoveHandler = () => {
    setValues({ ...values, image: "", image_preview: "" });
  };

  return (
    <>
      {(loader || uploading) && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.privileges}/page/${page_number ? page_number : 1}`}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Privilege Type"
            label="Privilege Type"
            id="privilege_type"
            name="privilege_type"
            value={values.privilege_type}
            onChange={handleInputChange}
            error={errors?.privilege_type || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Name"
            label="Privilege Name"
            id="privilege_name"
            name="privilege_name"
            value={values.privilege_name}
            onChange={handleInputChange}
            error={errors?.privilege_name || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="tel"
            placeholder="Enter Phone Number"
            label="Phone Number"
            id="privilege_phone_number"
            name="privilege_phone_number"
            value={values.privilege_phone_number}
            onChange={handleInputChange}
            error={errors?.privilege_phone_number || ""}
            maxLength={10}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Address"
            label="Privilege Address"
            id="privilege_address"
            name="privilege_address"
            value={values.privilege_address}
            onChange={handleInputChange}
            error={errors?.privilege_address || ""}
          />
          <TextArea
            className="create_from_input_content"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Description"
            label="Description"
            id="privilege_description"
            name="privilege_description"
            rows={5}
            value={values.privilege_description}
            onChange={handleInputChange}
            error={errors?.privilege_description || ""}
          />

          <Input
            className="create_from_input_content"
            labelClassName="create_from_label"
            errorClassName="err_text"
            type="file"
            id="image"
            name="image"
            label="Image"
            accept=".jpg, .jpeg, .png"
            onChange={handleInputChange}
            error={errors?.image || ""}
            onClick={(e) => (e.target.value = null)}
            imageProps={imageProps(
              values.image,
              values.image_preview,
              imageRemoveHandler,
              "image"
            )}
          />

          <Button
            className="create_from_bottom_btn col_span_2"
            buttonClassName="comman_btn"
            onClick={createPrivilegeHandler}
            text={id ? "Edit Privilege" : "Create Privilege"}
          />
        </div>
      </div>
    </>
  );
};

export default CreatePrivilegePage;
