import React from "react";

const BulkConfirmationModal = ({
  projectName,
  towerName,
  handleModalClose,
  showConfirmationModal,
  createBulkPropertiesHandler,
}) => {
  return (
    <>
      <div
        className={`user_detail_modal_bg  ${
          showConfirmationModal && "user_detail_modal_show"
        }`}
        onClick={handleModalClose}
      ></div>
      <section
        className={`user_detail_modal_main user_delete_btn ${
          showConfirmationModal && "user_detail_modal_show"
        }`}
      >
        <h4 className="modal-title">Add Bulk Properties</h4>
        <div className="modal-body">
          <p>
            Are you sure you want to add Bulk Properties in {projectName} in{" "}
            {towerName} !{" "}
          </p>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleModalClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={createBulkPropertiesHandler}
          >
            Create
          </button>
        </div>
      </section>
    </>
  );
};

export default BulkConfirmationModal;
