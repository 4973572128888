import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getLeadById } from "../../store/slice/leadSlice";
import "../../css/LoginPage.css";
import { Link } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import LeadDetail from "../../component/Lead/LeadDetail";

const ViewLeadPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const loader = useSelector((state) => state.lead.loader);
  useEffect(() => {
    dispatch(getLeadById(id));
  }, [id, dispatch]);

  const leadListId = useSelector((state) => state.lead.leadId);

  return (
    <>
      {loader && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link to={routes.leads} className="comman_btn back_btn">
          Back
        </Link>
      </div>
      <LeadDetail leadListId={leadListId} />
    </>
  );
};

export default ViewLeadPage;
