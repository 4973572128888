import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import {
  createCompanyDetail,
  editCompanyDetail,
  getCompanyDetail,
} from "../../store/slice/companyDetailSlice";
import TextArea from "../../component/Common/TextArea";
import Input from "../../component/Common/Input";
import { addIcon, editIcon, removeIcon } from "../../icons";
import Button from "../../component/Common/Button";
import { benefitsTypeImageProps } from "../../props";
import { benefitsTypeHandler } from "../../helpers/commonFunction";
import {
  companyHandlerData,
  companyValidate,
  getInitialCompanyState,
} from "../../helpers/companyFunctions";

export const CompanyEditForm = () => {
  const [isCompanyDetailLoading, setIsCompanyDetailLoading] = useState(true);

  const loader = useSelector((state) => state.company.loader);

  const dispatch = useDispatch();

  const companyState = useSelector((state) => state.company.getCompanyDetail);

  useEffect(() => {
    if (companyState.length === 0) {
      dispatch(getCompanyDetail());
    } else {
      setIsCompanyDetailLoading(false);
    }
  }, [companyState, dispatch]);

  if (isCompanyDetailLoading) {
    return <div />;
  }

  return (
    <>
      {loader && <Loader />}
      <CreateCompanyPage companyStates={companyState} />
    </>
  );
};

const CreateCompanyPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { companyStates } = props;

  const loader = useSelector((state) => state.company.loader);

  let companyState = companyStates?.length > 0 ? companyStates[0] : null;

  let initialCompanyAwardPreview;
  let initialTestimonialsPreview;
  let initialCsrPreview;
  let initialCompanyImagePreview;
  let initialOurWorkPreview;
  let initialCompanyFacts;
  let initialCompanyAddress;
  let initialCompanyFollow;
  let initialCompanyPdfPreview;

  if (companyState?.awards) {
    initialCompanyAwardPreview = JSON.parse(companyState?.awards).map((a) => {
      return `${process.env.REACT_APP_FILE_BASE_URL}/${a.image}`;
    });
  }

  if (companyState?.testimonials) {
    initialTestimonialsPreview = JSON.parse(companyState?.testimonials).map(
      (a) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}/${a.image}`;
      }
    );
  }

  if (companyState?.csr) {
    initialCsrPreview = JSON.parse(companyState?.csr).csr_data.map((a) => {
      return `${process.env.REACT_APP_FILE_BASE_URL}/${a.image}`;
    });
  }

  if (companyState?.company_profile) {
    initialCompanyPdfPreview = `${process.env.REACT_APP_FILE_BASE_URL}/${companyState?.company_profile}`;
  }

  if (companyState?.company_images) {
    initialCompanyImagePreview = JSON.parse(companyState?.company_images).map(
      (a) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}/${a}`;
      }
    );
  }

  if (companyState?.our_works) {
    initialOurWorkPreview = JSON.parse(companyState?.our_works).map((a) => {
      return `${process.env.REACT_APP_FILE_BASE_URL}/${a}`;
    });
  }

  if (companyState?.facts) {
    initialCompanyFacts = JSON.parse(companyState.facts);
  }

  if (companyState?.address) {
    initialCompanyAddress = JSON.parse(companyState.address);
  }

  if (companyState?.follow_me) {
    initialCompanyFollow = JSON.parse(companyState.follow_me);
  }

  const initialFValues = useCallback(() => {
    return getInitialCompanyState(
      companyState,
      initialCompanyAwardPreview,
      initialTestimonialsPreview,
      initialCsrPreview,
      initialCompanyImagePreview,
      initialOurWorkPreview,
      initialCompanyFacts,
      initialCompanyAddress,
      initialCompanyFollow,
      initialCompanyPdfPreview
    );
  }, [
    companyState,
    initialCompanyAwardPreview,
    initialTestimonialsPreview,
    initialCsrPreview,
    initialCompanyImagePreview,
    initialOurWorkPreview,
    initialCompanyFacts,
    initialCompanyAddress,
    initialCompanyFollow,
    initialCompanyPdfPreview,
  ]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return companyValidate(temp, fieldValues, values, setValues, setErrors);
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const companyPdfRemoveHandler = () => {
    setValues({
      ...values,
      company_profile: "",
      company_profile_preview: "",
    });
  };

  const companyImageHandler = (e) => {
    values.company_images_error = "";

    const { files } = e.target;

    if (files.length > 0) {
      let images = [];
      for (const key in files) {
        if (key !== "length" && key !== "item") {
          if (!files[key].name.match(/.(jpg|jpeg|png)$/i)) {
            values.company_images_error =
              "Please add valid image (png/jpeg/jpg)";
            continue;
          }
          if (files[key].size > 50000000) {
            values.company_images_error =
              "Image size should not be greater than 50MB";
            continue;
          }
          images.push(files[key]);
        }
      }

      const temp = [...values.company_images_preview];

      for (let i = 0; i < images.length; i++) {
        temp.push(URL.createObjectURL(images[i]));
      }

      setValues({
        ...values,
        company_images_preview: [...temp],
        company_images: [...values.company_images, ...images],
      });
    }
  };

  const ourWorkHandler = (e) => {
    values.our_works_error = "";

    const { files } = e.target;

    if (files.length > 0) {
      let images = [];
      for (const key in files) {
        if (key !== "length" && key !== "item") {
          if (!files[key].name.match(/.(jpg|jpeg|png)$/i)) {
            values.our_works_error = "Please add valid image (png/jpeg/jpg)";
            continue;
          }
          if (files[key].size > 50000000) {
            values.our_works_error =
              "Image size should not be greater than 50MB";
            continue;
          }
          images.push(files[key]);
        }
      }

      const temp = [...values.our_works_preview];

      for (let i = 0; i < images.length; i++) {
        temp.push(URL.createObjectURL(images[i]));
      }

      setValues({
        ...values,
        our_works_preview: [...temp],
        our_works: [...values.our_works, ...images],
      });
    }
  };

  const imageRemoveHandler = (i) => {
    const imgArr = [...values.company_images];
    const imgArrPreview = [...values.company_images_preview];
    imgArr.splice(i, 1);
    imgArrPreview.splice(i, 1);
    setValues({
      ...values,
      company_images: imgArr,
      company_images_preview: imgArrPreview,
    });
  };

  const ourWorkRemoveHandler = (i) => {
    const imgArr = [...values.our_works];
    const imgArrPreview = [...values.our_works_preview];
    imgArr.splice(i, 1);
    imgArrPreview.splice(i, 1);
    setValues({
      ...values,
      our_works: imgArr,
      our_works_preview: imgArrPreview,
    });
  };

  const companyAwardsHandler = (e, index) => {
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.company_awards,
      previewState: values.company_awards_preview,
      error: values.company_awards_error,
      prop: "company_awards",
      previewProp: "company_awards_preview",
      errorProp: "company_awards_error",
      type: "benefitsTypeHandler",
    };
    benefitsTypeHandler(tempData);
  };

  const testimonialsHandler = (e, index) => {
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.testimonials,
      previewState: values.testimonials_preview,
      error: values.testimonials_error,
      prop: "testimonials",
      previewProp: "testimonials_preview",
      errorProp: "testimonials_error",
      type: "benefitsTypeHandler",
    };
    benefitsTypeHandler(tempData);
  };

  const descriptionChangeHandler = (e) => {
    setValues({ ...values, csr_description: e.target.value });
  };

  const csrDataHandler = (e, index) => {
    const tempData = {
      e,
      index,
      values,
      setValues,
      state: values.csr,
      previewState: values.csr_preview,
      error: values.csr_error,
      prop: "csr",
      previewProp: "csr_preview",
      errorProp: "csr_error",
      type: "benefitsTypeHandler",
    };
    benefitsTypeHandler(tempData);
  };

  const awardImageRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.company_awards,
      previewState: values.company_awards_preview,
      type: "benefitsTypeImageRemoveHandler image",
      prop: "company_awards",
      previewProp: "company_awards_preview",
    };
    benefitsTypeHandler(tempData);
  };

  const testimonialsImageRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.testimonials,
      previewState: values.testimonials_preview,
      type: "benefitsTypeImageRemoveHandler image",
      prop: "testimonials",
      previewProp: "testimonials_preview",
    };
    benefitsTypeHandler(tempData);
  };

  const csrImageRemoveHandler = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.csr,
      previewState: values.csr_preview,
      type: "benefitsTypeImageRemoveHandler image",
      prop: "csr",
      previewProp: "csr_preview",
    };
    benefitsTypeHandler(tempData);
  };

  // handle click event of the Remove button
  const companyAwardHandleRemoveClick = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.company_awards,
      previewState: values.company_awards_preview,
      prop: "company_awards",
      previewProp: "company_awards_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };

  const testimonialsHandleRemoveClick = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.testimonials,
      previewState: values.testimonials_preview,
      prop: "testimonials",
      previewProp: "testimonials_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };

  const csrDataHandleRemoveClick = (index) => {
    const tempData = {
      index,
      values,
      setValues,
      state: values.csr,
      previewState: values.csr_preview,
      prop: "csr",
      previewProp: "csr_preview",
      type: "benefitsTypeHandleRemoveClick",
    };
    benefitsTypeHandler(tempData);
  };

  // handle click event of the Add button
  const companyAwardHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.company_awards,
      prop: "company_awards",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        description: "",
        image: "",
      },
    };
    benefitsTypeHandler(tempData);
  };

  const testimonialsHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.testimonials,
      prop: "testimonials",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        description: "",
        image: "",
      },
    };
    benefitsTypeHandler(tempData);
  };

  const csrDataHandleAddClick = () => {
    const tempData = {
      values,
      setValues,
      state: values.csr,
      prop: "csr",
      type: "benefitsHandleClick",
      addObj: {
        title: "",
        image: "",
      },
    };
    benefitsTypeHandler(tempData);
  };

  const submitCompanyDataHandler = async (e) => {
    e.preventDefault();

    if (validate()) {
      setValues({ ...values, pending: true });
      const data = await companyHandlerData(values);
      setValues({ ...values, pending: false });

      if (companyState) {
        dispatch(
          editCompanyDetail({
            data,
            navigate,
          })
        );
      } else {
        dispatch(
          createCompanyDetail({
            data,
            navigate,
          })
        );
      }
    }
  };

  return (
    <>
      {(values.pending || loader) && <Loader />}
      <div className="center_back_button comman_btn_container">
        <Link to={routes.company} className="comman_btn back_btn">
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="project_edit_feature_media_row">
          <div className="project_edit_detail_column_content">
            <TextArea
              className="create_from_input_content"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Company History"
              label="Message"
              id="company_history"
              name="company_history"
              value={values.company_history}
              onChange={handleInputChange}
              error={errors?.company_history || ""}
            />
            <Input
              className="create_from_input_content"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter video url"
              label="Corporate Video"
              id="company_video"
              name="company_video"
              value={values.company_video}
              onChange={handleInputChange}
              error={errors?.company_video || ""}
            />
          </div>

          <div className="project_edit_detail_column_content amenieties_file_select_content">
            <Input
              className="project_edit_detail_column_content amenieties_file_select_content"
              labelClassName="select_amenieties_file_btn"
              errorClassName="err_text"
              type="file"
              label="Choose Pdf"
              id="company_profile"
              onClick={(e) => (e.target.value = null)}
              name="company_profile"
              onChange={handleInputChange}
              labelOtherProps={addIcon}
              labelHeading={<h2>Corporate Pdf </h2>}
              accept={"application/pdf"}
              error={errors?.company_profile || ""}
            />
            <p className="create_project_review">
              The maximum size for an upload 50 MB
            </p>
            {values.company_profile && (
              <>
                <Button
                  className=""
                  buttonClassName="remove_pdf_btn"
                  onClick={companyPdfRemoveHandler}
                  text="Remove Pdf"
                  other={removeIcon}
                />
                <iframe
                  title="ampp"
                  src={values.company_profile_preview}
                  type="application/pdf"
                  className="profile_edit_iframe"
                />
              </>
            )}
          </div>

          <div className="project_edit_detail_column_content">
            <h2>Corporate Images</h2>
            <Input
              className="project_edit_media_input_file"
              errorClassName="err_text"
              type="file"
              label="Add Company Image"
              labelOtherProps={addIcon}
              id="company_images"
              name="company_images"
              multiple={true}
              accept="image/png,  image/jpeg"
              onChange={companyImageHandler}
              onClick={(e) => (e.target.value = null)}
              imageProps={
                <div className="project_edit_media_image_section">
                  {values.company_images_preview &&
                    values.company_images_preview.map((image, i) => {
                      return (
                        <div
                          key={i}
                          className="project_edit_media_image_column "
                        >
                          <img
                            src={image}
                            width="300"
                            height="300"
                            alt="Company img"
                            key={i}
                          />
                          <button
                            className="image_gallery_remove_btn"
                            onClick={() => imageRemoveHandler(i)}
                          >
                            <span></span>
                          </button>
                        </div>
                      );
                    })}
                </div>
              }
            />
            <p className="create_project_review">
              720 x 1280 (9 : 16),The maximum size for an upload in Images is 1
              MB
            </p>
            {values.company_images_error && (
              <span className="err_text">{values.company_images_error}</span>
            )}
          </div>

          <div className="project_edit_detail_column_content">
            <h2>Our Work</h2>
            <Input
              className="project_edit_media_input_file"
              errorClassName="err_text"
              type="file"
              label=" Add Work Image"
              labelOtherProps={addIcon}
              id="our_works"
              name="our_works"
              multiple={true}
              accept="image/png,  image/jpeg"
              onChange={ourWorkHandler}
              onClick={(e) => (e.target.value = null)}
              imageProps={
                <div className="project_edit_media_image_section">
                  {values.our_works_preview &&
                    values.our_works_preview.map((image, i) => {
                      return (
                        <div
                          key={i}
                          className="project_edit_media_image_column "
                        >
                          <img
                            src={image}
                            width="300"
                            height="300"
                            alt="Work img"
                            key={i}
                          />
                          <button
                            className="image_gallery_remove_btn"
                            onClick={() => ourWorkRemoveHandler(i)}
                          >
                            <span></span>
                          </button>
                        </div>
                      );
                    })}
                </div>
              }
            />
            <p className="create_project_review">
              960 x 720 (4 : 3),The maximum size for an upload in Images is 1 MB
            </p>
            {values.our_works_error && (
              <span className="err_text">{values.our_works_error}</span>
            )}
          </div>

          <div className="project_edit_detail_column_content">
            <h2>Awards</h2>
            {values.company_awards &&
              values.company_awards.map((x, i) => {
                return (
                  <div key={i} className="project_edit_detail_divider">
                    <div className="project_edit_feature_input_file">
                      <Input
                        className="create_from_input_content"
                        labelClassName="create_from_label"
                        errorClassName="err_text"
                        type="file"
                        id={`company_award_${i}`}
                        labelOtherProps={
                          <span>
                            {!values.company_awards[i].image
                              ? addIcon
                              : editIcon}
                          </span>
                        }
                        name="image"
                        accept="image/png, image/jpeg"
                        onChange={(e) => companyAwardsHandler(e, i)}
                        onClick={(e) => (e.target.value = null)}
                        imageProps={benefitsTypeImageProps(
                          x.image,
                          values.company_awards_preview[i],
                          i,
                          awardImageRemoveHandler
                        )}
                      />
                    </div>
                    <p className="create_project_review">
                      960 x 720 (4 : 3),The maximum size for an upload is 1 MB
                    </p>
                    {values.company_awards_error &&
                      values.company_awards_error.length > 0 && (
                        <span key={i} className="err_text">
                          {values.company_awards_error[i]}
                        </span>
                      )}
                    <Input
                      className="project_edit_feature_input"
                      type="text"
                      placeholder="Award Title"
                      label="Title"
                      id="title"
                      name="title"
                      value={x.title}
                      onChange={(e) => companyAwardsHandler(e, i)}
                    />
                    <TextArea
                      className="project_edit_feature_input"
                      labelClassName="create_from_label"
                      inputClassName="create_from_input"
                      type="text"
                      placeholder="Award Description"
                      label="Description"
                      id="description"
                      name="description"
                      value={x.description}
                      onChange={(e) => companyAwardsHandler(e, i)}
                    />
                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.company_awards.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => companyAwardHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.company_awards.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => companyAwardHandleAddClick(i)}
                          text="Add"
                          other={addIcon}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="project_edit_detail_column_content">
            <h2>Testimonials</h2>
            {values.testimonials &&
              values.testimonials.map((x, i) => {
                return (
                  <div key={i} className="project_edit_detail_divider">
                    <div className="project_edit_feature_input_file">
                      <Input
                        className="create_from_input_content"
                        labelClassName="create_from_label"
                        errorClassName="err_text"
                        type="file"
                        id={`testimonials_${i}`}
                        labelOtherProps={
                          <span>
                            {!values.testimonials[i].image ? addIcon : editIcon}
                          </span>
                        }
                        name="image"
                        accept="image/png, image/jpeg"
                        onChange={(e) => testimonialsHandler(e, i)}
                        onClick={(e) => (e.target.value = null)}
                        imageProps={benefitsTypeImageProps(
                          x.image,
                          values.testimonials_preview[i],
                          i,
                          testimonialsImageRemoveHandler
                        )}
                      />
                    </div>
                    {values.testimonials_error &&
                      values.testimonials_error.length > 0 && (
                        <span key={i} className="err_text">
                          {values.testimonials_error[i]}
                        </span>
                      )}
                    <Input
                      className="project_edit_feature_input"
                      type="text"
                      placeholder="Testimonial Title"
                      label="Title"
                      id="title"
                      name="title"
                      value={x.title}
                      onChange={(e) => testimonialsHandler(e, i)}
                    />
                    <TextArea
                      className="project_edit_feature_input"
                      labelClassName="create_from_label"
                      inputClassName="create_from_input"
                      type="text"
                      placeholder="Testimonial Description"
                      label="Description"
                      id="description"
                      name="description"
                      value={x.description}
                      onChange={(e) => testimonialsHandler(e, i)}
                    />
                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.testimonials.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => testimonialsHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.testimonials.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => testimonialsHandleAddClick(i)}
                          text="Add"
                          other={addIcon}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="project_edit_detail_column_content create_from_input_content">
            <h2 className="create_from_label">CSR</h2>
            <TextArea
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter CSR title"
              label="Description"
              id="csr_description"
              name="csr_description"
              value={values.csr_description}
              onChange={descriptionChangeHandler}
            />

            {values.csr &&
              values.csr.map((x, i) => {
                return (
                  <div key={i} className="csr_data_component">
                    <div className="project_edit_feature_input_file">
                      <Input
                        className="create_from_input_content"
                        labelClassName="create_from_label"
                        errorClassName="err_text"
                        type="file"
                        id={`csr_data_${i}`}
                        labelOtherProps={
                          <span>
                            {!values.csr[i].image ? addIcon : editIcon}
                          </span>
                        }
                        name="image"
                        accept="image/png, image/jpeg"
                        onChange={(e) => csrDataHandler(e, i)}
                        onClick={(e) => (e.target.value = null)}
                        imageProps={benefitsTypeImageProps(
                          x.image,
                          values.csr_preview[i],
                          i,
                          csrImageRemoveHandler
                        )}
                      />
                    </div>
                    <p className="create_project_review">
                      960 x 720 (4 : 3),The maximum size for an upload is 1 MB
                    </p>
                    {values.csr_error && values.csr_error.length > 0 && (
                      <span key={i} className="err_text">
                        {values.csr_error[i]}
                      </span>
                    )}
                    <Input
                      className="project_edit_feature_input"
                      type="text"
                      placeholder="Csr data Title"
                      label="Title"
                      id="title"
                      name="title"
                      value={x.title}
                      onChange={(e) => csrDataHandler(e, i)}
                    />
                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.csr.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => csrDataHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.csr.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => csrDataHandleAddClick(i)}
                          text="Add"
                          other={addIcon}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="project_edit_detail_column_content">
            <h2>Facts</h2>
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="number"
              placeholder="Enter year of experience"
              label="Year Of Experience"
              id="year_of_experience"
              name="year_of_experience"
              value={values.year_of_experience}
              onChange={handleInputChange}
              error={errors?.year_of_experience || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="number"
              placeholder="Enter lakh of constructed space"
              label="Lakh Of Constructed Space"
              id="lakh_of_constructed_space"
              name="lakh_of_constructed_space"
              value={values.lakh_of_constructed_space}
              onChange={handleInputChange}
              error={errors?.lakh_of_constructed_space || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="number"
              placeholder="Enter landmarks"
              label="Landmarks"
              id="landmarks"
              name="landmarks"
              value={values.landmarks}
              onChange={handleInputChange}
              error={errors?.landmarks || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="number"
              placeholder="Enter commercial unit"
              label="Commercial Unit"
              id="commercial_unit"
              name="commercial_unit"
              value={values.commercial_unit}
              onChange={handleInputChange}
              error={errors?.commercial_unit || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="number"
              placeholder="Enter happy customers"
              label="Happy Customers"
              id="happy_customers"
              name="happy_customers"
              value={values.happy_customers}
              onChange={handleInputChange}
              error={errors?.happy_customers || ""}
            />
          </div>

          <div className="project_edit_detail_column_content">
            <h2>Address</h2>
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Number / Street / Locality area"
              label="Address 1"
              id="address_one"
              name="address_one"
              value={values.address_one}
              onChange={handleInputChange}
              error={errors?.address_one || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Pincode / City / State"
              label="Address 2"
              id="address_two"
              name="address_two"
              value={values.address_two}
              onChange={handleInputChange}
              error={errors?.address_two || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Address Latitude"
              label="Address Latitude"
              id="address_lat"
              name="address_lat"
              value={values.address_lat}
              onChange={handleInputChange}
              error={errors?.address_lat || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Address Longitude"
              label="Address Longitude"
              id="address_long"
              name="address_long"
              value={values.address_long}
              onChange={handleInputChange}
              error={errors?.address_long || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="email"
              placeholder="Email address"
              label="Email"
              id="email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              error={errors?.email || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="tel"
              maxLength={10}
              placeholder="Enter support whatsapp"
              label="Support Whatsapp"
              id="support_whatsapp"
              name="support_whatsapp"
              value={values.support_whatsapp}
              onChange={handleInputChange}
              error={errors?.support_whatsapp || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="tel"
              maxLength={10}
              placeholder="Enter support phone number"
              label="Support Call"
              id="support_call"
              name="support_call"
              value={values.support_call}
              onChange={handleInputChange}
              error={errors?.support_call || ""}
            />
          </div>

          <div className="project_edit_detail_column_content">
            <h2>Follow Me</h2>
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter facebook url"
              label="Facebook Url"
              id="facebook_url"
              name="facebook_url"
              value={values.facebook_url}
              onChange={handleInputChange}
              error={errors?.facebook_url || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter facebook Id"
              label="Facebook Id"
              id="facebook_id"
              name="facebook_id"
              value={values.facebook_id}
              onChange={handleInputChange}
              error={errors?.facebook_id || ""}
            />
            <a
              href="https://www.facebook.com/help/1503421039731588/?helpref=uf_share"
              target={"blank"}
              className="follow_me-link"
            >
              Help for find id
            </a>
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter twitter url"
              label="Twitter Url"
              id="twitter_url"
              name="twitter_url"
              value={values.twitter_url}
              onChange={handleInputChange}
              error={errors?.twitter_url || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter twitter id"
              label="Twitter Id"
              id="twitter_id"
              name="twitter_id"
              value={values.twitter_id}
              onChange={handleInputChange}
              error={errors?.twitter_id || ""}
            />
            <a
              href="https://tools.codeofaninja.com/find-twitter-id"
              target={"blank"}
              className="follow_me-link"
            >
              Help for find id
            </a>
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter youtube url"
              label="Youtube Url"
              id="youtube_url"
              name="youtube_url"
              value={values.youtube_url}
              onChange={handleInputChange}
              error={errors?.youtube_url || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter instagram url"
              label="Instagram Url"
              id="instagram_url"
              name="instagram_url"
              value={values.instagram_url}
              onChange={handleInputChange}
              error={errors?.instagram_url || ""}
            />
            <Input
              className="project_edit_feature_input"
              labelClassName="create_from_label"
              inputClassName="create_from_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter instagram id"
              label="Instagram Username"
              id="instagram_id"
              name="instagram_id"
              value={values.instagram_id}
              onChange={handleInputChange}
              error={errors?.instagram_id || ""}
            />
          </div>
        </div>

        <Button
          className="legal_doc_button"
          buttonClassName="comman_btn"
          onClick={submitCompanyDataHandler}
          text={companyState ? "Update" : "Submit"}
          other=""
        />
      </div>
    </>
  );
};

export default CreateCompanyPage;
