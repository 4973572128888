import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import "../../css/ProjectForm.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserList,
  getUserListForCSV,
  sendOtp,
} from "../../store/slice/userSlice";
import Loader from "../../component/Common/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../constants";
import UserItem from "../../component/Users/UserItem";
import { searchIcon, userIcon } from "../../icons";
import { useForm } from "../../hooks/useForm";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";
import Pagination from "../../component/Pagination/Pagination";
import { getProjectList } from "../../store/slice/projectSlice";
import DeleteModal from "./DeleteModal";
import SendOtpModal from "./SendOtpModal";
import { CSVLink } from "react-csv";
import moment from "moment";

const userTypeOptions = [
  { value: "", label: "All" },
  { value: "Customer", label: "Customer" },
  { value: "Broker", label: "Broker" },
];

const userTypeOptionsForProjects = [{ value: "Customer", label: "Customer" }];

const userStatusOptions = [
  { value: "", label: "All" },
  { value: "verified", label: "Verified" },
  { value: "unverified", label: "Unverified" },
  { value: "incomplete_profile", label: "Incomplete Profile" },
];

const userMainSubOptions = [
  { value: "all", label: "All" },
  { value: "main", label: "Main User" },
  { value: "sub_user", label: "Sub User" },
];

const mobileStatusOptions = [
  { value: "", label: "All" },
  { value: "1", label: "App user" },
  { value: "0", label: "Not app user" },
];

const UsersListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;
  const location = useLocation();
  const { state } = location;

  const csvInstance = useRef();

  const [csv, setCsv] = useState(false);

  useEffect(() => {
    if (csv && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsv(false);
      });
    }
  }, [csv]);

  const initialFValues = {
    project_id: state?.value?.project_id
      ? state?.value?.project_id
      : { value: "All Projects", label: "All Projects", id: "" },
    mobileNum: state?.value?.mobileNum ? state?.value?.mobileNum : "",
    mobileStatus: state?.value?.mobileStatus ? state?.value?.mobileStatus : "",
    userStatus: state?.value?.userStatus ? state?.value?.userStatus : "",
    userMainSub: state?.value?.userMainSub ? state?.value?.userMainSub : "ALL",
    userType: state?.value?.userType
      ? state?.value?.userType
      : state?.user_type
      ? { label: state?.user_type, value: state?.user_type }
      : "",
    showDeleteModal: false,
    sendOtpForDelete: false,
    userId: "",
    phoneNumber: "",
    otp: "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const userList = useSelector((state) => state.user.users?.data);
  const totalUser = useSelector((state) => state.user.users?.totalCount);
  const filteredusers = userList?.filter(
    (item, i) => item.user_type !== "ADMIN"
  );
  const totalCount = useSelector((state) => state.user.users?.totalCount);
  const loader = useSelector((state) => state.user.loader);

  const mobileUserHandler = (e) => {
    page_number !== 1 && navigate(`${routes.users}/page/1`);
    setValues({ ...values, mobileNum: e.target.value });
    let page_num;
    if (page_number === 1) {
      page_num = 0;
    } else {
      page_num = (page_number - 1) * 2;
    }
    dispatch(
      getUserList({
        projectId: values.project_id?.id,
        userType: values.userType.value,
        mobileNum: e.target.value,
        userStatus: values.userStatus.value,
        mobileStatus: values.mobileStatus.value,
        user_finder: values.userMainSub.value,
        start: page_num,
        limit: 10,
      })
    );
  };
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.users}/page/1`);
      dispatch(
        getUserList({
          projectId:
            values.userStatus.value === "unverified"
              ? ""
              : values.project_id?.id,
          userType: values.project_id?.id ? "Customer" : values.userType.value,
          mobileNum: values.mobileNum,
          userStatus: values.userStatus.value,
          mobileStatus: values.mobileStatus.value,
          user_finder: values.userMainSub.value,
        })
      );
    }
  }, [
    dispatch,
    values.project_id,
    values.userType,
    values.mobileNum,
    values.userStatus,
    values.mobileStatus,
    values.userMainSub,
  ]);

  useEffect(() => {
    if (!state?.pageNum) {
      navigate(`${routes.users}/page/1`);
      if (+page_number === 1)
        dispatch(
          getUserList({
            projectId:
              values.userStatus.value === "unverified"
                ? ""
                : values.project_id?.id,
            userType: values.project_id?.id
              ? "Customer"
              : values.userType.value,
            mobileNum: values.mobileNum,
            userStatus: values.userStatus.value,
            mobileStatus: values.mobileStatus.value,
            user_finder: values.userMainSub.value,
          })
        );
    } else {
      ref.current = true;
    }
  }, [
    dispatch,
    values.project_id,
    values.userType,
    values.mobileNum,
    values.userStatus,
    values.mobileStatus,
    values.userMainSub,
  ]);

  useEffect(() => {
    dispatch(getProjectList({ admin: "true" }));
  }, [dispatch]);

  const projectList = useSelector((state) => state.project.projects);

  const projectIdOptions = projectList?.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const projectOptions = projectIdOptions && [
    { value: "All Projects", label: "All Projects", id: "" },
    ...projectIdOptions,
  ];

  const onClickHandle = (id, userType, value, status) => {
    navigate(`${routes.users}/detail/${id}/${page_number}`, {
      state: { userType, value, status },
    });
  };

  const onPageChange = (page_number) => {
    dispatch(
      getUserList({
        start: page_number,
        userType: values.userType.value,
        mobileNum: values.mobileNum,
        userStatus: values.userStatus.value,
        mobileStatus: values.mobileStatus.value,
        user_finder: values.userMainSub.value,
        limit: 10,
        projectId:
          values.userStatus.value === "unverified" ? "" : values.project_id?.id,
      })
    );
  };

  const deleteUserModal = (id, phone) => {
    setValues({
      ...values,
      showDeleteModal: true,
      phoneNumber: phone,
      userId: id,
    });
  };

  const handleDeleteClose = () => {
    setValues({ ...values, showDeleteModal: false });
  };

  const sendOtpModal = () => {
    const data = {
      del_req: "1",
      phone_number: values.phoneNumber,
    };
    dispatch(sendOtp(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setValues({
          ...values,
          sendOtpForDelete: true,
          showDeleteModal: false,
        });
      }
    });
  };

  const deleteOtpModalClose = () => {
    setValues({ ...values, sendOtpForDelete: false, otp: "" });
  };

  const userDownloadHandler = () => {
    dispatch(
      getUserListForCSV({
        userType: values.userType.value,
        mobileNum: values.mobileNum,
        userStatus: values.userStatus.value,
        mobileStatus: values.mobileStatus.value,
        projectId: values.project_id?.id,
      })
    ).then((res) => {
      setCsv([
        [
          "First Name",
          "Last Name",
          "Phone number",
          "Address",
          "Birth date",
          "Email",
          "User type",
          "User status",
          "Wallet balance",
          "Created At",
        ],
        ...res.payload?.data?.map((item) => {
          return [
            item.first_name,
            item.last_name,
            item.phone_number,
            item.address,
            item.birth_date &&
              moment(item.birth_date).format("DD/MM/YYYY, kk:mm:ss"),
            item.email,
            item.user_type,
            item.user_status,
            item.wallet_balance,
            item.created_at &&
              moment(item.created_at).format("DD/MM/YYYY, kk:mm:ss"),
          ];
        }),
      ]);
    });
  };

  // console.log("values", values);

  return (
    <>
      {/* {loader && <Loader />} */}
      <div className="project_edit_main_content">
        <div className="user_data_search_select_box_row">
          <span className="user_list-totalnumber">
            {userIcon} : {totalUser}
          </span>
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="tel"
              placeholder="Search by Mobile-number"
              id="mobileNum"
              name="mobileNum"
              maxLength={10}
              value={values.mobileNum}
              onChange={mobileUserHandler}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div
            className="user_data_search"
            // style={{
            //   zIndex: "99999",
            //   position: "relative",
            // }}
          >
            <Select
              isDisabled={values.userStatus.value === "unverified"}
              placeholder="Projects..."
              value={
                values.userStatus.value === "unverified"
                  ? { value: "All Projects", label: "All Projects", id: "" }
                  : values.project_id
              }
              onChange={(e) => handleInputChange(e, "project_id")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="project_id"
              id="project_id"
              options={projectOptions}
            />
          </div>
          <div
            className="user_data_search"
            style={{
              zIndex: "9999",
              position: "relative",
            }}
          >
            <Select
              placeholder="App Status..."
              value={values.mobileStatus}
              onChange={(e) => handleInputChange(e, "mobileStatus")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="mobileStatus"
              options={mobileStatusOptions}
            />
          </div>
          <div
            className="user_data_search"
            style={{
              zIndex: "999",
              position: "relative",
            }}
          >
            <Select
              placeholder="User type..."
              value={
                values.project_id?.id
                  ? { label: "Customer", value: "Customer" }
                  : values.userType
              }
              onChange={(e) => handleInputChange(e, "userType")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="userType"
              options={
                values?.project_id?.id
                  ? userTypeOptionsForProjects
                  : userTypeOptions
              }
            />
          </div>
          <div
            className="user_data_search"
            style={{
              zIndex: "99",
              position: "relative",
            }}
          >
            <Select
              placeholder="User status..."
              value={values.userStatus}
              onChange={(e) => handleInputChange(e, "userStatus")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="userStatus"
              options={userStatusOptions}
            />
          </div>
          <div
            className="user_data_search"
            style={{
              zIndex: "9",
              position: "relative",
            }}
          >
            <Select
              placeholder="Property Access As"
              value={values.userMainSub}
              onChange={(e) => handleInputChange(e, "userMainSub")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="userMainSub"
              options={userMainSubOptions}
            />
          </div>
        </div>
        <div className="creat_edit_project_btn_row">
          <button
            className="download_btn comman_btn"
            onClick={(e) => userDownloadHandler()}
          >
            Export User CSV
          </button>
          {csv.length > 0 ? (
            <CSVLink
              className="comman_btn"
              data={csv}
              filename="users.csv"
              ref={csvInstance}
            >
              Export User CSV
            </CSVLink>
          ) : undefined}
        </div>
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">First Name</th>
                <th className="custom_data_table_heading">Last Name</th>
                <th className="custom_data_table_heading">User Type</th>
                <th className="custom_data_table_heading">User Status</th>
                <th className="custom_data_table_heading">
                  UnVerified properties
                </th>
                {values.userStatus?.value === "unverified" && (
                  <th className="custom_data_table_heading">Project</th>
                )}
                {values.userStatus?.value === "unverified" && (
                  <th className="custom_data_table_heading">Tower</th>
                )}
                {values.userStatus?.value === "unverified" && (
                  <th className="custom_data_table_heading">Flat</th>
                )}
                <th className="custom_data_table_heading">Phone Number</th>
                {/* <th className="custom_data_table_heading">Address</th> */}
                <th className="custom_data_table_heading">Created At</th>
                <th className="custom_data_table_heading">Updated At</th>
                <th className="custom_data_table_heading"></th>
                <th className="custom_data_table_heading"></th>
                <th className="custom_data_table_heading"></th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {filteredusers &&
                filteredusers.map((item, i) => {
                  return (
                    <UserItem
                      key={i}
                      item={item}
                      i={i}
                      onClickHandle={onClickHandle}
                      deleteUserModal={deleteUserModal}
                      values={{
                        project_id: values.project_id,
                        mobileNum: values.mobileNum,
                        mobileStatus: values.mobileStatus,
                        userStatus: values.userStatus,
                        userType: values.userType,
                        userMainSub: values.userMainSub,
                      }}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
        {<Pagination totalRecords={totalCount} onPageChange={onPageChange} />}
        <DeleteModal
          values={values}
          handleDeleteClose={handleDeleteClose}
          sendOtpModal={sendOtpModal}
        />
        <SendOtpModal
          values={values}
          setValues={setValues}
          deleteOtpModalClose={deleteOtpModalClose}
        />
      </div>
    </>
  );
};

export default UsersListPage;
