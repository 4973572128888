import React from "react";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../constants";
import { deleteIcon, editIcon, viewIcon } from "../../icons";

const PrivilegeItem = ({
  item,
  handlePrivilageDelete,
  handlePrivilageView,
}) => {
  const { page_number } = useParams();
  return (
    <div
      className={`customer_properties_column ${item.user_type}`}
      key={item.id}
    >
      <div className="customer_properties_user_type_name">
        <span className="customer_properties_user_type">
          {item.privilege_type}
        </span>
        <p className="customer_properties_user_name">{item.privilege_name}</p>
      </div>
      <div className="customer_properties_other_details">
        <span className="customer_properties_other_details_label">
          Phone Number
        </span>
        <p className="customer_properties_other_details_text">
          {item.privilege_phone_number}
        </p>
      </div>
      <div className="customer_properties_other_details">
        <span className="customer_properties_other_details_label">Address</span>
        <p className="customer_properties_other_details_text">
          {item.privilege_address}
        </p>
      </div>
      <div className="customer_properties_other_details customer_properties_discription_details">
        <span className="customer_properties_other_details_label">
          Description
        </span>
        <p className="customer_properties_other_details_text">
          {item.privilege_description}
        </p>
      </div>
      <div className="project_list_card_bottom_btn_row project_edit_link">
        <Link
          to={`${routes.privileges}/edit/${item.id}/${page_number}`}
          className="project_list_card_view_edit_btn link_red"
        >
          {editIcon}
          Edit
        </Link>

        <button
          className="project_list_card_view_edit_btn link_red privilege_extra_class"
          onClick={() => handlePrivilageView(item)}
        >
          {viewIcon}
          View
        </button>

        <div className="custom_data_table_view_edit_btn_item_row flex-end">
          <button
            className="custom_data_table_view_edit_item_btn"
            onClick={() => handlePrivilageDelete(item.id)}
          >
            {deleteIcon}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivilegeItem;
