import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import getRoutes from "./routes";
import "./App.css";
import "./css/style.css";
import { checkAuth } from "./helpers/checkAuth";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  // console.log("user", user);
  user && checkAuth(dispatch);

  const routing = useRoutes(getRoutes(user));

  return <>{routing}</>;
}

export default App;
