import React, { useEffect, useRef, useCallback } from "react";
import "../../css/LoginPage.css";
import Select from "react-select";
import "../../css/ProjectForm.css";
import { useDispatch, useSelector } from "react-redux";
import { getProjectList } from "../../store/slice/projectSlice";
import { getUserList } from "../../store/slice/userSlice";
import { createLead, updateLeadList } from "../../store/slice/leadSlice";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { Link } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import Input from "../../component/Common/Input";
import TextArea from "../../component/Common/TextArea";
import { useForm } from "../../hooks/useForm";
import Button from "../../component/Common/Button";
import { getInitialLeadState, leadValidate } from "../../helpers/Lead";
import { toast } from "react-toastify";

const CreateLeadPage = ({ leadDetails, locationValues }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id, page_number } = params;
  const first = useRef(false);

  const initialFValues = useCallback(() => {
    return getInitialLeadState(leadDetails);
  }, [leadDetails]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return leadValidate(temp, fieldValues, values, setErrors);
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    if (first.current) {
      setValues({
        ...values,
        referred_by: {
          value: "",
          label: "",
          id: "",
        },
      });
    }
  }, [values.user_type]);

  useEffect(() => {
    dispatch(getProjectList({ admin: "false" }));
  }, [dispatch]);

  const projectList = useSelector((state) => state.project.projects);
  const loader = useSelector((state) => state.lead.loader);
  const projectIdOptions = projectList.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const userList = useSelector((state) => state.user.users?.data);

  const referredOptions = userList?.map((item) => {
    return {
      value: item.first_name,
      label: item.first_name + " " + item.last_name,
      phone_number: item.phone_number,
      id: item.id,
    };
  });

  useEffect(() => {
    dispatch(
      getUserList({
        userType: values.user_type,
        mobileNum: "",
        userStatus: "verified",
        limit: 100000,
      })
    );
  }, [dispatch, values.user_type]);

  const leadStatusContent = () => {
    if (leadDetails?.status === "submitted") {
      return (
        <div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Submitted at </span>
            <span>
              {moment(leadDetails.created_at).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
          </div>
          <div className="create_from_input_content lead_status_rejected_confirmed">
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Confirmed"
              id="confirmed"
              value="confirmed"
              name="lead_status_sub"
              onChange={handleInputChange}
              checked={values.lead_status_sub === "confirmed"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Rejected"
              id="rejected"
              value="rejected"
              name="lead_status_sub"
              onChange={handleInputChange}
              checked={values.lead_status_sub === "rejected"}
            />
          </div>
        </div>
      );
    }
    if (leadDetails?.status === "rejected") {
      return (
        <div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Submitted at </span>
            <span>
              {moment(leadDetails.created_at).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
          </div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Rejected at </span>
            <span>
              {moment(leadDetails.updated_at).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
          </div>
        </div>
      );
    }
    if (leadDetails?.status === "confirmed") {
      return (
        <div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Submitted at </span>
            <span>
              {moment(leadDetails.created_at).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
          </div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Confirmed at </span>
            <span>
              {moment(leadDetails.confirmed_at).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </span>
          </div>
          <div className="create_from_input_content">
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="checkbox"
              label="Site Visited"
              id="visited"
              name="visited"
              onChange={(e) => handleInputChange(e, false, true)}
              checked={values.visited}
            />
          </div>
        </div>
      );
    }
    if (leadDetails?.status === "visited") {
      return (
        <div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Submitted at </span>
            <span>
              {moment(leadDetails.created_at).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
          </div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Confirmed at </span>
            <span>
              {moment(leadDetails.confirmed_at).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </span>
          </div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Visited at </span>
            <span>
              {moment(leadDetails.site_visited_at).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </span>
          </div>
          <div className="create_from_input_content">
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="checkbox"
              label="Converted"
              id="converted"
              name="converted"
              onChange={(e) => handleInputChange(e, false, true)}
              checked={values.converted}
            />
          </div>
        </div>
      );
    }
    if (leadDetails?.status === "converted") {
      return (
        <div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Submitted at </span>
            <span>
              {moment(leadDetails.created_at).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
          </div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Confirmed at </span>
            <span>
              {moment(leadDetails.confirmed_at).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </span>
          </div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Visited at </span>
            <span>
              {moment(leadDetails.site_visited_at).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </span>
          </div>
          <div className="lead_date">
            <p>Status : </p>
            <span>Converted at </span>
            <span>
              {moment(leadDetails.converted_at).format(
                "MMMM Do YYYY, h:mm:ss a"
              )}
            </span>
          </div>
        </div>
      );
    }
  };

  const createLeadHandler = (e) => {
    e.preventDefault();

    validate();
    if (validate()) {
      const data = {
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        lead_email: values.lead_email.trim(),
        phone_number: values.phone_number.trim(),
        project_id: values.project_id.id,
        project_name: values.project_id.value,
        remarks: values.remarks.trim(),
        referred_by: values.referred_by.id,
        referred_by_name: values.referred_by.label,
        referred_by_phone_number: values.referred_by.phone_number,
      };
      const updateData = {
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        lead_email: values.lead_email.trim(),
        phone_number: values.phone_number.trim(),
        project_id: values.project_id.id,
        remarks: values.remarks.trim(),
        referred_by: values.referred_by.id,
        // updated_at: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      };

      if (
        (!leadDetails?.rewards_points && id) ||
        (!leadDetails?.rewards_points_broker && id)
      ) {
        toast.error("Please Add Project Rewards Points", {
          autoClose: 2000,
        });
        return;
      }
      if (
        leadDetails?.status === "submitted" &&
        values.lead_status_sub === "confirmed"
      ) {
        updateData.status = "confirmed";
        // updateData.confirmed_at = moment(Date.now()).format(
        //   "YYYY-MM-DD HH:mm:ss"
        // );
        if (leadDetails?.user_type !== "BROKER") {
          updateData.reward_points = JSON.parse(
            leadDetails?.rewards_points
          ).lead_confirmation;
        } else {
          updateData.reward_points = JSON.parse(
            leadDetails?.rewards_points_broker
          )?.lead_confirmation;
        }
      }
      if (
        leadDetails?.status === "submitted" &&
        values.lead_status_sub === "rejected"
      ) {
        updateData.status = "rejected";
        // updateData.confirmed_at = moment(Date.now()).format(
        //   "YYYY-MM-DD HH:mm:ss"
        // );
        updateData.reward_points = 0;
      }
      if (leadDetails?.status === "confirmed" && values.visited) {
        updateData.status = "visited";
        // updateData.site_visited_at = moment(Date.now()).format(
        //   "YYYY-MM-DD HH:mm:ss"
        // );
        if (leadDetails?.user_type !== "BROKER") {
          updateData.reward_points = JSON.parse(
            leadDetails?.rewards_points
          ).site_visit;
        } else {
          updateData.reward_points = JSON.parse(
            leadDetails?.rewards_points_broker
          ).site_visit;
        }
        // updateData.reward_points = JSON.parse(
        //   leadDetails?.rewards_points
        // ).site_visit;
      }
      if (leadDetails?.status === "visited" && values.converted) {
        updateData.status = "converted";
        // updateData.converted_at = moment(Date.now()).format(
        //   "YYYY-MM-DD HH:mm:ss"
        // );
        if (leadDetails?.user_type !== "BROKER") {
          updateData.reward_points = JSON.parse(
            leadDetails?.rewards_points
          ).lead_conversion;
        } else {
          updateData.reward_points = JSON.parse(
            leadDetails?.rewards_points_broker
          ).lead_conversion;
        }
        // updateData.reward_points = JSON.parse(
        //   leadDetails?.rewards_points
        // ).lead_conversion;
      }

      if (id) {
        dispatch(
          updateLeadList({
            data: updateData,
            id,
            navigate,
            page_number,
            locationValues,
          })
        );
      } else {
        dispatch(createLead({ data, navigate }));
      }
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.leads}/page/${page_number || 1}`}
          state={{ locationValues }}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Lead First Name"
            label="First Name"
            id="first_name"
            name="first_name"
            value={values.first_name}
            onChange={handleInputChange}
            error={errors?.first_name || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Lead Last Name"
            label="Last Name"
            id="last_name"
            name="last_name"
            value={values.last_name}
            onChange={handleInputChange}
            error={errors?.last_name || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Lead Email"
            label="Email"
            id="lead_email"
            name="lead_email"
            value={values.lead_email}
            onChange={handleInputChange}
            error={errors?.lead_email || ""}
          />
          <Input
            className="create_from_input_content null"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="tel"
            placeholder="Enter Mobile Number"
            label="Mobile Number"
            id="phone_number"
            name="phone_number"
            disabled={
              leadDetails?.status === "confirmed" ||
              leadDetails?.status === "rejected" ||
              leadDetails?.status === "visited" ||
              leadDetails?.status === "converted"
            }
            maxLength={10}
            value={values.phone_number}
            onChange={handleInputChange}
            error={errors?.phone_number || ""}
          />
          <div className="create_from_input_content">
            <label className="create_from_label">Project </label>
            <Select
              value={values.project_id}
              isDisabled={
                leadDetails?.status === "confirmed" ||
                leadDetails?.status === "rejected" ||
                leadDetails?.status === "visited" ||
                leadDetails?.status === "converted"
              }
              className="basic-multi-select lead_project"
              classNamePrefix="select"
              name="project_id"
              options={projectIdOptions}
              onChange={(e) => {
                handleInputChange(e, "project_id");
              }}
            />
            <span className="err_text"> {errors?.project_id || ""}</span>
          </div>

          <div className="create_from_input_content">
            <label className="create_from_label">Referred By </label>
            <div className="create_from_input_content create_lead_referred">
              <Input
                className="create_from_radio_checkbox_content"
                disabled={
                  leadDetails?.status === "confirmed" ||
                  leadDetails?.status === "rejected" ||
                  leadDetails?.status === "visited" ||
                  leadDetails?.status === "converted"
                }
                labelClassName="create_from_radio_label"
                inputClassName="create_from_radio_checkbox"
                errorClassName="err_text"
                type="radio"
                label="Customer"
                id="CUSTOMER"
                name="user_type"
                value="CUSTOMER"
                onChange={(e) => {
                  first.current = true;
                  handleInputChange(e);
                }}
                checked={values.user_type === "CUSTOMER"}
              />
              <Input
                disabled={
                  leadDetails?.status === "confirmed" ||
                  leadDetails?.status === "rejected" ||
                  leadDetails?.status === "visited" ||
                  leadDetails?.status === "converted"
                }
                className="create_from_radio_checkbox_content"
                labelClassName="create_from_radio_label"
                inputClassName="create_from_radio_checkbox"
                errorClassName="err_text"
                type="radio"
                label="Broker"
                id="BROKER"
                name="user_type"
                value="BROKER"
                onChange={(e) => {
                  first.current = true;
                  handleInputChange(e);
                }}
                checked={values.user_type === "BROKER"}
              />
            </div>
            <Select
              isDisabled={
                leadDetails?.status === "confirmed" ||
                leadDetails?.status === "rejected" ||
                leadDetails?.status === "visited" ||
                leadDetails?.status === "converted"
              }
              value={values.referred_by}
              onChange={(e) => handleInputChange(e, "referred_by")}
              className="basic-multi-select lead_referred"
              classNamePrefix="select"
              name="referred_by"
              options={referredOptions}
              required
            />
            <span className="err_text"> {errors?.referred_by || ""}</span>
          </div>
          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Remarks"
            label="Remarks"
            id="remarks"
            name="remarks"
            value={values.remarks}
            onChange={handleInputChange}
            error={errors?.remarks || ""}
          />

          {id && leadStatusContent()}

          <Button
            className="create_from_bottom_btn col_span_2"
            buttonClassName="comman_btn"
            onClick={createLeadHandler}
            text={id ? "Edit Lead" : "Create Lead"}
          />
        </div>
      </div>
    </>
  );
};

export default CreateLeadPage;
