import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  clearService,
  getServiceList,
  updateService,
} from "../../store/slice/serviceSlice";
import ServiceList from "./ServiceList";
import Loader from "../../component/Common/Loader";
import DeleteModal from "../Privilege/DeleteModal";
import { useForm } from "../../hooks/useForm";
import Pagination from "../../component/Pagination/Pagination";
import ViewServiceModal from "./ViewServiceModal";
import { toast } from "react-toastify";

const Service = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { page_number } = params;

  const [show, setShow] = useState(false);
  const [viewModalData, setViewModalData] = useState("");

  const serviceContactList = useSelector(
    (state) => state?.service?.serviceList?.data || []
  );
  const isLoading = useSelector((state) => state?.service?.loader);
  const totalCount = useSelector(
    (state) => state?.service?.serviceList?.totalCount
  );

  useEffect(() => {
    dispatch(getServiceList({}));
  }, []);

  const initialFValues = {
    showDeleteModal: false,
  };

  const { values, setValues } = useForm(initialFValues, true);

  // ON PAGE CHANGE
  // const onPageChange = (page_number) => {
  //   dispatch(getServiceList({ start: page_number, limit: 10 }));
  // };

  //OPEN DELETE MODAL
  const handleServiceDelete = (id) => {
    setValues({
      module: "Service",
      showDeleteModal: true,
      itemId: id,
    });
  };

  //CLOSE DELETE MODAL
  const handleDeleteClose = () => {
    setValues({ showDeleteModal: false });
  };

  //DELETE SERVICE HANDLER
  const deleteServiceHandler = (id) => {
    setValues({ showDeleteModal: false });
    const data = {
      delete_status: "1",
    };

    dispatch(
      updateService({
        data,
        id,
        page_number,
        cb: (err, res) => {
          if (err) {
            // console.log("err", err);
          } else {
            toast.error("Service deleted successfully", { autoClose: 3000 });
            dispatch(getServiceList({ start: (page_number - 1) * 10 }));
          }
        },
      })
    );
  };

  //OPEN VIEW MODAL
  const handleServiceView = (item) => {
    setViewModalData(item);
    setShow(true);
  };

  //CLOSE VIEW MODAL
  const hideModal = () => {
    setShow(false);
  };

  return (
    <div className="project_edit_main_content">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="creat_edit_project_btn_row">
            <Link to={routes.createService} className="comman_btn ml-auto">
              Create Service
            </Link>
          </div>

          {serviceContactList?.length === 0 || !serviceContactList ? (
            <p className="no_data_found">No data Found</p>
          ) : (
            <div className="col_lg_3 d_grid gap_15">
              <ServiceList
                list={serviceContactList}
                handleServiceDelete={handleServiceDelete}
                handleServiceView={handleServiceView}
              />
            </div>
          )}
          {/* <Pagination totalRecords={totalCount} onPageChange={onPageChange} /> */}
        </>
      )}
      <DeleteModal
        values={values}
        handleDeleteClose={handleDeleteClose}
        handleDeleteConfirm={deleteServiceHandler}
      />
      <ViewServiceModal
        show={show}
        handleClose={hideModal}
        viewModalData={viewModalData}
      />
    </div>
  );
};

export default Service;
