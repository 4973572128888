import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "../../component/Common/Button";
import Loader from "../../component/Common/Loader";
import NoticeItem from "../../component/Notification/NotificationItem";
import { routes } from "../../constants";
import { viewIcon } from "../../icons";
import { clearNotice, getNotice } from "../../store/slice/noticeSlice";
import moment from "moment";
import NotificationdetailModal from "./NotificationdetailModal";
import Pagination from "../../component/Pagination/Pagination";

const NoticePage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { page_number } = params;
  const loader = useSelector((state) => state.notifications.loader);

  useEffect(() => {
    // dispatch(clearNotice());
    if (+page_number === 1) dispatch(getNotice({}));
  }, [dispatch]);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState("");
  const [subject, setSubject] = useState("");

  const showModal = (message, attachment, subject) => {
    setShow(true);
    setMessage(message);
    setAttachment(attachment);
    setSubject(subject);
  };

  const hideModal = () => {
    setShow(false);
  };

  const noticeList = useSelector(
    (state) => state.notifications.notifications?.data
  );

  const totalCount = useSelector(
    (state) => state.notifications.notifications?.totalCount
  );

  const onPageChange = (page_number) => {
    dispatch(
      getNotice({
        start: page_number,
        limit: 10,
      })
    );
  };

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content">
        <div className="creat_edit_project_btn_row">
          <Link to={routes.createNotice} className="comman_btn ml-auto">
            Create Notification
          </Link>
        </div>
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">Subject</th>
                <th className="custom_data_table_heading">Notification For</th>
                <th className="custom_data_table_heading">Created At</th>
                <th className="custom_data_table_heading"></th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {noticeList &&
                noticeList.map((item, i) => {
                  return (
                    <tr className="custom_data_table_row" key={i}>
                      <td className="custom_data_table_item table_item">
                        {item.subject}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {item.notification_for}
                      </td>
                      <td className="custom_data_table_item table_item">
                        {moment(item.created_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <Button
                            buttonClassName="custom_data_table_view_edit_item_btn"
                            onClick={(e) =>
                              showModal(
                                item.message,
                                item.attachment,
                                item.subject
                              )
                            }
                            text={viewIcon}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Pagination totalRecords={totalCount} onPageChange={onPageChange} />
        </div>
        <NotificationdetailModal
          show={show}
          handleClose={hideModal}
          message={message}
          attachment={attachment}
          subject={subject}
        >
          <p>Modal</p>
        </NotificationdetailModal>
      </div>
    </>
  );
};

export default NoticePage;
