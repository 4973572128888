import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getContact } from "../../../store/slice/contactSlice";
import { updateProjectList } from "../../../store/slice/projectSlice";
import Loader from "../../Common/Loader";
import Button from "../../Common/Button";
import { useForm } from "../../../hooks/useForm";
import Input from "../../Common/Input";
import Pagination from "../../Pagination/PaginationWithoutUrl";
import ContactFormModal from "./ContactFormModal";
import { viewIcon } from "../../../icons";

const ContactForm = ({ projectDetails }) => {
  const contactList = useSelector((state) => state.contact.contactList.data);
  const totalCounts = useSelector(
    (state) => state.contact.contactList.totalCount
  );

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState("");

  const initialFValues = {
    contactsListCheck: projectDetails?.contacts
      ? JSON.parse(projectDetails?.contacts)
      : [],
  };

  const { values, setValues } = useForm(initialFValues, true);

  const dispatch = useDispatch();

  const params = useParams();
  const { id } = params;

  const loader = useSelector((state) => state.project.loader);

  const onChangeContactsHandler = (id) => {
    let tempContactsHandler = [...values.contactsListCheck];
    if (tempContactsHandler.includes(id)) {
      let index = tempContactsHandler.findIndex((obj) => obj === id);
      if (index > -1) {
        tempContactsHandler.splice(index, 1);
      }
      setValues({ ...values, contactsListCheck: tempContactsHandler });
    } else {
      setValues({
        ...values,
        contactsListCheck: [...values.contactsListCheck, id],
      });
    }
  };

  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    dispatch(getContact({}));
  }, [dispatch]);

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(getContact({ start: page }));
  };

  const saveButtonHandler = (e) => {
    e.preventDefault();
    const data = {
      contacts: values.contactsListCheck,
    };
    dispatch(updateProjectList({ data, id }));
  };

  const hideModal = () => {
    setShow(false);
    setModalData("");
  };

  const viewIconHandler = (item) => {
    setShow(true);
    setModalData(item);
  };
  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content center_table_body">
        <div className="custom_data_table_content">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                <th className="custom_data_table_heading">Select</th>
                <th className="custom_data_table_heading">Contact Name</th>
                <th className="custom_data_table_heading">Service Name</th>
                <th className="custom_data_table_heading">Contact Number</th>
                <th className="custom_data_table_heading"></th>
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {contactList &&
                contactList.map((item, i) => {
                  return (
                    <tr className="custom_data_table_row" key={i}>
                      <td
                        className="custom_data_table_item"
                        style={{ textAlign: "center" }}
                      >
                        <Input
                          inputClassName="create_from_radio_checkbox contacts_check"
                          type="checkbox"
                          id="confirmed"
                          name="confirmed"
                          onChange={() => onChangeContactsHandler(item.id)}
                          checked={values.contactsListCheck.includes(item.id)}
                        />
                      </td>
                      <td
                        className="custom_data_table_item"
                        style={{ textAlign: "center" }}
                      >
                        {item.contact_name}
                      </td>
                      <td
                        className="custom_data_table_item"
                        style={{ textAlign: "center" }}
                      >
                        {item.service_name}
                      </td>
                      <td
                        className="custom_data_table_item"
                        style={{ textAlign: "center" }}
                      >
                        {item.phone_number}
                      </td>
                      <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                        <div className="custom_data_table_view_edit_btn_item_row">
                          <Button
                            buttonClassName="custom_data_table_view_edit_item_btn"
                            onClick={(e) => viewIconHandler(item)}
                            text={viewIcon}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {
          <Pagination
            totalRecords={totalCounts}
            onPageChange={onPageChange}
            startValue={pageNum}
          />
        }
        <Button
          className="project_submit_bottom_btn"
          buttonClassName="comman_btn"
          onClick={saveButtonHandler}
          text="Save"
        />
        <ContactFormModal
          handleClose={hideModal}
          show={show}
          modalData={modalData}
        >
          <p>Modal</p>
        </ContactFormModal>
      </div>
    </>
  );
};

export default ContactForm;
