import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Button from "../../component/Common/Button";
import Input from "../../component/Common/Input";
import Loader from "../../component/Common/Loader";
import TextArea from "../../component/Common/TextArea";
import { routes } from "../../constants";
import { useForm } from "../../hooks/useForm";
import {
  clearInquiry,
  getInquiryDetailById,
  updateInquiryList,
} from "../../store/slice/inquirySlice";

const EditInquiryPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id, page_number } = params;

  const location = useLocation();
  const { value } = location?.state;

  const inquiry = useSelector((state) => state.inquiry.inquiryDetailId);
  const loader = useSelector((state) => state.inquiry.loader);

  const initialFValues = {
    pending: false,
    status: inquiry?.status ? inquiry?.status : "received",
    remarks: inquiry?.remarks ? inquiry?.remarks : "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  useEffect(() => {
    if (inquiry) {
      dispatch(clearInquiry());
      setValues({
        ...values,
        status: inquiry.status,
        remarks: inquiry.remarks,
      });
    }
  }, [inquiry, dispatch]);

  useEffect(() => {
    dispatch(getInquiryDetailById(id));
  }, [dispatch, id]);

  const updateHandler = (e) => {
    e.preventDefault();
    setValues({ ...values, pending: true });

    const data = {
      status: values.status,
      remark: values.remarks,
    };

    setValues({ ...values, pending: false });
    dispatch(updateInquiryList({ data, id, navigate, page_number, value }));
  };

  return (
    <>
      {(loader || values.pending) && <Loader />}
      <div className="comman_btn_container center_back_btn">
        <Link
          to={`${routes.inquiries}/page/${page_number}`}
          state={{ value }}
          className="comman_btn back_btn"
        >
          Back
        </Link>
      </div>
      <div className="project_edit_main_content">
        <div className="create_from_row">
          <div className="create_from_input_content">
            <label className="create_from_label">Status </label>
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Received"
              id="received"
              name="status"
              value="received"
              onChange={handleInputChange}
              checked={values.status === "received"}
            />
            <Input
              className="create_from_radio_checkbox_content"
              labelClassName="create_from_radio_label"
              inputClassName="create_from_radio_checkbox"
              errorClassName="err_text"
              type="radio"
              label="Responded"
              id="responded"
              name="status"
              value="responded"
              onChange={handleInputChange}
              checked={values.status === "responded"}
            />
          </div>
          <TextArea
            className="create_from_input_content col_span_2"
            labelClassName="create_from_label"
            inputClassName="create_from_input"
            errorClassName="err_text"
            type="text"
            placeholder="Enter Remarks"
            label="Remarks"
            id="remarks"
            name="remarks"
            value={values.remarks}
            onChange={handleInputChange}
          />
          <Button
            className="create_from_bottom_btn  col_span_2"
            buttonClassName="comman_btn"
            onClick={updateHandler}
            text="Update"
          />
        </div>
      </div>
    </>
  );
};

export default EditInquiryPage;
