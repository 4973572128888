import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import { csvFileUpload } from "../../store/slice/customerPropertySlice";
import { CSVLink } from "react-csv";
import { addIcon } from "../../icons";
import Input from "../Common/Input";

const CsvToJson = () => {
  const allowedExtensions = ["csv"];
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");

  const csvFilesList = useSelector((state) => state.customerProperty.csvFiles);

  // console.log("csvFilesList", csvFilesList);

  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      console.log(e.target.files);
      setFileName(e.target.files[0].name);
      const inputFile = e.target.files[0];
      console.log("inputFile", inputFile);

      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };

  const handleParse = () => {
    // If user clicks the parse button without
    // a file we show a error
    console.log("file", file);
    if (!file || error) return setError("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      console.log("parsedData", parsedData);
      setData(parsedData);
      // dispatch(csvFileUpload(parsedData));
      // setFileName("");
    };
    reader.readAsText(file);
  };

  const [csvFiles, setCsvFiles] = useState([]);
  const [csv, setCsv] = useState([]);

  useEffect(() => {
    const allCsvFiles = csvFilesList?.map((item, i) => {
      return [
        item.first_name + item.last_name,
        item.project_id,
        item.address,
        item.birth_date,
        item.email,
        item.phone_number,
        item.plan_id,
        item.tower_name,
        item.property_number,
        item.reason,
        item.user_type,
      ];
    });
    setCsvFiles(allCsvFiles);
  }, [csvFilesList]);

  useEffect(() => {
    const csvData =
      csvFiles?.length > 0
        ? [
            [
              "Name",
              "Project Id",
              "Address",
              "Birth Date",
              "Email",
              "Phone Number",
              "Plan Id",
              "Tower Name",
              "Property Number",
              "Reason",
              "User Type",
            ],
            ...csvFiles,
          ]
        : [];
    setCsv(csvData);
  }, [csvFiles]);

  return (
    <div>
      <h2 className="featured_project_heading" htmlFor="csvInput">
        Enter CSV File
      </h2>
      <div className="radio-input-flex">
        <Input
          className="project_edit_detail_column_content amenieties_file_select_content csv_file_select"
          labelClassName="select_amenieties_file_btn"
          inputClassName=""
          errorClassName="err_text"
          type="file"
          label="Choose File"
          id="csvInput"
          name="file"
          onChange={handleFileChange}
          labelOtherProps={addIcon}
        />
        {fileName && (
          <span className="featured_project_heading csv_file_name">
            {fileName}
          </span>
        )}
        {error && <span className="error_text csv_file_name">{error}</span>}
      </div>
      <div className="flex">
        <button className="comman_btn parse_csv_btn" onClick={handleParse}>
          Parse
        </button>
        {csvFilesList.length > 0 && (
          <CSVLink className="comman_btn parse_csv_btn" data={csv}>
            Export CSV
          </CSVLink>
        )}
      </div>
    </div>
  );
};

export default CsvToJson;
