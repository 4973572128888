import React from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { routes } from "../../constants";
import { editIcon, viewIcon } from "../../icons";
import { getUserById } from "../../store/slice/userSlice";
import Button from "../Common/Button";
import moment from "moment";

const InquiryItem = ({ item, i, showModal, values }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { page_number } = params;

  // const userDetailHandler = (user_id) => {
  //   dispatch(getUserById({ id: user_id }));
  // };

  return (
    <tr className="custom_data_table_row" key={i}>
      <td className="custom_data_table_item table_item">
        {/* <Button
          className="custom_data_table_item table_item"
          buttonClassName="comman_btn user_referred_btn"
          onClick={(e) => userDetailHandler(item.user_id)}
          text={item.first_name + " " + item.last_name}
        /> */}
        {/* <Link to={`${routes.users}/${item.id}/1`}> */}
        {item.first_name + " " + item.last_name}
        {/* </Link> */}
      </td>
      <td className="custom_data_table_item table_item">{item.email}</td>
      <td className="custom_data_table_item table_item">{item.user_type}</td>
      <td className="custom_data_table_item table_item">{item.project_name}</td>
      <td className="custom_data_table_item table_item">{item.phone_number}</td>
      <td className="custom_data_table_item table_item">
        {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
      </td>
      <td className="custom_data_table_item table_item">
        {moment(item.created_at).format("MMMM Do YYYY, h:mm:ss a")}
      </td>
      <td className="custom_data_table_item lead_btn_container table_item">
        <div className="custom_data_table_view_edit_btn_item_row">
          <Button
            buttonClassName="custom_data_table_view_edit_item_btn"
            onClick={(e) => showModal(item.id)}
            text={viewIcon}
          />
          <Link
            to={`${routes.inquiries}/edit/${item.id}/${page_number}`}
            state={{ value: values }}
            className="project_list_card_view_edit_btn "
          >
            {editIcon}
          </Link>
        </div>
      </td>
    </tr>
  );
};

export default InquiryItem;
