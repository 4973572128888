import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import VerifyCustomer from "../../component/Users/VerifyCustomer";
import moment from "moment";
import { getUserById } from "../../store/slice/userSlice";
import { userDefaultIcon } from "../../icons";

const BasicInfo = ({ userData }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;

  const [isClicked, setIsClicked] = useState(false);

  const verifyUserHandler = () => {
    setIsClicked(true);
  };

  return (
    <div className="project_edit_main_content">
      <div className="about_page_section">
        <div className="about_detaile_row">
          <div>
            {userData && userData[0]?.profile_image ? (
              <img
                src={`${process.env.REACT_APP_FILE_BASE_URL}/${userData[0]?.profile_image}`}
                alt="user profile"
                className="user_profile"
              />
            ) : (
              <div className="user_profile">{userDefaultIcon}</div>
            )}
          </div>
          <div className="about_detaile_text_content">
            <h2>Name </h2>
            <p>
              {userData && userData[0]?.first_name}{" "}
              {userData && userData[0]?.last_name}
            </p>
          </div>
          <div className="about_detaile_text_content">
            <h2>Mobile Number </h2>
            <p>{userData && userData[0]?.phone_number}</p>
          </div>
          <div className="about_detaile_text_content">
            <h2>Email </h2>
            <p>{userData && userData[0]?.email}</p>
          </div>
          <div className="about_detaile_text_content">
            <h2>User Type </h2>
            <p>{userData && userData[0]?.user_type}</p>
          </div>
          {/* <div className="about_detaile_text_content">
            <h2>Address </h2>
            <p>{userData && userData[0]?.address}</p>
          </div> */}
          {/* <div className="about_detaile_text_content">
            <h2>Created At </h2>
            <p>
              {moment(userData && userData[0]?.created_at).format(
                "DD/MM/YY, h:mm A"
              )}
            </p>
          </div> */}
          {userData && userData[0]?.updated_at && (
            <div className="about_detaile_text_content">
              <h2>Updated At </h2>
              <p>
                {moment(userData && userData[0]?.updated_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </p>
            </div>
          )}
          <div className="about_detaile_text_content">
            <h2>Status </h2>
            <p>{userData && userData[0]?.user_status}</p>
            {userData &&
              userData[0]?.user_status === "unverified" &&
              !isClicked && (
                <div className="verify_btn">
                  <button className="comman_btn" onClick={verifyUserHandler}>
                    Verify
                  </button>
                </div>
              )}
          </div>
          <div className="about_detaile_text_content">
            <h2>Wallet Balance </h2>
            <p>{userData && userData[0]?.wallet_balance}</p>
          </div>
        </div>
        {isClicked && (
          <div className="project_edit_main_content">
            <div className="about_page_section">
              <VerifyCustomer getUserData={userData[0]} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicInfo;
