export const legalDocumentsColumn = (legal, setLegalDocErr) => {
  const hasEmptyTitleAndDoc = legal.some(
    (item) => item.doc.trim() === "" && item.title.trim() === ""
  );
  const hasEmptyTitleLegal = legal.some((item) => item.title.trim() === "");
  if (hasEmptyTitleAndDoc) {
    setLegalDocErr("");
    return true;
  } else if (hasEmptyTitleLegal) {
    setLegalDocErr("Document and title both are required!");
    return false;
  } else {
    setLegalDocErr("");
    return true;
  }
};

export const drawingsColumn = (drawing, setDrawingsErr) => {
  const hasEmptyTitleAndDoc = drawing.some(
    (item) => item.doc.trim() === "" && item.title.trim() === ""
  );
  const hasEmptyTitleDrawings = drawing.some(
    (item) => item.title.trim() === ""
  );
  if (hasEmptyTitleAndDoc) {
    setDrawingsErr("");
    return true;
  } else if (hasEmptyTitleDrawings) {
    setDrawingsErr("Document and title both are required!");
    return false;
  } else {
    setDrawingsErr("");
    return true;
  }
};

export const documentsColumn = (document, setDocumentsErr) => {
  const hasEmptyTitleAndDoc = document.some(
    (item) => item.doc.trim() === "" && item.title.trim() === ""
  );
  const hasEmptyTitleDocuments = document.some(
    (item) => item.title.trim() === ""
  );
  if (hasEmptyTitleAndDoc) {
    setDocumentsErr("");
    return true;
  } else if (hasEmptyTitleDocuments) {
    setDocumentsErr("Document and title both are required!");
    return false;
  } else {
    setDocumentsErr("");
    return true;
  }
};

export const mediaColumn = (mediasCategoryImage, setMediaColumnErr) => {
  const hasEmptyCategoryAndImage = mediasCategoryImage.some(
    (item) =>
      item.category_name.trim() === "" &&
      item.images &&
      item.images.length === 0
  );
  const hasEmptyCategoryName = mediasCategoryImage.some(
    (item) =>
      item.category_name.trim() === "" && item.images && item.images.length > 0
  );

  if (hasEmptyCategoryAndImage) {
    setMediaColumnErr("");
    return true;
  } else if (hasEmptyCategoryName) {
    setMediaColumnErr("Document and Category Name both are required!");
    return false;
  } else {
    setMediaColumnErr("");
    return true;
  }
};

// export const videosColumn = (videoUrl, setVideosColumnErr) => {
//   const hasEmptyVideoInput = videoUrl.some((item) => item.trim() === "");
//   if (hasEmptyVideoInput) {
//     setVideosColumnErr("Empty fields are required");
//     return false;
//   } else {
//     setVideosColumnErr("");
//     return true;
//   }
// };

export const onSiteActualColumn = (tempOnSiteActuals, setOnSiteErr) => {
  console.log("tempOnSiteActuals", tempOnSiteActuals);

  const hasEmptyTitleAndImage = tempOnSiteActuals.some(
    (item) => item.title.trim() === "" && item.image === ""
  );
  const hasEmptyTitle = tempOnSiteActuals.some(
    (item) => item.title.trim() === ""
  );

  const hasEmptyImage = tempOnSiteActuals.some((item) => item.image === "");
  if (hasEmptyTitle || hasEmptyTitleAndImage || hasEmptyImage) {
    setOnSiteErr("Document and title both are required!");
    return true;
  } else {
    setOnSiteErr("");
  }
};

export const notificationContactValidation = (values) => {
  const hasEmptyNumbers = values.some((item) => item.contact_number === "");
  if (hasEmptyNumbers) {
    return true;
  } else {
    return false;
  }
};
