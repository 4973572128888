import React from "react";
import { useLocation } from "react-router-dom";

const VerifyPropertyModal = ({
  verifyCondirmation,
  handleVerifyCondirmationClose,
  verifyPropertyHandler,
  propertyData,
  storingVerificationStatus,
}) => {
  // console.log("storingVerificationStatus", storingVerificationStatus);

  const location = useLocation();
  const status = location?.state?.status;
  // console.log("status", status);

  // status: "unverified";
  return (
    <>
      <div
        className={`user_detail_modal_bg  ${
          verifyCondirmation && "user_detail_modal_show"
        }`}
        onClick={handleVerifyCondirmationClose}
      ></div>
      <section
        className={`user_detail_modal_main verify_user_modal  ${
          verifyCondirmation && "user_detail_modal_show"
        }`}
      >
        <div className="verify_user_modal_content">
          <h4 className="modal-title">Are you sure?</h4>
          <div className="modal-body">
            <p>{`You want to verify Following property for User?`}</p>
          </div>

          <div className="modal-body">
            <div className="detail_container_content">
              <div className="detail_container">
                <h2 className="detail_label">User Name</h2>
                <span className="detail_content">
                  {propertyData?.owner_first_name +
                    " " +
                    propertyData?.owner_last_name}
                </span>
              </div>
              <div className="detail_container">
                <h2 className="detail_label">Project Name</h2>
                <span className="detail_content">
                  {propertyData?.project_name}
                </span>
              </div>
              <div className="detail_container">
                <h2 className="detail_label">Tower Name</h2>
                <span className="detail_content">
                  {propertyData?.tower_name}
                </span>
              </div>
              <div className="detail_container">
                <h2 className="detail_label">Property Number</h2>
                <span className="detail_content">
                  {propertyData?.property_number}
                </span>
              </div>
            </div>
          </div>

          {(storingVerificationStatus === "incomplete_profile" ||
            storingVerificationStatus === "unverified" ||
            status === "unverified") && (
            <div className="last-modal-body">
              <p className="last-modal-para">
                This user is not verified yet,
                <br />
                please verify the user from the "Basic Info" tab first.
              </p>
            </div>
          )}

          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleVerifyCondirmationClose}
            >
              Cancel
            </button>
            {storingVerificationStatus === "incomplete_profile" ||
            storingVerificationStatus === "unverified" ||
            status === "unverified" ? (
              ""
            ) : (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => verifyPropertyHandler()}
              >
                Yes, Verify
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyPropertyModal;
