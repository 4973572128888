import moment from "moment";
import React from "react";
import Button from "../Common/Button";

const UserItem = ({ item, i, onClickHandle, deleteUserModal, values }) => {
  return (
    <tr className="custom_data_table_row" key={i}>
      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        {item.first_name}
      </td>
      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        {item.last_name}
      </td>
      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        {item.user_type}
      </td>
      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        {item.user_status}
      </td>
      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        <Button
          buttonClassName="unverified-properties-btn"
          text={
            item.unverified_property && item.unverified_property?.length !== 0
              ? item.unverified_property
              : "0"
          }
        />
      </td>
      {values.userStatus.value === "unverified" && (
        <td className="custom_data_table_item" style={{ textAlign: "center" }}>
          {item.project_data
            ? JSON.parse(item.project_data)?.project_name
              ? JSON.parse(item.project_data)?.project_name
              : "-"
            : "-"}
        </td>
      )}
      {values.userStatus.value === "unverified" && (
        <td className="custom_data_table_item" style={{ textAlign: "center" }}>
          {item.project_data ? JSON.parse(item.project_data)?.tower_name : "-"}
        </td>
      )}
      {values.userStatus.value === "unverified" && (
        <td className="custom_data_table_item" style={{ textAlign: "center" }}>
          {item.project_data
            ? JSON.parse(item.project_data)?.property_number
            : "-"}
        </td>
      )}
      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        {item.phone_number}
      </td>

      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        {moment(item.created_at).format("MMMM Do YYYY, h:mm:ss a")}
      </td>

      {/* New line added */}
      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        {moment(item.updated_at).format("MMMM Do YYYY, h:mm:ss a")}
      </td>

      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        {item.user_login_status === "1" && (
          <i className="mobile-icon fa-solid fa-mobile-screen-button"></i>
        )}
      </td>
      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        <Button
          buttonClassName="custom_data_table_button"
          onClick={(e) =>
            onClickHandle(item.id, item.user_type, values, item.user_status)
          }
          text="Details"
        />
      </td>
      <td className="custom_data_table_item" style={{ textAlign: "center" }}>
        <Button
          buttonClassName="custom_data_table_button"
          onClick={(e) => deleteUserModal(item.id, item.phone_number)}
          text="Delete"
        />
      </td>
    </tr>
  );
};

export default UserItem;
