import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { routes } from "../../constants";
import { editIcon } from "../../icons";
import {
  getProjectList,
  updateProjectList,
} from "../../store/slice/projectSlice";
import Button from "../Common/Button";

const ProjectItem = ({ item }) => {
  const dispatch = useDispatch();

  const onClickHandle = (id) => {
    const data = {
      published: item.published === "0" ? "1" : "0",
    };
    dispatch(
      updateProjectList({
        data,
        id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getProjectList({ admin: "true" }));
          }
        },
      })
    );
  };

  const handleSoldStatus = (id) => {
    const data = {
      sold_status: item.sold_status === "0" ? "1" : "0",
    };

    dispatch(
      updateProjectList({
        data,
        id,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(getProjectList({ admin: "true" }));
          }
        },
      })
    );
  };

  return (
    <div className="project_list_card" key={item.id}>
      <img
        src={process.env.REACT_APP_FILE_BASE_URL + "/" + item.featured_image}
        alt="project item"
        className="project_list_card_img"
      />
      <div className="project_list_card_body">
        <div className="project_list_card_detaile">
          <div>
            <h2>{item.name}</h2>
            <p>{item.one_line_specification}</p>
            <p>{item.possession_status}</p>
          </div>
          <span style={{ color: "#c11f3b", fontWeight: "bold" }}>
            # {item.id}
          </span>
        </div>
        <div className="project_list_card_bottom_btn_row">
          {
            <Link
              to={`${routes.projects}/edit/${item.id}`}
              className="project_list_card_view_edit_btn"
            >
              {editIcon}
              Project Edit
            </Link>
          }
          {item?.sold_status === "0" && (
            <Button
              buttonClassName="custom_data_table_button"
              onClick={(e) => onClickHandle(item.id)}
              text={item.published === "1" ? "Unpublish" : "Publish"}
            />
          )}
          <Button
            buttonClassName="custom_data_table_button sold_button"
            onClick={(e) => handleSoldStatus(item.id)}
            text={item.sold_status === "1" ? "Unsold" : "Sold"}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
