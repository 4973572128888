import React from "react";

const NotificationdetailModal = ({
  show,
  handleClose,
  message,
  attachment,
  subject,
}) => {
  const showHideClassName = show && "user_detail_modal_show";

  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_video_img">
              {attachment ? (
                <iframe
                  title="notification"
                  src={`${process.env.REACT_APP_FILE_BASE_URL}/${attachment}`}
                  alt="Notification item"
                  className="plan_slider_box_img"
                />
              ) : (
                <img
                  src="https://www.medstartr.com/main/images/no-image.png"
                  alt="default pic"
                  className="default_image"
                />
              )}
            </div>
            <div className="inquiry_complaint_detail_body">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">Subject</h2>
                <p className="inquiry_complaint_detail_name">{subject}</p>
              </div>
            </div>
            <div className="inquiry_complaint_detail_body">
              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">Message</h2>
                <p className="inquiry_complaint_detail_name">{message}</p>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            Close
          </button>
        </div>
      </section>
    </>
  );
};

export default NotificationdetailModal;
