import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInquiryDetailById } from "../../store/slice/inquirySlice";
import moment from "moment";

const InquiryDetailModal = ({ handleClose, show, inquiryId }) => {
  const showHideClassName = show && "user_detail_modal_show";
  const dispatch = useDispatch();

  useEffect(() => {
    if (inquiryId) {
      dispatch(getInquiryDetailById(inquiryId));
    }
  }, [inquiryId, dispatch]);

  const inquiryDetailId = useSelector((state) => state.inquiry.inquiryDetailId);
  return (
    <>
      <div
        className={`user_detail_modal_bg  ${showHideClassName}`}
        onClick={handleClose}
      ></div>
      <section className={`user_detail_modal_main ${showHideClassName}`}>
        <div className="inquiry_complaint_detail_section">
          <div className="inquiry_complaint_detail_content">
            <div className="inquiry_complaint_detail_body">
              <div className="inquiry_complaint_detail_create_updated_date">
                <div className="inquiry_complaint_detail_create_date">
                  <h2>Created At </h2>
                  <p>
                    {moment(inquiryDetailId?.created_at).format(
                      "DD/MM/YYYY, h:mm A"
                    )}
                  </p>
                </div>
                {inquiryDetailId?.updated_at && (
                  <div className="inquiry_complaint_detail_updated_date">
                    <h2>Updated At </h2>
                    <p>
                      {moment(inquiryDetailId?.updated_at).format(
                        "DD/MM/YYYY, h:mm A"
                      )}
                    </p>
                  </div>
                )}
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">Message</h2>
                <p className="inquiry_complaint_detail_name">
                  {inquiryDetailId?.message}
                </p>
              </div>

              <div className="inquiry_complaint_detail_text">
                <h2 className="inquiry_complaint_detail_heading">Status</h2>
                <p className="inquiry_complaint_detail_name">
                  {inquiryDetailId?.status}
                </p>
              </div>

              {inquiryDetailId?.remark && (
                <div className="inquiry_complaint_detail_text">
                  <h2 className="inquiry_complaint_detail_heading">Remark</h2>
                  <p className="inquiry_complaint_detail_name">
                    {inquiryDetailId?.remark}
                  </p>
                </div>
              )}
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="user_detail_modal_close_btn complaint_view_modalbtn comman_btn"
          >
            Close
          </button>
        </div>
      </section>
    </>
  );
};

export default InquiryDetailModal;
