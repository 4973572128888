import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearPlan, getPlan } from "../../store/slice/planSlice";
import moment from "moment";
import Loader from "../../component/Common/Loader";
import { routes } from "../../constants";
import { editIcon, searchIcon, viewIcon } from "../../icons";
import Pagination from "../../component/Pagination/Pagination";
import { useForm } from "../../hooks/useForm";
import { getProjectList } from "../../store/slice/projectSlice";
import Select from "react-select";
import Input from "../../component/Common/Input";
import Button from "../../component/Common/Button";

const PlanListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { page_number } = params;

  const location = useLocation();
  const locValues = location?.state?.locValues;

  const initialFValues = {
    project_id: locValues?.project_id ? locValues?.project_id : null,
    searchByTitle: locValues?.searchByTitle ? locValues?.searchByTitle : "",
    searchByName: locValues?.searchByName ? locValues?.searchByName : "",
  };

  const { values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      navigate(`${routes.plans}/page/1`);
      dispatch(clearPlan());
      dispatch(
        getPlan({
          projectId: values.project_id,
          title: values.searchByTitle,
          display_name: values.searchByName,
        })
      );
    }
  }, [values]);

  useEffect(() => {
    // if (
    //   !locValues?.searchByName &&
    //   !locValues?.project_id &&
    //   !locValues?.searchByTitle
    // ) {
    navigate(`${routes.plans}/page/${page_number}`);
    dispatch(clearPlan());
    dispatch(
      getPlan({
        start: (page_number - 1) * 10,
        projectId: values.project_id,
        title: values.searchByTitle,
        display_name: values.searchByName,
      })
    );
    ref.current = true;
    // } else {
    // }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProjectList({ admin: "true" }));
  }, [dispatch]);

  const projectList = useSelector((state) => state.project.projects);

  const projectIdOptions = projectList?.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const projectOptions = [
    { value: "All Projects", label: "All Projects", id: "" },
    ...projectIdOptions,
  ];

  const planList = useSelector((state) => state.plan.planDetails?.data);

  const totalCount = useSelector((state) => state.plan.planDetails?.totalCount);

  const loader = useSelector((state) => state.plan.loader);

  const onPageChange = (page_number) => {
    dispatch(
      getPlan({
        start: page_number,
        limit: 10,
        projectId: values.project_id,
        title: values.searchByTitle,
        display_name: values.searchByName,
      })
    );
  };

  const searchByTitleHandler = (e) => {
    setValues({ ...values, searchByTitle: e.target.value });
  };

  const searchByNameHandler = (e) => {
    setValues({ ...values, searchByName: e.target.value });
  };

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content">
        <div className="creat_edit_project_btn_row">
          <Link to={routes.createPlan} className="comman_btn ml-auto">
            Create Plan
          </Link>
        </div>
        <div className="user_data_search_select_box_row">
          {/* <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder="Search by Plan title..."
              id="searchByTitle"
              name="searchByTitle"
              value={values.searchByTitle}
              onChange={searchByTitleHandler}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div> */}
          <div className="user_data_search">
            <Input
              inputClassName="user_data_search_input"
              type="search"
              placeholder="Search by Plan name..."
              id="searchByName"
              name="searchByName"
              value={values.searchByName}
              onChange={searchByNameHandler}
            />
            <Button buttonClassName="user_data_search_btn" text={searchIcon} />
          </div>
          <div className="user_data_select lead_status-options">
            <Select
              placeholder="Projects..."
              value={values.project_id}
              onChange={(e) => handleInputChange(e, "project_id")}
              className="basic-multi-select"
              classNamePrefix="select"
              name="project_id"
              id="project_id"
              options={projectOptions}
            />
          </div>
        </div>
        <div className="col_lg_3 d_grid gap_15">
          {planList &&
            planList.map((item) => {
              return (
                <div className="plan_list_column" key={item.id}>
                  <div className="plan_list_img">
                    {item?.main_plan_image && (
                      <img
                        src={
                          process.env.REACT_APP_FILE_BASE_URL +
                          "/" +
                          item.main_plan_image
                        }
                        alt="plan item"
                        className="plan_list_image"
                      />
                    )}
                    <div className="plan_list_add_update_time">
                      <p className="plan_list_added_time">
                        Created Date
                        <span>
                          {moment(item.created_at).format("DD/MM/YY, h:mm A")}
                        </span>
                      </p>
                      {item?.updated_at && (
                        <p className="plan_list_update_time">
                          Updated Date
                          <span>
                            {moment(item?.updated_at).format(
                              "DD/MM/YY, h:mm A"
                            )}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="plan_list_body">
                    {item.project_name && (
                      <span className="plan_list_title">
                        {item.project_name}
                      </span>
                    )}
                    {item.title && (
                      <span className="plan_list_title">{item.title}</span>
                    )}
                    <div className="plan_list_id">
                      {item.display_name && (
                        <h3 className="plan_list_name">{item.display_name}</h3>
                      )}
                      <span className="plan_list_name"># {item.id}</span>
                    </div>
                    <div className="plan_list_bottom_btn">
                      <Link
                        to={`${routes.plans}/edit/${item.id}/${page_number}`}
                        state={{ values }}
                        className="plan_list_bottom"
                      >
                        {editIcon}
                        Plan Edit
                      </Link>
                      <Link
                        to={`${routes.plans}/${item.id}/${page_number}`}
                        state={{ values }}
                        className="plan_list_bottom"
                      >
                        {viewIcon}
                        Plan View
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {<Pagination totalRecords={totalCount} onPageChange={onPageChange} />}
      </div>
    </>
  );
};

export default PlanListPage;
