import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateProjectList } from "../../../store/slice/projectSlice";
import Loader from "../../Common/Loader";
import { useForm } from "../../../hooks/useForm";
import Input from "../../Common/Input";
import Button from "../../Common/Button";
import { addIcon, removeIcon } from "../../../icons";
import { useEffect, useState } from "react";
import { getPlan } from "../../../store/slice/planSlice";
import Select from "react-select";
import { toast } from "react-toastify";

const TowersNameForm = ({ projectDetails }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
  const loader = useSelector((state) => state.project.loader);
  const planData = useSelector((state) => state.plan.planDetails?.data);

  const generateInitialInputCounts = (length) => {
    const initialCounts = [];
    for (let i = 0; i < length; i++) {
      initialCounts.push({ inputCount: 0, isDisable: false });
    }
    return initialCounts;
  };

  const [inputCounts, setInputCounts] = useState([]);

  const planOptions = planData?.map((item) => {
    return { value: item.id, label: item.display_name };
  });

  const getSelectedPlanObject = (id) => {
    let obj = {};
    for (let i = 0; i < planOptions?.length; i++) {
      if (planOptions[i].value === id) {
        return planOptions[i];
      }
    }
  };

  const initialFValues = {
    tower_names: projectDetails?.tower_names
      ? JSON.parse(projectDetails?.tower_names)
      : [
          {
            name: "",
            properties: [],
          },
        ],
    orignal_tower_names: projectDetails?.tower_names
      ? JSON.parse(projectDetails?.tower_names)
      : [
          {
            name: "",
            properties: [],
          },
        ],
  };

  useEffect(() => {
    setValues({
      ...values,
      tower_names: projectDetails?.tower_names
        ? JSON.parse(projectDetails?.tower_names)
        : [
            {
              name: "",
              properties: [],
            },
          ],
      orignal_tower_names: projectDetails?.tower_names
        ? JSON.parse(projectDetails?.tower_names)
        : [
            {
              name: "",
              properties: [],
            },
          ],
    });
  }, [projectDetails]);

  const { values, setValues } = useForm(initialFValues, true);

  useEffect(() => {
    dispatch(
      getPlan({
        start: 0,
        limit: 10000000,
        project_id: projectDetails?.id,
      })
    );
  }, [projectDetails]);

  useEffect(() => {
    setInputCounts(generateInitialInputCounts(values.tower_names.length));
  }, [values.tower_names.length]);

  const towerNamesHandler = (e, i) => {
    const { name, value } = e.target;
    const list = [...values.tower_names];
    list[i][name] = value;
    setValues({
      ...values,
      tower_names: list,
    });
  };

  // handle click event of the Remove button
  const towerNamesHandleRemoveClick = (index) => {
    const list = [...values.tower_names];
    list.splice(index, 1);
    setValues({
      ...values,
      tower_names: list,
    });
  };

  // handle click event of the Add button
  const towerNamesHandleAddClick = () => {
    setValues({
      ...values,
      tower_names: [
        ...values.tower_names,
        {
          name: "",
        },
      ],
    });
  };

  const propertyChangeHandler = (e, firstIndex, secondIndex, type) => {
    if (type !== "plan") {
      const { name, value } = e.target;
      setValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const updatedTowerNames = [...updatedValues.tower_names];
        const updatedProperties = [...updatedTowerNames[firstIndex].properties];
        updatedProperties[secondIndex] = {
          ...updatedProperties[secondIndex],
          [name]: value,
        };
        updatedTowerNames[firstIndex] = {
          ...updatedTowerNames[firstIndex],
          properties: updatedProperties,
        };
        updatedValues.tower_names = updatedTowerNames;
        return updatedValues;
      });
    } else {
      setValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const updatedTowerNames = [...updatedValues.tower_names];
        const updatedProperties = [...updatedTowerNames[firstIndex].properties];
        updatedProperties[secondIndex] = {
          ...updatedProperties[secondIndex],
          plan_id: e.value,
        };
        updatedTowerNames[firstIndex] = {
          ...updatedTowerNames[firstIndex],
          properties: updatedProperties,
        };
        updatedValues.tower_names = updatedTowerNames;
        return updatedValues;
      });
    }
  };

  const addPropertyToTowerhandler = (towerIndex) => {
    const newItem = {
      property_number: "",
      tower_name: values.tower_names[towerIndex].name,
      plan_id: 0,
    };
    const list = [...values.tower_names];
    list[towerIndex] = {
      ...list[towerIndex],
      properties: [...list[towerIndex].properties, newItem],
    };
    setValues({ ...values, tower_names: list });
  };

  const removePropertyFromTowerHandler = (towerIndex, propertyIndex) => {
    const list = [...values.tower_names];
    list[towerIndex] = {
      ...list[towerIndex],
      properties: list[towerIndex].properties.filter(
        (_, index) => index !== propertyIndex
      ),
    };
    setValues({ ...values, tower_names: list });
  };

  const inputCountChange = (index, e) => {
    setInputCounts((prevInputCounts) => {
      const updatedInputCounts = [...prevInputCounts];
      updatedInputCounts[index] = {
        ...updatedInputCounts[index],
        inputCount: e.target.value,
      };
      return updatedInputCounts;
    });
  };

  const createBulkInputsHandler = (inputCount, towerIndex) => {
    if (towerIndex < 0 || towerIndex >= values.tower_names.length) {
      console.error("Invalid towerIndex");
      return;
    }

    const newInputs = [];
    for (let i = 0; i < inputCount; i++) {
      newInputs.push({
        property_number: "",
        tower_name: values.tower_names[towerIndex].name,
        plan_id: 0,
      });
    }

    // Update the state with the new array of tower_names
    setValues((prevValues) => {
      const updatedTowerNames = [...prevValues.tower_names];
      const towerToUpdate = { ...updatedTowerNames[towerIndex] };

      if (towerToUpdate.hasOwnProperty("properties")) {
        // Tower already has "properties" field, so update the "properties" array
        towerToUpdate.properties = [...towerToUpdate.properties, ...newInputs];
      } else {
        // Tower doesn't have "properties" field, so create it with the new inputs
        towerToUpdate.properties = newInputs;
      }

      updatedTowerNames[towerIndex] = towerToUpdate;

      return { ...prevValues, tower_names: updatedTowerNames };
    });

    setInputCounts((prevInputCounts) => {
      const updatedInputCounts = [...prevInputCounts];
      updatedInputCounts[towerIndex] = {
        ...updatedInputCounts[towerIndex],
        isDisable: true,
      };
      return updatedInputCounts;
    });
  };

  // const saveDataHandler = (e) => {
  //   e.preventDefault();
  //   let uniqueChars = values.tower_names
  //     .map((item, i) => {
  //       return { ...item, name: item.name.trim() };
  //     })
  //     .reduce((r, { name }) => {
  //       if (!r.get(name)) {
  //         r.set(name, { name });
  //       }
  //       return r;
  //     }, new Map())
  //     .values();
  //   const data = {
  //     tower_names: [...uniqueChars],
  //   };
  //   setValues({ ...values, ...data });

  //   console.log("dataaaa", data);
  //   dispatch(updateProjectList({ data, id }));
  // };

  const checkDuplicateTowerNames = () => {
    const towerNamesSet = new Set();
    const duplicateNames = [];

    for (const tower of values.tower_names) {
      const trimmedName = tower.name.trim();

      if (towerNamesSet.has(trimmedName)) {
        duplicateNames.push(trimmedName);
      } else {
        towerNamesSet.add(trimmedName);
      }
    }
    if (duplicateNames.length > 0) {
      toast.error(
        `The following tower names are duplicated: ${duplicateNames.join(
          ", "
        )}`,
        {
          autoClose: 2000,
        }
      );

      return false;
    } else {
      // console.log("errr ==> not ERR");
      return true;
    }
  };

  const removeEmptyAndDuplicateProperties = (inputArray) => {
    return inputArray.map((tower) => {
      if (!tower.properties) {
        return tower;
      }

      const filteredProperties = tower.properties
        .filter((property) => property.property_number !== "")
        .filter((property, index, self) => {
          return (
            index ===
            self.findIndex(
              (p) => p.property_number === property.property_number
            )
          );
        });

      return { ...tower, properties: filteredProperties };
    });
  };

  const removeMatchingProperties = (tower_names, orignal_tower_names) => {
    const filteredProperties = tower_names?.map((tower) => {
      // Find the matching tower in orignal_tower_names array
      const originalTower = orignal_tower_names.find(
        (ot) => ot.name === tower.name
      );

      // If the tower has properties and there is a matching original tower
      if (tower.properties && originalTower) {
        const filteredProps = tower.properties?.filter((prop) => {
          // Find the matching property in the original tower
          const originalProp = originalTower.properties?.find(
            (op) => op.id === prop.id
          );

          // Check if there is a matching property with the same plan_id and property_number
          return !(
            originalProp &&
            originalProp.property_number === prop.property_number &&
            originalProp.plan_id === prop.plan_id
          );
        });

        // Update the tower object with filtered properties
        return { ...tower, properties: filteredProps };
      }

      // Return the original tower if there are no properties or no matching original tower
      return tower;
    });

    // Optionally, you can return the filteredProperties array
    return filteredProperties;
  };

  const saveDataHandler = (e) => {
    e.preventDefault();
    if (checkDuplicateTowerNames()) {
      const updatedArr = removeEmptyAndDuplicateProperties(values.tower_names);
      const finalArr = removeMatchingProperties(
        updatedArr,
        values.orignal_tower_names
      );

      const data = {
        tower_names: finalArr,
      };

      dispatch(updateProjectList({ data, id }));
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content">
        <div className="create_from_row">
          {values.tower_names &&
            values.tower_names.map((x, i) => {
              return (
                <>
                  <Input
                    className="create_from_input_content null"
                    labelClassName="create_from_label"
                    inputClassName="create_from_input"
                    errorClassName="err_text"
                    type="text"
                    placeholder="Enter Tower Name"
                    label="Tower Name"
                    id="name"
                    name="name"
                    value={x.name}
                    onChange={(e) => towerNamesHandler(e, i)}
                  />
                  <div className="create_project_properties_input_box">
                    <Input
                      className="create_from_input_content null"
                      labelClassName="create_from_label"
                      inputClassName="create_from_input"
                      errorClassName="err_text"
                      type="number"
                      placeholder="Enter the Number of Properties"
                      label="Number of Properties you want to create!"
                      id="privilege_name"
                      name="privilege_name"
                      value={inputCounts[i]?.inputCount}
                      onChange={(e) => inputCountChange(i, e)}
                      disabled={inputCounts[i]?.isDisable}
                    />
                    <button
                      className="comman_btn"
                      onClick={() =>
                        createBulkInputsHandler(inputCounts[i]?.inputCount, i)
                      }
                      disabled={
                        inputCounts[i]?.isDisable ||
                        inputCounts[i]?.inputCount <= 0
                      }
                    >
                      Create
                    </button>
                  </div>
                  <span className="col_span_2 border-bottom-span-minor"></span>
                  {values.tower_names[i].properties?.map((property, j) => {
                    return (
                      <>
                        <Input
                          className="create_from_input_content null"
                          labelClassName="create_from_label"
                          inputClassName="create_from_input"
                          errorClassName="err_text"
                          type="text"
                          placeholder="Enter Property number"
                          label="Property number"
                          id="property_number"
                          name="property_number"
                          value={property.property_number}
                          onChange={(e) => propertyChangeHandler(e, i, j)}
                        />
                        <div className="create_from_input_content">
                          <label className="create_from_label">Plan</label>
                          <div
                            style={{
                              zIndex: "999" - j,
                              position: "relative",
                            }}
                          >
                            <Select
                              placeholder="Select Plan..."
                              className="basic-multi-select"
                              classNamePrefix="select"
                              name="plan"
                              id="plan"
                              options={planOptions}
                              onChange={(e) =>
                                propertyChangeHandler(e, i, j, "plan")
                              }
                              value={getSelectedPlanObject(property.plan_id)}
                            />
                          </div>
                        </div>
                        <div className="project_edit_feature_add_remove_btn_row col_span_2">
                          {!property.id && (
                            <div>
                              <button
                                className="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                                type="button"
                                onClick={() =>
                                  removePropertyFromTowerHandler(i, j)
                                }
                              >
                                Remove
                              </button>
                            </div>
                          )}
                          {values.tower_names[i].properties?.length - 1 ===
                            j && (
                            <div>
                              <button
                                className="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                                type="button"
                                onClick={() => addPropertyToTowerhandler(i)}
                              >
                                Add
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                  <div className="project_edit_feature_add_remove_btn_row col_span_2">
                    {values.tower_names.length !== 1 && (
                      <Button
                        buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                        onClick={() => towerNamesHandleRemoveClick(i)}
                        text="Remove"
                        other={removeIcon}
                      />
                    )}
                    {values.tower_names.length - 1 === i && (
                      <Button
                        buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                        onClick={() => towerNamesHandleAddClick()}
                        text="Add"
                        other={addIcon}
                      />
                    )}
                  </div>
                  <span className="col_span_2 border-bottom-span" />
                </>
              );
            })}
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={saveDataHandler}
          text="Save"
        />
      </div>
    </>
  );
};

export default TowersNameForm;
