import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { fileUpload } from "../../../helpers/fileUpload";
import { useForm } from "../../../hooks/useForm";
import { addIcon, removeIcon } from "../../../icons";
import { updateProjectList } from "../../../store/slice/projectSlice";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import Loader from "../../Common/Loader";
import { videosColumn, mediaColumn } from "./ValidationForm";

const MediasForm = ({ projectDetails }) => {
  let InitialMediasPreview;

  if (projectDetails?.medias) {
    InitialMediasPreview = JSON.parse(projectDetails?.medias).map(
      (i, index) => {
        return i.images?.length > 0
          ? i?.images?.map((x, ind) => {
              return `${process.env.REACT_APP_FILE_BASE_URL}/${x}`;
            })
          : [];
      }
    );
  }
  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;
  const loader = useSelector((state) => state.project.loader);

  const [mediaColumnErr, setMediaColumnErr] = useState("");

  const initialFValues = {
    medias:
      projectDetails?.medias && JSON.parse(projectDetails?.medias).length > 0
        ? JSON.parse(projectDetails?.medias)
        : [
            {
              category_name: "",
              images: [],
              imgError: "",
            },
          ],
    medias_preview: projectDetails?.medias ? InitialMediasPreview : [],

    videos: projectDetails?.videos ? JSON.parse(projectDetails?.videos) : [""],
    pending: false,
  };

  const { values, setValues } = useForm(initialFValues, true);

  const mediasHandler = (e, index) => {
    setMediaColumnErr("");
    const { files, value } = e.target;
    var list = [...values.medias];
    var listPreview = [];
    if (files) {
      if (files.length > 0) {
        let images = [];
        for (const key in files) {
          if (key !== "length" && key !== "item") {
            if (!files[key].name.match(/.(jpg|jpeg|png)$/i)) {
              list[index]["imgError"] = "Please add valid image (png/jpeg/jpg)";
              continue;
            }
            if (files[key].size > 50000000) {
              list[index]["imgError"] =
                "Image size should not be greater than 50MB";
              continue;
            }
            list[index]["imgError"] = "";
            images.push(files[key]);
          }
        }

        for (let i = 0; i < images.length; i++) {
          listPreview.push(URL.createObjectURL(images[i]));
        }
        let tempPrev = [...values.medias_preview];

        if (tempPrev.length > 0) {
          if (tempPrev[index]) {
            tempPrev[index] = [...tempPrev[index], ...listPreview];
          } else {
            tempPrev.push(listPreview);
          }
        } else {
          tempPrev = [listPreview];
        }

        list[index].images = [...list[index].images, ...images];
        setValues({
          ...values,
          medias_preview: tempPrev,
          medias: list,
        });
      }
    } else {
      list[index].category_name = value;
      setValues({
        ...values,
        medias: list,
      });
    }
  };

  const videoLinkChangeHandler = (e, index) => {
    const { value } = e.target;
    const list = [...values.videos];
    list[index] = value;
    setValues({
      ...values,
      videos: list,
    });
  };

  const videoHandleRemoveClick = (index) => {
    const list = [...values.videos];
    list.splice(index, 1);
    setValues({
      ...values,
      videos: list,
    });
  };

  const videoHandleAddClick = () => {
    setValues({
      ...values,
      videos: [...values.videos, ""],
    });
  };

  const imageRemoveHandler = (i, index) => {
    const imgArr = [...values.medias];
    const imgArrPreview = [...values.medias_preview];

    imgArr[i].images.splice(index, 1);
    imgArrPreview[i].splice(index, 1);
    setValues({
      ...values,
      medias: imgArr,
      medias_preview: imgArrPreview,
    });
  };

  const mediasHandleRemoveClick = (index) => {
    setMediaColumnErr("");
    const list = [...values.medias];
    const mediasPreviewList = [...values.medias_preview];
    mediasPreviewList.splice(index, 1);
    list.splice(index, 1);
    setValues({
      ...values,
      medias: list,
      medias_preview: mediasPreviewList,
    });
  };

  // handle click event of the Add button
  const mediasHandleAddClick = () => {
    setValues({
      ...values,
      medias_preview: [...values.medias_preview, []],
      medias: [
        ...values.medias,
        {
          category_name: "",
          images: [],
        },
      ],
    });
  };

  const saveButtonHandler = async (e) => {
    setValues({
      ...values,
      pending: true,
    });
    const formData = new FormData();
    let mediasImage = [...values.medias];

    for (let i = 0; i < mediasImage.length; i++) {
      for (let j = 0; j < mediasImage[i].images.length; j++) {
        if (typeof mediasImage[i].images[j] !== "string") {
          formData.append("file", mediasImage[i].images[j]);
          let temp = await fileUpload(formData);
          mediasImage[i].images[j] = temp;
          formData.delete("file");
        } else {
          console.log("else");
        }
      }
    }

    setValues({
      ...values,
      pending: false,
    });

    let hasEmptyCategoryName = mediaColumn(mediasImage, setMediaColumnErr);

    if (hasEmptyCategoryName) {
      const data = {
        medias: mediasImage,
        videos: values.videos,
      };

      dispatch(updateProjectList({ data, id }));
    }
  };

  return (
    <>
      {(values.pending || loader) && <Loader />}
      <div className="project_edit_main_content">
        <div className="project_edit_feature_media_row">
          <div className="project_edit_detail_column_content">
            <h2>Medias </h2>
            {values.medias &&
              values.medias.map((x, i) => {
                return (
                  <div key={i} className="project_edit_detail_divider">
                    <Input
                      className="project_edit_feature_input"
                      type="text"
                      placeholder="Category Name"
                      label="Category Name"
                      id="category_name"
                      name="category_name"
                      value={x.category_name}
                      onChange={(e) => mediasHandler(e, i)}
                    />
                    <Input
                      className="project_edit_media_input_file"
                      errorClassName="err_text"
                      type="file"
                      labelOtherProps={addIcon}
                      label={`Add Medias`}
                      id={`media_file${i}`}
                      accept="image/png, image/jpeg"
                      onClick={(e) => (e.target.value = null)}
                      name="image"
                      multiple={true}
                      onChange={(e) => mediasHandler(e, i)}
                    />
                    <div className="project_edit_media_image_section">
                      {values.medias_preview[i]?.length > 0 &&
                        values.medias_preview[i]?.map((imgs, index) => {
                          return (
                            <div
                              key={index}
                              className="project_edit_media_image_column"
                            >
                              <img
                                src={imgs}
                                width="300"
                                height="300"
                                alt="medias"
                                key={index}
                              />

                              <Button
                                buttonClassName="image_gallery_remove_btn"
                                onClick={() => imageRemoveHandler(i, index)}
                                text={<span></span>}
                              />
                            </div>
                          );
                        })}
                    </div>
                    <p className="create_project_review">
                      720 x 1280 (9 : 16),The maximum size for an upload in
                      Images is 1 MB
                    </p>
                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.medias.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => mediasHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.medias.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => mediasHandleAddClick(i)}
                          text="Add Category"
                          other={addIcon}
                        />
                      )}
                    </div>
                    {values.medias[i]["imgError"] && (
                      <span className="err_text">
                        {values.medias[i]["imgError"]}
                      </span>
                    )}
                  </div>
                );
              })}
            {mediaColumnErr && (
              <span className="err_text">{mediaColumnErr}</span>
            )}
          </div>
          <div className="project_edit_detail_column_content">
            <h2>videos </h2>
            {values.videos &&
              values.videos.map((x, i) => {
                return (
                  <div key={i} className="project_edit_detail_divider">
                    <Input
                      className="project_edit_feature_input"
                      type="text"
                      placeholder="Enter Video Url"
                      id="videos"
                      name="videos"
                      value={x}
                      onChange={(e) => videoLinkChangeHandler(e, i)}
                    />
                    <div className="project_edit_feature_add_remove_btn_row">
                      {values.videos.length !== 1 && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => videoHandleRemoveClick(i)}
                          text="Remove"
                          other={removeIcon}
                        />
                      )}
                      {values.videos.length - 1 === i && (
                        <Button
                          buttonClassName="project_edit_feature_add_remove_btn project_edit_feature_add_btn"
                          onClick={() => videoHandleAddClick(i)}
                          text="Add Video"
                          other={addIcon}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <Button
          className="legal_doc_button"
          buttonClassName="comman_btn"
          onClick={saveButtonHandler}
          text="Save"
        />
      </div>
    </>
  );
};

export default MediasForm;
