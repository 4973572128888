import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../../component/Common/Loader";
import Pagination from "../../component/Pagination/Pagination";
import PrivilegeItem from "../../component/Privilege/PrivilegeItem";
import { routes } from "../../constants";
import {
  clearPrivilege,
  getPrivilege,
  updatePrivilege,
} from "../../store/slice/privilegeSlice";
import DeleteModal from "./DeleteModal";
import { useForm } from "../../hooks/useForm";
import ViewPrivilegeModal from "./ViewPrivilegeModal";

const PrivilegeListPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { page_number } = params;

  const [viewModalData, setViewModalData] = useState("");

  const loader = useSelector((state) => state.privilege.loader);

  const initialFValues = {
    showDeleteModal: false,
    itemId: 0,
    showViewModal: false,
  };
  const { values, setValues } = useForm(initialFValues, true);
  const handlePrivilageDelete = (id) => {
    setValues({
      module: "Privilege",
      showDeleteModal: true,
      itemId: id,
    });
  };

  const handlePrivilageView = (viewItem) => {
    setValues({
      showViewModal: true,
    });
    setViewModalData(viewItem);
  };

  useEffect(() => {
    dispatch(clearPrivilege());
    if (page_number === 1) dispatch(getPrivilege({}));
  }, [dispatch]);

  const privilegeList = useSelector(
    (state) => state.privilege.privilegeList?.data
  );

  const totalCount = useSelector(
    (state) => state.privilege.privilegeList?.totalCount
  );

  const listItems =
    privilegeList &&
    privilegeList.map((item) => (
      <PrivilegeItem
        key={item.id}
        item={item}
        handlePrivilageDelete={handlePrivilageDelete}
        handlePrivilageView={handlePrivilageView}
      />
    ));

  const onPageChange = (page_number) => {
    dispatch(getPrivilege({ start: page_number, limit: 10 }));
  };
  const handleDeleteClose = () => {
    setValues({ showDeleteModal: false });
  };

  const handleViewClose = () => {
    setValues({ showViewModal: false });
  };

  const deletePrivilageHandler = (id) => {
    setValues({ showDeleteModal: false });
    const data = {
      delete_status: "1",
    };

    dispatch(
      updatePrivilege({
        data,
        id,
        page_number,
        cb: (err, res) => {
          if (err) {
            // console.log("err", err);
          } else {
            dispatch(getPrivilege({ start: (page_number - 1) * 10 }));
          }
        },
      })
    );
  };

  return (
    <>
      {loader && <Loader />}
      <div className="project_edit_main_content">
        <div className="creat_edit_project_btn_row">
          <Link to={routes.createPrivilege} className="comman_btn ml-auto">
            Create Privilege
          </Link>
        </div>
        {listItems && listItems.length === 0 ? (
          <div className="no_data_found_container">
            <h2 className="user_detail-nodata">No Data Found !</h2>
          </div>
        ) : (
          <>
            <div className="col_lg_4 d_grid gap_15">{listItems}</div>
            <Pagination totalRecords={totalCount} onPageChange={onPageChange} />
          </>
        )}
        <DeleteModal
          values={values}
          handleDeleteClose={handleDeleteClose}
          handleDeleteConfirm={deletePrivilageHandler}
        />
        <ViewPrivilegeModal
          values={values.showViewModal}
          viewModalData={viewModalData}
          handleViewClose={handleViewClose}
        />
      </div>
    </>
  );
};

export default PrivilegeListPage;
